import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { StyledCardEmpty } from "../../elements/card/styled-card-empty";
import { ContainerInformacionAdicional } from "./containers-informacion-adicional";
import { StyledBox, StyledTypography, StyledCardMedia } from "../../elements";
import { Divider } from "@material-ui/core";
import { palette } from "../../themes/color-palette";

import VideoPlayer from "./VideoPlayer";
import DrawerInformacionAdicional from "./DrawerInformacionAdicional";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
// import { checkSuperSKU } from '../../functions/utils'
interface Props {
  partNumber: any;
  longDescription: any;
  legalDescription: any;
  model: any;
  videos: any;
}
const InformacionAdicional = (props: Props) => {
  const { partNumber, videos, longDescription, legalDescription, model } = props;
  const [superSKU, setSuperSKU] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const storeConfData = useSelector(onlineStoreRdc);

  useEffect(() => {
    checkSuperSKU();
  }, []);

  const checkSuperSKU = () => {
    const urlFile = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${partNumber}/${partNumber}-xm.jpg`;
    // const urlFile = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/126080/126080-xd.jpg`
    const request = new XMLHttpRequest();
    request.open("HEAD", urlFile, true);
    request.send();
    request.onload = function () {
      if (request.status == 200) {
        //if(statusText == OK)
        setSuperSKU(urlFile);
      }
    };
  };

  const handleIsOpen = () => {
    setIsOpen(false);
  };
  const handleOpenEspicificaciones = () => {
    setIsOpen(true);
  };


  return (
    <div id="pdp-informacion-adicional">
      <ContainerInformacionAdicional>
        <StyledBox className="container-informacion">
          <StyledCardEmpty className="product-additional-info-card" style={{ padding: 10 }}>
            <StyledTypography variant="textSegment" className="titulo-informacion">
              {t("InformacionAdicional.infoAdicional")}
            </StyledTypography>
            {videos && videos.length !== 0 ? (
              <StyledBox className="container-videos" style={{ minHeight: 200 }}>
                <VideoPlayer videos={videos} />
              </StyledBox>
            ) : null}
            <StyledBox>
              {superSKU !== "" ? (
                <StyledCardMedia
                  image={superSKU}
                  className="lazyload"
                  title=""
                  style={{ height: "100%", width: "100%" }}
                  component="img"
                />
              ) : null}
            </StyledBox>
            <StyledBox>
              <StyledTypography variant="bodyBaseline" className="descripcion-informacion">
                {longDescription}
              </StyledTypography>
            </StyledBox>
            <Divider style={{ marginTop: 10, backgroundColor: palette.gray["50"] }} />

            <StyledBox>
              <StyledTypography
                variant="bodyBaseline"
                className="link-mas-informacion"
                onClick={handleOpenEspicificaciones}>
                {t("InformacionAdicional.informacionDescargables")}
              </StyledTypography>
            </StyledBox>
          </StyledCardEmpty>
        </StyledBox>

        <DrawerInformacionAdicional isOpen={isOpen} handleIsOpen={handleIsOpen} partNumber={partNumber} model={model} longDescription={longDescription} legalDescription={legalDescription} />
      </ContainerInformacionAdicional>
    </div>
  );
};

export default InformacionAdicional;

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "styled-components";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { palette } from "../../../hdm/themes";
//Components
import { StyledBox, StyledTypography } from "../../../hdm/elements/";

const StyledDialogTitle = styled(({ onClickHandler, ...props }) => (
  <DialogTitle {...props}>
    <HighlightOffIcon
      onClick={onClickHandler}
      viewBox="0 2 24 24"
      className="dialogStore--icon--highlightOff"
      aria-label="close"
    />
    <StyledBox>
      <StyledTypography variant={props?.title === "Tu sesión ha expirado" ? "headingSubhead" : "textSegment"} component="h1" fontFamily='"Open Sans Light"'>
        {props.title}
      </StyledTypography>
    </StyledBox>
  </DialogTitle>
))`
  ${({ theme, props }) => `
    padding: ${theme.spacing(2)}px ${theme.spacing(2)}px;
    .dialogStore--icon--highlightOff{
      font-size: ${props?.title === "Tu sesión ha expirado" ? "25px" : "20px"};
      cursor: pointer;
      color: ${palette.gray["100"]};
      position: absolute;
      right: 0px;
      top:5px;
      margin: ${theme.spacing(1)}px;
    }
    .MuiTypography-h6 {
      text-align: center;
    }
    font-family: "Open Sans";
    .MuiIconButton-root:hover {
      background-color: ${palette.background.paper};
    }

    .MuiButtonBase-root {
      position: absolute;
      right: ${theme.spacing(0)}px;
      top: ${theme.spacing(0)}px;

      svg {
        color: ${palette.gray["100"]};
            cursor: pointer;
      }
      &:hover {
        svg {
          fill: ${theme.palette.primary.main};
        }
      }
    }
  `}
`;

export { StyledDialogTitle };

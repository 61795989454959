/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { LazyComponentProps, LazyLoadComponent, trackWindowScroll } from "react-lazy-load-image-component";
//UI
import { StyledGrid, StyledChip, StyledButton, StyledTypography, StyledBox } from "../../elements";
import { Trans, useTranslation } from "react-i18next";

import Paginationhdm from "../../../components/custom-components/shared/Paginationhdm";

import styled from "styled-components";
import SorterSelecthdm from "../../../components/custom-components/shared/SorterSelecthdm";

import { useDispatch, useSelector } from "react-redux";
import { breadcrumbsSelector, isSearchRetrySelector, spellcheckSelector } from "../../../redux/selectors/catalog";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { useSite } from "../../../_foundation/hooks/useSite";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import { EMPTY_STRING } from "../../../constants/common";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import { StyledSkeleton } from "../../../components/custom-components/skeleton-loader/components/StyledSkeleton";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../../../components/custom-components/skeleton-loader/actions/sl-actions";
import { triggerSearchSubmitDL } from "../../../components/custom-components/Datalayer-Utils/dataLayer-func";

/**
 * Catalog entry list widget
 *  @summary Displays Product Card List in Grid Layout.
 * `@prop {any} props` have following properties:
 * `@property {String} cid(required)` Unique identifier used for layouts.
 * `@property {String} categoryId(required)` Category unique identifier.
 * `@property {String} searchTerm` The input string for search.
 * `@property {any} translation(required)` The translation JSON object for i18n.
 * `@property {any} ProductCards(required)` The wrapper component to display product list.
 * `@property {any} formattedPriceDisplay(required)` The wrapper element use to display price on product grid layout.
 * `@property {any} sortOptions(required)` The options to sort the products like Name, Brand etc.
 * `@property {any} onSortOptionChange `The function will sort the products like Name, Brand etc.
 * `@property {any} SEARCH(required)` The constant variable - "bySearchTerm".
 * `@property {any} pageLimit(required)`  The number of products to dislay in a single page for pagination.
 * `@property {any} selectedFacets(required)` The labels use to display selected facet name.
 * `@property {any} clearPriceFacet(required)` The handler to clear the price facets.
 * `@property {any} onPageChange(required)` The handler to go to next page - pagination.
 * `@property {any} isValidUrl(required)` The function will return true if URL is valid.
 */
interface CatalogEntryListWidgetProps extends LazyComponentProps {
  cid: string;
  categoryId?: string;
  searchTerm?: string;
  isValidUrl: any;
  onPageChange: any;
  clearPriceFacet: any;
  onSortOptionChange: any;
  onClearAll: any;
  onFacetRemove: any;
  priceSelected: any;
  selectedFacets: any;
  productListTotal: any;
  selectedSortOption: any;
  pageLimit: any;
  productList: any;
  sortOptions: any;
  selectedPageOffset: any;
  suggestedKeywords: any;
  SEARCH: any;
  formattedPriceDisplay: any;
  ProductCards?: any;
  translation?: any;
  selectFacetRemove: boolean;
}
/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 * @param props
 */

const StyledGridHeaderContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: baseline;

    }

    & .sorter-container{
      ${theme.breakpoints.down("sm")} {
        width: 100% !important;
        padding-right: ${theme.spacing(2)}px !important;

      }
    }


`}
`;

const StyledTitleNoMatchContainer = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
        &.headingTitle--nomatch-found{
          padding-bottom: ${theme.spacing(2)}px;
          border-bottom: solid 1px #cccccc;
        }

        & .headingTitle--typography{
          display: flex;
          flex-direction: column;
        }
      `}
`;

const SuggestionsLinksContainer = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
        &.suggestionlinks-container{
          margin: ${theme.spacing(2)}px ${theme.spacing(2)}px 0px ${theme.spacing(1)}px;
          display: flex;
          flex-direction: row;
          align-items: center;

        }

        & .suggestion-title{
          min-width: 121px;
        }

        & .suggested-buttons{
          display: flex;
          flex-direction: row;
          max-width: 100%;

          @media screen and (max-width: 600px){
            overflow: hidden;
            overflow-x: scroll;
            }

        }

        & .suggestion-link{
          min-width: fit-content;
          border-radius: 3px;
          margin: 0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px;
          display: grid;
          // // padding-left: 10px;
        }

      `}
`;
const ChipsContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      &.chips-container{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        
        ${theme.breakpoints.down("sm")} {
            padding: 0px 5px;
        }

      }

      & svg {
        font-size: 21px;
      }

      & .filtered-text{
        margin-right: 5px;
        min-width: fit-content;
        @media screen and (max-width: 600px){
            display: none;
        }
      }

      & .delete-button-container{
        & button{
          background: unset;
        }
        min-width: fit-content;
          & button{
            margin: 0;
            @media screen and (max-width: 600px){
              padding-rigth: 0;
            }
          }
      }

      & .chip{
        border: 1px solid #ccc;
        box-shadow: none;
      }

      & .chipsanddelete-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 86.5%;
        justify-content: space-between;
        max-width: min-content;

        ${theme.breakpoints.down("sm")} {
            min-width: 100%;
            margin-left: 0px;
        }
       
      }

      & .chips-wrapper{
        flex-wrap: nowrap;
        display: flex;
        overflow: hidden;
        // max-width: 89%;
        ${theme.breakpoints.down("sm")} {
          overflow-x: auto;
        }
      }

      & .chips-nowrap {
        white-space: nowrap;
        & p{
          white-space: nowrap;
        }
      }

      & .shadow-rigth {
        box-shadow: -20px 0 10px -10px #999;
        // padding-right: 5px;
      }

      `}
`;

const StyledHeaderTitleContainer = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
          &.headingTitle--search-title{
            display: flex;
            flex-direction: row;
            align-items: baseline;
            ${theme.breakpoints.down("sm")} {
                padding-left: 10px;
                padding-right: 10px;
                flex-direction: column;
                margin-left: ${theme.spacing(1)}px;
            }
            /*@media screen and (max-width: 600px){
            }*/

            & .product-count{
            ${theme.breakpoints.down("sm")} {
              width: -webkit-fill-available;
              margin-left: 0;
            }
             /* @media screen and (max-width: 600px){
                width: -webkit-fill-available;
                margin-left: 0;
              }*/
            }
          }

          & .headingTitle--searchterm{
            display: flex;
            margin-right: ${theme.spacing(2)}px;

          }

          & b{
            font-weight: 600;
          }

          & .headingTitle--product-count{
            align-self: center;
            transform: translate(0px, 2.5px);
            padding-left: 6px;
            @media screen and (max-width: 600px){
              padding-left: 0;
              width: 100%;
            }
          }

          &.headingTitle--category-title{
            display: flex;
            align-items: baseline;
            @media screen and (max-width: 600px){
              flex-direction: column;
              margin-left: ${theme.spacing(1)}px;
            }
          }

          & .headingTitle--category-name{
            font-weight: 600;
            margin-right: ${theme.spacing(2)}px;
          }
          & .headingTitle--category-product-count{
            align-self: end;
            padding-left: ${theme.spacing(2)}px;

            @media screen and (max-width: 600px){
              width:100%;
              padding-left:0;
            }
          }

        `}
`;
const StyledPaginationFooterContainer = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
      & .pagination-footer-content{
        font-weight: 300;
        & b{
          font-weight: 600;
        }
      }
        `}
`;

const facetEntryName = {
  "Catalogo Extendido": "Catalogo extendido",
  "Hipoteca Verde": "Hipoteca verde",
  "Precio Mayoreo": "Precio mayoreo"
}

const CatalogEntryListWidget: React.FC<CatalogEntryListWidgetProps> = (props: any) => {
  const {
    priceSelected,
    selectedFacets,
    selectedSortOption,
    sortOptions,
    selectedPageOffset,
    suggestedKeywords,
    SEARCH,
    formattedPriceDisplay,
    ProductCards,
    translation,
    onSortOptionChange,
    productListTotal,
    pageLimit,
    cid,
    onFacetRemove,
    onClearAll,
    categoryId,
    searchTerm,
    isValidUrl,
    onPageChange,
    clearPriceFacet,
    productList,
    selectFacetRemove,
    retrySearchTerm,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const container = containerRef.current;

  const [isLeft, setIsLeft] = useState(true);
  const [canScroll, setCanScroll] = useState(false);

  const handleMouseMove = (e) => {
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const containerScrollWidth = container.scrollWidth;

    if (container) {
      setCanScroll(containerWidth !== containerScrollWidth);
    }

    const xPos = e.pageX - container.offsetLeft;
    setIsLeft(xPos < containerWidth / 0.8);
    const isRight = !isLeft;

    if (isLeft) {
      container.scrollLeft -= 10;
    } else if (isRight) {
      container.scrollLeft += 10;
    }
  };

  const pageCountTotal: number = Math.ceil(productListTotal / pageLimit);
  const selectedPage = Math.floor(selectedPageOffset / pageLimit) + 1;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const beardcrumbState = useSelector(breadcrumbsSelector);
  const categoryName = beardcrumbState[beardcrumbState.length - 1];

  const { mySite } = useSite();
  const theme = useTheme();

  const isB2B = Boolean(mySite?.isB2B);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isBTLoaded, setIsBTLoaded] = useState<boolean>(false);

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector)

  const openDrawer = () => {
    dispatch(OPEN_BACKDROP_LOADER_ACTION({}));
  };


  useEffect(() => {
    openDrawer(); // open loader when component in loaded
    if (searchTerm !== EMPTY_STRING && searchTerm !== undefined) {
      sendEmarsysTag();
    }
  }, []);

  const sendEmarsysTag = () => {
    const query = {
      url: "",
      data: {
        searchTerm: ["searchTerm", searchTerm],
        tag: [
          "tag",
          "custom_search",
          {
            search_param: searchTerm,
            search_order: "Relevancia",
            order_direction: "asc",
          },
        ],
      },
      option: "EMARSYS_SEARCH_TAG_DATA",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const removeFinalComa = (suggestedKeywords) => {
    const newSuggestedKeywords: any = [];
    if (!suggestedKeywords) return;
    let splittedWord;
    for (const keyword in suggestedKeywords) {
      splittedWord = suggestedKeywords[keyword];

      if (splittedWord.slice(splittedWord.length - 1) === ",") {
        splittedWord = splittedWord.slice(0, -1);
      }
      newSuggestedKeywords.push(splittedWord);
    }
    return newSuggestedKeywords;
  };

  const newKeywords = removeFinalComa(suggestedKeywords);

  const spellcheck = useSelector(spellcheckSelector);
  const isSearchRetry = useSelector(isSearchRetrySelector);

  const ZeroResults = (props) => {
    useEffect(() => {
      setTimeout(() => {
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
      }, 1000);
    }, []);


    return (
      <>
        <StyledBox id={`productGrid_div_18_${cid}`} className="suggested-keywords">
          <StyledTitleNoMatchContainer className="headingTitle--nomatch-found">
            <StyledTypography variant="bodyBaseline" component="h1" className="headingTitle--typography">
              <StyledBox
                sx={{
                  borderBottom: "1px solid #ccc",
                  width: "100%",
                  paddingBottom: "10px",
                  marginBottom: "10px",
                }}>
                {/*<EspotHdm espotName="PLPNullSearch_Entered" variable1={searchTerm} />*/}
                {
                  <>
                  <Trans t={t} i18nKey="ProductGrid.Labels.noMatches" values={{ searchTerm: searchTerm }}>
                    Tu búsqueda <b>searchTerm</b> no obtuvo resultados
                  </Trans>
                  <br/>
                  <Trans t={t} i18nKey="ProductGrid.Labels.searchTips" />
                  
                  </>
                }
              </StyledBox>
              <StyledBox sx={{ paddingLeft: "40px" }}>
                <ul>
                  <li>Intenta buscar productos por su nombre, marca, modelo o SKU.</li>
                  <li>
                    Mejora tus resultados agregando adjetivos a tu búsqueda separados por un espacio Ej. "Refrigerador
                    negro".
                  </li>
                  <li>Revisa que la palabra que buscas esté escrita correctamente.</li>
                  <li>Intenta usar sinónimos de la palabra que buscas.</li>
                </ul>
              </StyledBox>
            </StyledTypography>
          </StyledTitleNoMatchContainer>

          {newKeywords?.slice(0, 1).map((keyword: string) => (
            <StyledBox key={searchTerm} to={SEARCH + "?searchTerm=" + searchTerm} className="suggestion-link bottom-margin-2 top-margin-1">
              Mostrando resultados de búsqueda para "<b>{retrySearchTerm ?? searchTerm}</b>"
            </StyledBox>
          ))}

          {suggestedKeywords?.length > 0 && (
            <SuggestionsLinksContainer className="suggestionlinks-container">
              <StyledTypography className="suggestion-title">
                {translation.ProductGridLabelssuggestion}
              </StyledTypography>
              <StyledBox className="suggested-buttons">
                {newKeywords?.map((keyword: string, index: number) => (
                  <StyledButton
                    variant="outlined"
                    color="secondary"
                    key={keyword}
                    component={Link}
                    to={SEARCH + "?searchTerm=" + keyword}
                    className="suggestion-link"
                    id={`productGrid_a_22_${index}_${cid}`}>
                    {keyword}
                  </StyledButton>
                ))}
              </StyledBox>
            </SuggestionsLinksContainer>
          )}

          {productListTotal === 0 && isSearchRetry && spellcheck && spellcheck?.length > 0 && (
            <SuggestionsLinksContainer className="suggestionlinks-container">
              <StyledTypography className="suggestion-title">
                {translation.ProductGridLabelssuggestion}
              </StyledTypography>
              <StyledBox className="suggested-buttons">
                {spellcheck.map((keyword: string, index: number) => (
                  <StyledButton
                    variant="outlined"
                    color="secondary"
                    key={keyword}
                    component={Link}
                    to={SEARCH + "?searchTerm=" + keyword}
                    className="suggestion-link"
                    id={`productGrid_a_22_${index}_${cid}`}>
                    {keyword}
                  </StyledButton>
                ))}
              </StyledBox>
            </SuggestionsLinksContainer>
          )}
        </StyledBox>
      </>
    );
  };
  useEffect(() => {
    try {
      if (productListTotal >= 0 &&
        searchTerm !== "" &&
        Object.keys(selectedFacets)?.length === 0 &&
        !priceSelected &&
        !selectFacetRemove && sessionStorage.getItem("event_SearchTerm") !== null) {
        let kwdVal = "";
        const srchTerm = sessionStorage.getItem("event_SearchTerm") + "";
        if (srchTerm.startsWith("kw")) {
          kwdVal = "keyword";
        } else if (/[A-Z]/.test(srchTerm.substring(srchTerm.length - 1))) {
          kwdVal = "Search rule (mayus)";
        } else if (document.getElementsByTagName("html")[0].getAttribute("lang") !== "es") {
          kwdVal = "Translated";
        } else if (srchTerm.includes("+") && (/^[0-9 +]*$/.test(srchTerm))) {
          kwdVal = "sku+sku";
        } else if ((window.location.href).includes("/s/")) {
          kwdVal = "Regular";
        } else if ((window.location.href).includes("/b/")) {
          kwdVal = "Category redirect";
        } else if (/^[a-zA-Z ]*$/.test(srchTerm) && !(srchTerm.startsWith("kw") || /[A-Z]/.test(srchTerm.substring(srchTerm.length - 1)))) {
          kwdVal = "Landing redirect";
        }
        if (productListTotal === 0) {
          kwdVal = "Null search";
          triggerSearchSubmitDL("null_search", kwdVal, "Null search", srchTerm, window.location.href);
          sessionStorage.removeItem("event_SearchTerm");
        } else if (productListTotal === 1) {
          kwdVal = "Product redirect";
          triggerSearchSubmitDL("search_submit", kwdVal, "Search submit", srchTerm, "https://" + window.location.hostname + productList[0].seo.href);
          sessionStorage.removeItem("event_SearchTerm");
        } else if (productListTotal > 1) {
          triggerSearchSubmitDL("search_submit", kwdVal, "Search submit", srchTerm, window.location.href);
          sessionStorage.removeItem("event_SearchTerm");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [productListTotal]);

  return (
    <div className="product-listing-container productListingWidget top-margin-3">
      {(productListTotal === 0 || !productListTotal) && searchTerm !== "" && (
        // No matchs screen
        <>
          <ZeroResults />
        </>
      )}
      {/* Search result is 1, then go to PDP directly */}

      {productListTotal === 1 &&
        isValidUrl(productList) &&
        searchTerm !== "" &&
        Object.keys(selectedFacets)?.length === 0 &&
        !priceSelected &&
        !selectFacetRemove && <Navigate replace to={productList[0].seo.href} />}

      {productListTotal > 0 && isSearchRetry && <ZeroResults />}
      {productListTotal > 0 && (
        <>
          {isB2B && !categoryName && <EspotHdm espotName={"PRO_PLPSearch_SuperiorSection"} />}
          {categoryName && (
            <>
              <EspotHdm
                espotName={isB2B 
                  ? `PRO_PLPCategory_Title?DM_SubstitutionName1=[CategoryTitle]&DM_SubstitutionValue1=${categoryName?.label}&DM_FilterResults=true`
                  : `PLPCategory_Title?DM_SubstitutionName1=[CategoryTitle]&DM_SubstitutionValue1=${categoryName?.label}&DM_FilterResults=true`
                }
              />
              <br />
              <EspotHdm espotName={"PRO_PLPCategory_SuperiorSection"} />
            </>
          )}

          <StyledGridHeaderContainer
            container
            className={isB2B ? "" : "bottom-margin-4"}
            justifyContent="space-between"
            alignItems="center">
            <StyledGrid item>
              <StyledTypography variant="headingTitle" component="h1">
                {categoryId !== "" ? (
                  // Category title
                  <StyledHeaderTitleContainer className="headingTitle--category-title">
                    <StyledTypography variant="headingTitle" component="h1">
                      {t("ProductGrid.Labels.productSearchFound")}{" "}
                    </StyledTypography>
                    <StyledTypography variant="headingTitle" component="h1" className="headingTitle--category-name">
                      {categoryName?.label}
                    </StyledTypography>
                    <StyledBox className="product-count">
                      <StyledTypography variant="textSegment">
                        <Trans
                          t={t}
                          i18nKey={
                            Number.parseInt(productListTotal) === 1
                              ? "ProductGrid.Labels.productFound"
                              : "ProductGrid.Labels.productsFound"
                          }
                          values={{ count: productListTotal }}></Trans>
                      </StyledTypography>
                    </StyledBox>
                  </StyledHeaderTitleContainer>
                ) : (
                  // Search title
                  <StyledHeaderTitleContainer className="headingTitle--search-title">
                    <StyledBox className="headingTitle--searchterm">
                      <StyledTypography variant="headingTitle" component="h1">
                        {t("ProductGrid.Labels.productSearchFound")}{" "}
                        <b className="">"{retrySearchTerm ? retrySearchTerm : searchTerm}"</b>
                      </StyledTypography>
                      {/* <StyledTypography variant="headingTitle" component="h1" weight="semibold">

                         {" "}"{retrySearchTerm ? retrySearchTerm : searchTerm}"
                      </StyledTypography> */}
                    </StyledBox>
                    {
                      withSkeletonLoader(
                        <StyledBox className="product-count">
                          <StyledTypography variant="textSegment">
                            <Trans
                              t={t}
                              i18nKey={
                                Number.parseInt(productListTotal) === 1
                                  ? "ProductGrid.Labels.productFound"
                                  : "ProductGrid.Labels.productsFound"
                              }
                              values={{ count: productListTotal.toString() }}></Trans>
                          </StyledTypography>
                        </StyledBox>,
                        loaderState.src !== LoaderActions.PLP_GET_PRODUCTS_FACET_SELECTION
                        ,
                        loaders.plp.productsFound
                      )
                    }

                  </StyledHeaderTitleContainer>
                )}

                {/* :  translation.ProductGridLabelsproductSearchFound + translation.ProductGridLabelsproductFound } */}
              </StyledTypography>
            </StyledGrid>

            <StyledGrid container item style={{ width: "auto", alignItems: "center" }} className="sorter-container">
              <StyledGrid xs={12} item>
                <SorterSelecthdm
                  sortOptions={sortOptions}
                  selectedSortOption={selectedSortOption}
                  onSortOptionChange={onSortOptionChange}
                  cid={cid}
                  translation={translation}
                  isB2B={isB2B}
                />
              </StyledGrid>
            </StyledGrid>
          </StyledGridHeaderContainer>
        </>
      )}

      {/* Facet selection listing */}
      {productListTotal > 0 && (Object.keys(selectedFacets).length > 0 || priceSelected) && (
        <ChipsContainer item container direction="row" alignItems="center" className="bottom-margin-3 chips-container">
          <StyledTypography variant="bodyBaseline" className="filtered-text" weight="semibold">
            {`${translation.ProductGridLabelsfilteredBy}  (${Object.keys(selectedFacets).length + priceSelected})`}
          </StyledTypography>

          <StyledBox className="chipsanddelete-container">
            <StyledBox className="chips-wrapper" ref={containerRef} onMouseMove={handleMouseMove}>
              {Object.keys(selectedFacets).map((key: string, index: number) => (
                <Fragment key={key}>
                  <StyledChip
                    deleteIcon={<HighlightOffIcon />}
                    size="medium"
                    className="left-margin-2 chip"
                    label={
                      <StyledTypography variant="bodyBaseline" className="color-gray500 chips-nowrap">
                        {facetEntryName[selectedFacets[key]] ? facetEntryName[selectedFacets[key]] : selectedFacets[key]}
                      </StyledTypography>
                    }
                    onClick={() => onFacetRemove(key)}
                    onDelete={() => onFacetRemove(key)}
                  />
                </Fragment>
              ))}
              {priceSelected && (
                <StyledChip
                  deleteIcon={<HighlightOffIcon />}
                  size="medium"
                  className="left-margin-2 chip"
                  label={formattedPriceDisplay}
                  onClick={() => clearPriceFacet()}
                  onDelete={() => clearPriceFacet()}
                />
              )}
            </StyledBox>

            <StyledBox className="delete-button-container">
              {(Object.keys(selectedFacets).length > 1 ||
                (priceSelected && Object.keys(selectedFacets).length > 0)) && (
                  <StyledButton
                    testId="clear-all-facets"
                    variant="text"
                    className={isLeft && canScroll ? "left-margin-1 shadow-rigth" : "left-margin-1"}>
                    <StyledTypography variant="bodyBaseline">
                      <Link onClick={(event) => onClearAll(event)} to="" className="clear-all">
                        {translation.ProductGridActionsclearAll}
                      </Link>
                    </StyledTypography>
                  </StyledButton>
                )}
            </StyledBox>
          </StyledBox>
        </ChipsContainer>
      )}

      {/* Product listing and pagination */}
      {ProductCards}
      {productListTotal > pageLimit && (
        <>
          {/* <StyledPagination
          variant="outlined"
          color="primary"
          count={pageCountTotal}
          // shape="rounded"
          page={selectedPage}
          onChange={(event: ChangeEvent<unknown>, value: number) => onPageChange(value)}
          </PaginationItem>
          }
        /> */}

          <Paginationhdm pageCountTotal={pageCountTotal} onPageChange={onPageChange} selectedPage={selectedPage} />

          <StyledPaginationFooterContainer
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "300 !important",
            }}>
            <StyledTypography variant="bodyBaseline" className="pagination-footer-content">
              Mostrando del <b>{selectedPageOffset + 1}</b> -{" "}
              <b>
                {productList.length < pageLimit
                  ? selectedPageOffset + productList.length
                  : selectedPageOffset + pageLimit}
              </b>{" "}
              de <b>{productListTotal}</b> resultados
            </StyledTypography>
          </StyledPaginationFooterContainer>

        </>
      )}

      {productListTotal > 0 && isB2B && !categoryName && <EspotHdm espotName={"PRO_PLPSearch_InferiorSection"} />}
      {productListTotal > 0 && isB2B && categoryName && <EspotHdm espotName={"PRO_PLPCategory_InferiorSection"} />}
    </div>
  );
};
export default trackWindowScroll(CatalogEntryListWidget);

import styled from "styled-components";
import { StyledGrid } from "../../../hdm/elements";
const StyledWishListButtonContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `

  .wishList--styledGrid-addDirectionIcon{
    width: 20px;
    height: 20px;
    margin-left: -${theme.spacing(1)}px;
  }

  .wishList--styledGrid-addCardTitleEdit{
    display: flex;
  }

  .styledWishList--svg-container{
    width: fit-content;
    padding: ${theme.spacing(1)}px;
    box-shadow: 0 3px 6px ${theme.palette.gray["200"]};
    border-radius: 50%;
    color: ${theme.palette.primary.main};
    display: flex;
    margin-right: ${theme.spacing(3)}px;
    background-color: white;
    cursor: pointer;
    & svg{
        font-size: 25px;
    }
    #favoriteIcon{
      display: none;
    }
    :hover{
      position: relative;

      .styledWishList--svg-line {
        display: block;
      }
      #favoriteBorderIcon{
        display: none;
      }
      #favoriteIcon{
        display: inline-block;
      }
    }
    & .styledWishList--svg-line {
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      height: 3px;
      background: ${theme.palette.primary.main};
      display: none;
    }
  }

  .styledWishList--svg-wishProduct{
    cursor: default;
  }

  .styledWishList--orderSummary-container {
    padding-top: ${theme.spacing(3)}px;
    padding-bottom: ${theme.spacing(3)}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .styledWishList--orderSummary-favoriteBorderIcon {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    color: ${theme.palette.primary.main};
  }
`}
`;
export { StyledWishListButtonContainer };

import React, { FC } from "react";
import { ListItemIcon } from "@material-ui/core";
import {
  StyledBox,
  StyledListItem,
  StyledTypography,
} from "../../../../hdm";



const StyledListItemHDM = ({ icon, title, setEspot, cid="" }) => {
  return (
    <StyledBox id={cid} onClick={() => setEspot && setEspot(title)}>
      <StyledListItem button className={"list-item"}>
        <ListItemIcon className={"list-item__icon"}>{icon}</ListItemIcon>
        <StyledTypography variant={"bodyBaseline"} className={"list-item__text"}>
          {title}
        </StyledTypography>
      </StyledListItem>
    </StyledBox>
  );
};

export default StyledListItemHDM;

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React, { Fragment, useEffect, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import getDisplayName from "react-display-name";
import { CONSTANTS } from "../../../constants/order-item-table";
import { orderItemsSelector } from "../../../redux/selectors/order";

//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import personContactService from "../../../_foundation/apis/transaction/personContact.service";

//Redux
import { addressDetailsSelector } from "../../../redux/selectors/account";
import { GET_ADDRESS_DETAIL_ACTION } from "../../../redux/actions/account";
import * as successActions from "../../../redux/actions/success";

//Custom libraries
import {
  ADDRESS_TYPE_MAP,
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESSLINE3,
  EMPTY_STRING,
  PHONE1,
  ORG_ADDRESS_DETAILS,
  ORG_ADDRESS,
  ADDRESS_LINE,
  FIELD3,
  ATTRIBUTES,
} from "../../../constants/common";
import { EDIT_ADDRESS } from "../../../constants/routes";
import * as ROUTES from "../../../constants/routes";
import AddressContext from "../../pages/checkout/address/AddressContext";
import addressUtil from "../../../utils/addressUtil";
//UI
import { StyledTypography, StyledCard, StyledGrid, StyledBox } from "../../../hdm";
import { StyledRadioCard } from "../../custom-components/styled-store-selector/styled-radio-card/StyledRadioCard";
import { StyledLinkButton } from "../../custom-components/styled-store-selector/styled-link-button";
import { DeliveryInfoCard } from "../delivery-info-card";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Divider, Hidden } from "@material-ui/core";

import { checkOutStepRdc } from "../../../redux/selectors/hdmData";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { StyledEmarsysTitle } from "../../../hdm/commerce-widgets/hdm-emarsys-product-recommendation/Container-emarsys-product";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../../custom-components/skeleton-loader/actions/sl-actions";

interface AddressCardProps {
  addressId: string;
  nickName?: string;
  addressData?: any;
  actions?: any[];
  type?: boolean;
  setSelectedAddressId?: (v1?: any, v2?: any, v3?: any) => void; //selected address setter
  selectedAddressId?: string;
  selectedNickName?: string;
  readOnly?: boolean;
  hideEdit?: boolean;
  handleReadOnlyShipping?: any;
  variant?: string;
  deliveryMethod?: string;
  deliveryDates?: any;
  manualShippingDate?: any;
  setManualShippingDate?: (v: string) => void | undefined;
  oneTimeDeliveryOfMixedCart?: any;
  setOneTimeDeliveryOfMixedCart?: any;
  isInstallation?: any;
  isDrawer?: boolean;
}

/**
 * Address card display component
 * displays the details of a single address
 * @param props
 */
const AddressCard: React.FC<AddressCardProps> = (props: any) => {
  const { selectedNickName, variant } = props;
  const widgetName = getDisplayName(AddressCard);
  const stepActiveSelector = useSelector(checkOutStepRdc);
  const stepActive = stepActiveSelector ? parseInt(stepActiveSelector.currentStep) : 0;
  const addressId = props.addressId ? props.addressId : "";
  const nickName = props.nickName ? props.nickName : "";
  const actions = props.actions;
  const type = props.type ? props.type : false;
  const readOnly = props.readOnly ? props.readOnly : false;
  // const variant = props.variant ? props.variant : "checkout";
  //const hideEdit = props.hideEdit ? props.hideEdit : false;
  const hideEdit = props.hideEdit ? props.hideEdit : false;
  const selectedAddressId = props.selectedAddressId ? props.selectedAddressId : "";
  const setSelectedAddressId = props.setSelectedAddressId ? props.setSelectedAddressId : null;
  const isSelected = selectedAddressId === addressId || nickName === selectedNickName;
  const addressDetails = useSelector(addressDetailsSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const addressContext = useContext(AddressContext);
  const orgAddressDetails = addressContext[ORG_ADDRESS_DETAILS];
  
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const addressData = props.addressData ? buildAddressData(props.addressData) : getAddress();
  const handleReadOnlyShipping = props.handleReadOnlyShipping ? props.handleReadOnlyShipping : false;
  const deliveryDates = props.deliveryDates;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let editAddressDetails: any;
  const TOGGLE_EDIT_ADDRESS = "toggleEditAddress";
  const SET_EDIT_ADDRESS_FORM_DATA = "setEditAddressFormData";
  const deliveryMethod = props.deliveryMethod;
  const CancelToken = Axios.CancelToken;
  const isPrimaryAddress = addressData?.primary === "true";
  const cancels: Canceler[] = [];
  const manualShippingDate = props?.manualShippingDate;
  const setManualShippingDate = props?.setManualShippingDate ? props.setManualShippingDate : undefined;
  const oneTimeDeliveryOfMixedCart = props.oneTimeDeliveryOfMixedCart ? props.oneTimeDeliveryOfMixedCart : undefined;
  const setOneTimeDeliveryOfMixedCart = props.setOneTimeDeliveryOfMixedCart
    ? props.setOneTimeDeliveryOfMixedCart
    : undefined;
  const isInstallation = props?.isInstallation === true ? true : false;
  const isDrawer = props.isDrawer;

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector)

  const payload = {
    ...payloadBase,
  };

  useEffect(() => {
    if (mySite && addressDetails === null) {
      const payload = {
        ...payloadBase,
      };
      dispatch(GET_ADDRESS_DETAIL_ACTION(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteAddress(nickName: string, primary: string) {
    const parameters: any = {
      nickName: nickName,
      ...payloadBase,
    };
    personContactService.deletePersonContact(parameters).then((res) => {
      dispatch(GET_ADDRESS_DETAIL_ACTION(payload));
      const successMessage = {
        key: "success-message.DELETE_ADDRESS_SUCCESS",
        messageParameters: {
          "0": nickName,
        },
      };
      dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
      console.log(" is primary", primary);
      if (primary && primary === "true") {
        handleChangePrimaryAddres();
      }
    });
  }
  const handleChangePrimaryAddres = () => {
    const body = {
      primary: "true",
    };

    const parameters: any = {
      nickName: addressDetails.logonId,
      body: body,
      ...payloadBase,
    };
    console.log("parameters ", parameters);
    personContactService
      .updatePersonContact(parameters)
      .then((res) => {
        dispatch(GET_ADDRESS_DETAIL_ACTION(payloadBase));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  function getAddress() {
    let finalAddressData: any = {};
    if (addressDetails && addressId !== "") {
      const contactMap = addressDetails.contactMap;
      if (addressDetails.addressId === addressId || addressDetails.nickName === nickName) {
        finalAddressData = addressDetails;
      } else if (contactMap && contactMap[addressId]) {
        finalAddressData = contactMap[addressId];
      } else if (nickName !== "") {
        for (const key in contactMap) {
          if (contactMap[key].nickName === nickName) {
            finalAddressData = contactMap[key];
            break;
          }
        }
      }
    }

    if (
      orgAddressDetails &&
      orgAddressDetails.contactInfo &&
      orgAddressDetails.addressBook &&
      addressId !== EMPTY_STRING
    ) {
      if (
        addressId === orgAddressDetails.contactInfo.addressId ||
        nickName === orgAddressDetails.contactInfo.nickName
      ) {
        const orgAddress: any = {};
        Object.assign(orgAddress, orgAddressDetails.contactInfo);
        orgAddress[ADDRESS_LINE] = [orgAddress.address1, orgAddress.address2, orgAddress.address3];
        orgAddress[ORG_ADDRESS] = true;
        finalAddressData = orgAddress;
      } else {
        for (const orgAddress of orgAddressDetails.addressBook) {
          if (addressId === orgAddress.addressId || nickName === orgAddress.nickName) {
            const address: any = {};
            Object.assign(address, orgAddress);
            address[ADDRESS_LINE] = [address.address1, address.address2, address.address3];
            address[ORG_ADDRESS] = true;
            finalAddressData = address;
            break;
          }
        }
      }
    }

    return buildAddressData(finalAddressData);
  }

  function buildAddressData(address: any) {
    let finalAddressData: any = { ...address };

    let fullNameString: string = "";
    if (finalAddressData.firstName !== undefined && finalAddressData.firstName !== "") {
      fullNameString = finalAddressData.firstName;
    }
    if (finalAddressData.lastName !== undefined && finalAddressData.lastName !== "") {
      if (fullNameString !== "") {
        fullNameString += " ";
      }
      fullNameString += finalAddressData.lastName;
      finalAddressData = {
        ...finalAddressData,
        fullNameString: fullNameString,
      };
    }

    const cityStateZipList: string[] = [];
    if (finalAddressData.city !== undefined && finalAddressData.city !== "") {
      cityStateZipList.push(finalAddressData.city);
    }
    if (finalAddressData.state !== undefined && finalAddressData.state !== "") {
      cityStateZipList.push(finalAddressData.state);
    }
    if (finalAddressData.zipCode !== undefined && finalAddressData.zipCode !== "") {
      cityStateZipList.push(finalAddressData.zipCode);
    }
    if (cityStateZipList.length > 0) {
      const cityStateZipString = cityStateZipList.join(", ");
      finalAddressData = {
        ...finalAddressData,
        cityStateZipString: cityStateZipString,
      };
    }
    return finalAddressData;
  }
  const handleChangeDeliveryButton = () => {
    if (!userLoggedIn) {
      handleEditButton();
    }
    handleReadOnlyShipping();
  };
  const handleEditButton = () => {
    addressContext[TOGGLE_EDIT_ADDRESS](true);
    editAddressDetails = { ...addressData };
    setAndCleanAddressData(addressData);
    if (!addressData.phone1) {
      editAddressDetails[PHONE1] = EMPTY_STRING;
    }
    addressContext[SET_EDIT_ADDRESS_FORM_DATA](editAddressDetails);
  };

  const setAndCleanAddressData = (filteredAddressDetails: any) => {
    if (filteredAddressDetails.addressLine && filteredAddressDetails.addressLine.length > 2) {
      editAddressDetails[ADDRESSLINE1] = filteredAddressDetails.addressLine[0];
      editAddressDetails[ADDRESSLINE2] = filteredAddressDetails.addressLine[1];
      editAddressDetails[ADDRESSLINE3] = filteredAddressDetails.addressLine[2];
      if (filteredAddressDetails?.attributes && filteredAddressDetails?.attributes.length > 0) {
        let attributes: any = {};
        if (filteredAddressDetails.attributes[0].key) {
          attributes = filteredAddressDetails.attributes.filter((attribue: any) => attribue.key === "addressField3")[0];
          editAddressDetails[FIELD3] = attributes?.value;
        } else {
          attributes = filteredAddressDetails.attributes.filter(
            (attribue: any) => attribue.contactInfoAttrKey === "addressField3"
          )[0];
          editAddressDetails[FIELD3] = attributes?.contactInfoAttrValue;
        }
      }
      editAddressDetails = addressUtil.removeIgnorableAddressFormFields(editAddressDetails);
    }
  };
  const headerComponent = (
    <>
      {addressData.nickName ? (
        <StyledTypography variant="subtitle2" display="block" noWrap>
          {addressData.nickName === addressDetails.logonId
            ? t("Account.Labels.MainAddress")
            : addressData.nickName
            ? addressData.nickName
            : ""}
        </StyledTypography>
      ) : null}
      {type && addressData.addressType ? (
        <StyledTypography variant="caption">{t(ADDRESS_TYPE_MAP.get(addressData.addressType))}</StyledTypography>
      ) : null}
    </>
  );
  const contentComponent = (
    <>
      <StyledGrid
        className={`deliveryAddressCard--styledGrid-maxWidth 
        ${readOnly && "top-margin-1 deliveryAddressCard--styledGrid-overflowVisible"}`}>
        {userLoggedIn && addressData.nickName && readOnly ? (
          <StyledTypography variant="bodyBaseline" weight="semibold" noWrap>
            {addressData.nickName === addressDetails.logonId
              ? t("Account.Labels.MainAddress")
              : addressData.nickName
              ? addressData.nickName
              : ""}
          </StyledTypography>
        ) : null}
        {addressData.addressLine ? (
          <>
            <StyledTypography variant="bodyBaseline" component={variant === "typ" ? "span" : "span"} noWrap>
              {addressData.addressLine[0] + " "} {"#" + addressData.addressLine[1]}
            </StyledTypography>
          </>
        ) : null}
        {addressData?.attributes &&
          addressData.attributes.length > 0 &&
          addressData.attributes[0]?.key &&
          addressData.attributes
            .filter((e) => e.key === "addressField3")
            .map((f: any) => (
              <>
                <StyledTypography variant="bodyBaseline" component={variant === "typ" ? "span" : "span"} noWrap>
                  {", " + f.value}
                </StyledTypography>{" "}
              </>
            ))}
        {addressData?.attributes &&
          addressData.attributes.length > 0 &&
          addressData.attributes[0]?.contactInfoAttrKey &&
          addressData.attributes
            .filter((e) => e.contactInfoAttrKey === "addressField3")
            .map((f: any) => (
              <>
                <StyledTypography variant="bodyBaseline" component={"span"} noWrap>
                  {", " + f.contactInfoAttrValue}
                </StyledTypography>{" "}
              </>
            ))}
        {addressData.addressLine ? (
          <StyledTypography variant="bodyBaseline" noWrap>
            {addressData.addressLine[2]}
          </StyledTypography>
        ) : null}
        {addressData.city ? (
          <>
            <StyledTypography variant="bodyBaseline" component={variant === "typ" ? "span" : ""} noWrap>
              {addressData.city + ", "}
              {addressData.state + " "}
              {addressData.country && stepActive === 2 && hideEdit && !readOnly ? (
                <StyledTypography variant="bodyBaseline" component={"span"} noWrap>
                  <br />
                  {addressData.country === "MX" ? "México, " : "Estados Unidos, "}
                </StyledTypography>
              ) : null}
              {addressData.zipCode ? (
                <StyledTypography variant="bodyBaseline" component={"span"} noWrap>
                  {"C.P."}&nbsp;{addressData.zipCode}
                </StyledTypography>
              ) : null}
            </StyledTypography>{" "}
          </>
        ) : null}

        {/*addressData.country ? (
        <StyledTypography variant="bodySubText" display="block" noWrap>
          {addressData.country}
        </StyledTypography>
      ) : null*/}

        {/*addressData.phone1 ? (
        <StyledTypography variant="bodySubText" display="block" noWrap>
          {addressData.phone1}
        </StyledTypography>
      ) : null*/}

        {/*addressData.email1 ? (
        <StyledTypography variant="bodySubText" display="block" noWrap>
          {addressData.email1}
        </StyledTypography>
      ) : null*/}
      </StyledGrid>
    </>
  );

  // Memoized function to get the address card action based on dependencies/conditons
  const cardActions = useMemo(
    () => getCardActions(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, setSelectedAddressId, isSelected, addressData.orgAddress, addressId, addressData]
  );

  // const cardActions = () => {
  //   getCardActions()
  // }

  /**
   * Get the card actions for Address Card
   */
  function getCardActions() {
    if (actions) {
      return actions;
    } else if (setSelectedAddressId) {
      return getCardActionsForCheckout();
    } else {
      return getCardActionsForAddressBook();
    }
  }

  /**
   * Returns card action for checkout flow
   */
  function getCardActionsForCheckout() {
    const action: any[] = [];
    if (!isOrgAddress() && !hideEdit) {
      action.push({
        text: t("AddressCard.EditButton"),
        handleClick: () => handleEditButton(),
      });
    }
    if (!isSelected) {
      action.push({
        text: t("AddressCard.UseAddress"),
        handleClick: () => setSelectedAddressId(addressData.addressId, addressData.nickName),
      });
    }
    return action;
  }

  /**
   * if the address is organization address returns true else undefined
   */
  function isOrgAddress() {
    return addressData.orgAddress;
  }

  /**
   * Returns the adress card actions for AddressBook component
   */
  function getCardActionsForAddressBook() {
    return addressData?.logonId
      ? [
          {
            text: t("AddressCard.EditButton"),
            link: EDIT_ADDRESS + ROUTES.HOME + addressData.addressId,
          },
        ]
      : [
          {
            text: t("AddressCard.EditButton"),
            link: EDIT_ADDRESS + ROUTES.HOME + addressData.addressId,
          },
          {
            text: t("AddressCard.DeleteButton"),
            handleClick: () => deleteAddress(addressData.nickName, addressData.primary),
            enableConfirmation: true,
          },
        ];
  }

  const orderItems = useSelector(orderItemsSelector);
  let totalProductsBopis = 0;
  let totalProductsDelivery = 0;

  if (orderItems && orderItems.length > 0) {
    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].carrier === CONSTANTS.bopis) {
        totalProductsBopis += 1;
      }

      if (orderItems[i].carrier === CONSTANTS.delivery) {
        totalProductsDelivery += 1;
      }
    }
  }

  let isSplitOrder = false;

  if (totalProductsBopis > 0 && totalProductsDelivery > 0) {
    isSplitOrder = true;
  }

  return readOnly ? (
    <>
      {variant === "orderResume" && (
        <StyledGrid item xs={12} className={isSplitOrder ? "top-margin-3" : "top-margin-1"}>
          <StyledEmarsysTitle>
            <StyledBox mb={1}>
              <StyledTypography variant="bodyBaseline" component="span" className="title-border-checkout">
                {isB2B ? t("Shipping.Labels.ShippingAddressConfirmedPro") : t("Shipping.Labels.ShippingAddressConfirmed")}
              </StyledTypography>
              <Divider className="top-margin-1"/>
            </StyledBox>
          </StyledEmarsysTitle>
        </StyledGrid>
      )}
      <StyledGrid xs={12} className={"deliveryAddressCard--styledGrid-flex bottom-margin-2"}>
        <StyledGrid
          xs={variant === "checkout" && stepActive === 1 ? 10 : 12}
          className={"deliveryAddressCard--styledGrid-flex"}>
          <StyledGrid className={"vertical-margin-1"}>
            <LocationOnIcon className="shipping--styledIcon-locationIcon" />
          </StyledGrid>{" "}
          {contentComponent}
        </StyledGrid>
        {variant === "checkout" && stepActive === 1 && (
          <StyledGrid item xs={2} className={"shipping--styledGrid-editSelectedAddress"}>
            <StyledLinkButton variant="bodyBaseline" onClick={() => handleChangeDeliveryButton()}>
              {t("AddressCard.Change")}
            </StyledLinkButton>
          </StyledGrid>
        )}
      </StyledGrid>
      <StyledGrid xs={12}>
        <DeliveryInfoCard
          readOnlyShipping={readOnly}
          type="delivery"
          variant={variant}
          deliveryMethod={deliveryMethod}
          deliveryDates={deliveryDates}
          manualShippingDate={manualShippingDate}
          setManualShippingDate={setManualShippingDate}
          oneTimeDeliveryOfMixedCart={oneTimeDeliveryOfMixedCart}
          setOneTimeDeliveryOfMixedCart={setOneTimeDeliveryOfMixedCart}
          isDrawer={isDrawer}
        />
      </StyledGrid>
    </>
  ) : (
    <>
      {addressId !== selectedAddressId && variant !== "MyAccount" ? (
        <>
          {/* My account - address cards */}
          {/* <StyledCard
            testId={`${widgetName}-${addressId}`}
            nickName={addressData.nickName ? addressData.nickName : ""}
            className={`address-card ${isSelected ? "selected" : ""}`}
            headerProps={headerComponent}
            contentComponent={contentComponent}
            cardActions={cardActions}
            confirmLabel={t("AddressCard.Confirm")}
            cancelLabel={t("AddressCard.Cancel")}
            /> */}
          <StyledRadioCard
            value={addressId}
            nickName={addressData.nickName ? addressData.nickName : ""}
            variant={"checkoutAddress"}
            checked={false}
            onClick={() => setSelectedAddressId(addressId, nickName, isInstallation)}
            noMarginTop={true}
            disabled={loaderState.src === LoaderActions.CHECKOUT_SAVE_AND_CONFIRM_STEP_2}
          />
        </>
      ) : addressId !== selectedAddressId && variant === "MyAccount" ? (
        /* My account address card */
        <StyledCard
          testId={`${widgetName}-${addressId}`}
          nickName={addressData.nickName ? addressData.nickName : ""}
          className={`address-card ${isSelected ? "selected" : ""}`}
          headerProps={headerComponent}
          contentComponent={contentComponent}
          cardActions={cardActions}
          confirmLabel={t("AddressCard.Confirm")}
          cancelLabel={t("AddressCard.Cancel")}
          isPrimaryAddress={isPrimaryAddress}
          variant={variant}
        />
      ) : (
        <>
          <StyledRadioCard
            value={addressId}
            nickName={addressData.nickName ? addressData.nickName : ""}
            variant={"checkoutAddress"}
            checked={true}
            noMarginTop={true}
          />
          <StyledCard
            testId={`${widgetName}-${addressId}`}
            nickName={addressData.nickName ? addressData.nickName : ""}
            className={`address-card ${isSelected ? "selected" : ""}`}
            headerProps={headerComponent}
            contentComponent={contentComponent}
            cardActions={cardActions}
            confirmLabel={t("AddressCard.Confirm")}
            cancelLabel={t("AddressCard.Cancel")}
          />
        </>
      )}
      {/* {variant === "MyAccount" ? (
        <StyledCard
          testId={`${widgetName}-${addressId}`}
          nickName={addressData.nickName ? addressData.nickName : ""}
          className={`address-card ${isSelected ? "selected" : ""}`}
          headerProps={headerComponent}
          contentComponent={contentComponent}
          cardActions={cardActions}
          confirmLabel={t("AddressCard.Confirm")}
          cancelLabel={t("AddressCard.Cancel")}
        />
      ) : null} */}
    </>
  );
};

export { AddressCard };

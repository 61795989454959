import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
//Standard libraries
import { useTranslation } from "react-i18next";
import { StyledGrid, StyledTypography } from "../../../../elements";
import FormattedPriceDisplay from "../../../../widgets/formatted-price-display";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { GiftCard } from "../giftCard";
import { useSite } from "../../../../../_foundation/hooks/useSite";

const Cybersource = ({ ...props }) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const orderData = props.orderData;
  const paymentInstruction = orderData?.paymentInstruction;
  let cardNo = "";
  let authorizationCode = "";
  let transDate = "";
  let previousBalance = "";
  let redimedPoints = "";
  let updatedBalance = "";
  let isBankPointsOptIn = "false";
  const installments: any = [];
  const numFor = Intl.NumberFormat("en-US");

  const currentTime = new Date();
  let hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  let meridiem = 'AM';

  if (hours >= 12) {
    meridiem = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  }

  const transDateHour = minutes < 10 
    ? (hours < 10 ? `0${hours}:0${minutes} ${meridiem}` : `${hours}:0${minutes} ${meridiem}`)
    : (hours < 10 ? `0${hours}:${minutes} ${meridiem}` : `${hours}:${minutes} ${meridiem}`);

  const giftCardPaymentInfo = paymentInstruction.filter((pi) => pi.payMethodId == "GiftCard");

  if (paymentInstruction.length > 0) {
    console.log("paymentInstruction", paymentInstruction);
    const protocolData = paymentInstruction[0].protocolData;
    if (protocolData.length > 0) {
      cardNo = protocolData.filter((data) => data.name === "cardNo")[0]?.value?.slice(-4);
      authorizationCode = protocolData.filter((data) => data.name === "authorizationCode")[0]?.value;
      const trasDateDate = orderData?.x_lastUpdate?.split(" ").slice(0, 3).join(" ");
      transDate = `${trasDateDate} ${transDateHour}`

      isBankPointsOptIn = protocolData.filter((data) => data.name === "isBankPointsOptIn")[0]?.value;
      paymentInstruction.map((instruction) => {
        if (instruction.protocolData.length > 0) {
          installments.push(instruction.protocolData.filter((data) => data.name === "MSI_Month")[0]?.value);
        }
      });

      if (isBankPointsOptIn && isBankPointsOptIn === "true") {
        previousBalance = numFor.format(protocolData.filter((data) => data.name === "PreviousBalance")[0]?.value);
        redimedPoints = numFor.format(protocolData.filter((data) => data.name === "RedeemedPoints")[0]?.value);
        updatedBalance = numFor.format(protocolData.filter((data) => data.name === "UpdatedBalance")[0]?.value);
      }
    }
  }
  return (
    <>
      <StyledGrid container>
        <StyledGrid xs={12}>
          <StyledTypography variant="headingFeatured" className={"orderConfirmation--styledTypography-upperCase"}>
            {t("OrderConfirmation.PaymentMethod.Cybersource.nameConfim")}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item xs={12} className={"top-margin-2"}>
          <StyledTypography
            variant="bodyBaseline"
            className={"orderConfirmation--StyledTypography-darkGray"}
            component={"span"}>
            {t("OrderConfirmation.PaymentMethod.Cybersource.cardNum")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" **** " + cardNo}
          </StyledTypography>
        </StyledGrid>
        {!isB2B && installments.map((installment) => (
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.Cybersource.installments")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" "}
              {installment === "01"
                ? t("OrderConfirmation.PaymentMethod.Cybersource.simplePay")
                : parseInt(installment) + " " + t("OrderConfirmation.PaymentMethod.Cybersource.installmenPay")}
            </StyledTypography>
          </StyledGrid>
        ))}
        <StyledGrid item xs={12} className={"top-margin-2"}>
          <StyledTypography
            variant="bodyBaseline"
            className={"orderConfirmation--StyledTypography-darkGray"}
            component={"span"}>
            {t("OrderConfirmation.PaymentMethod.Cybersource.authNumber")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" " + authorizationCode}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item xs={12} className={"top-margin-2"}>
          <StyledTypography
            variant="bodyBaseline"
            className={"orderConfirmation--StyledTypography-darkGray"}
            component={"span"}>
            {t("OrderConfirmation.PaymentMethod.Cybersource.transDate")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" " + transDate}
          </StyledTypography>
        </StyledGrid>
        {isBankPointsOptIn === "true" && (
          <StyledGrid xs={12}>
            <Divider className="vertical-margin-2" />
            <StyledTypography variant="textSegment" align="center" className="bottom-margin-2" gutterBottom>
              {t("OrderConfirmation.PaymentMethod.Cybersource.complementaryMethod")}
            </StyledTypography>
            <StyledGrid xs={12}>
              <StyledTypography variant="headingFeatured" className={"orderConfirmation--styledTypography-upperCase"}>
                {t("OrderConfirmation.PaymentMethod.Cybersource.bbvaPoints")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item xs={12} className={"top-margin-2"}>
              <StyledTypography
                variant="bodyBaseline"
                className={"orderConfirmation--StyledTypography-darkGray"}
                component={"span"}>
                {t("OrderConfirmation.PaymentMethod.Cybersource.PreviousBalance")}
              </StyledTypography>
              <StyledTypography
                variant="bodyBaseline"
                weight={"semibold"}
                className={"top-margin-1"}
                component={"span"}>
                {" " + previousBalance + " " + t("OrderConfirmation.PaymentMethod.Cybersource.points")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item xs={12} className={"top-margin-2"}>
              <StyledTypography
                variant="bodyBaseline"
                className={"orderConfirmation--StyledTypography-darkGray"}
                component={"span"}>
                {t("OrderConfirmation.PaymentMethod.Cybersource.redeemedPoints")}
              </StyledTypography>
              <StyledTypography
                variant="bodyBaseline"
                weight={"semibold"}
                className={"top-margin-1"}
                component={"span"}>
                {" " + redimedPoints + " " + t("OrderConfirmation.PaymentMethod.Cybersource.points")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item xs={12} className={"top-margin-2"}>
              <StyledTypography
                variant="bodyBaseline"
                className={"orderConfirmation--StyledTypography-darkGray"}
                component={"span"}>
                {t("OrderConfirmation.PaymentMethod.Cybersource.updatedBalance")}
              </StyledTypography>
              <StyledTypography
                variant="bodyBaseline"
                weight={"semibold"}
                className={"top-margin-1"}
                component={"span"}>
                {" " + updatedBalance + " " + t("OrderConfirmation.PaymentMethod.Cybersource.points")}
              </StyledTypography>
            </StyledGrid>
          </StyledGrid>
        )}
        {/* Complementary payment method */}

        {giftCardPaymentInfo.length > 0 && (
          <>
            <StyledGrid xs={12}>
              <Divider className="vertical-margin-2" />
              <StyledTypography variant="textSegment" align="center" gutterBottom>
                {t("OrderConfirmation.PaymentMethod.complementary")}
              </StyledTypography>
              <GiftCard orderData={orderData} orderDataTYP={props.orderDataTYP} storeConfData={props.storeConfData} />
            </StyledGrid>
          </>
        )}
      </StyledGrid>
    </>
  );
};

export { Cybersource };

import styled from "styled-components"
import { StyledBox, StyledGrid, StyledTypography } from "../../elements"


const ContainerStyledHDmHeadingTitle = styled((props: any) => <StyledGrid {...props} />)`
${({theme}) => `

    .contact-us-main-title{
        text-align: center;
        color: ${theme.palette.primary.main};
        font-weight: 300;
        font-size: 36px;
        width: fit-content;
        margin: auto;
        background-color: ${theme.palette.gray["50"]};
        padding: 0 ${theme.spacing(1)}px;
        margin-bottom: ${theme.spacing(2)}px;
    }

    .middle-line{
        border: solid 1px ${theme.palette.gray["100"]};
        transform: translateY(23px);
        z-index: -1;
        position: relative;
    }

    @media (max-width: 600px) {
        .contact-us-main-title{
            text-align: center;
            color: ${theme.palette.primary.main};
            font-weight: 300;
            font-size: 30px;
            width: fit-content;
            margin: auto;
            background-color: ${theme.palette.gray["50"]};
            padding: 0 ${theme.spacing(1)}px;
            margin-bottom: ${theme.spacing(2)}px;
        }
        .middle-line {
            border: solid 1px ${theme.palette.gray["100"]};
            transform: translateY(20px);
            z-index: -1;
            position: relative;
        }
    }

`}
`


export const HDmHeadingTitle = (props) =>{

    const { title } = props
    return (
        <ContainerStyledHDmHeadingTitle item xs={12}>
            <StyledBox className="middle-line"/>
            <StyledTypography variant="headingMainTitle" component="h1" className="contact-us-main-title">
               {title}
            </StyledTypography>
        </ContainerStyledHDmHeadingTitle>

    )
}
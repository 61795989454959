import React, { FC } from "react";
import { StyledTypography } from "../../../hdm";
// import { useTranslation } from "react-i18next";
import { ContainerStyledBox } from "./container-price-format";
import { useSelector } from "react-redux";
import { giftCardSelector } from "../../../redux/selectors/order";

interface IPricedFormatProps {
  priceUnformatted: string | number | JSX.Element;
  TypographyVariant?: string;
  discountType?: boolean;
  strikethrough?: boolean;
  isMerchandising?: boolean;
}

const StyledPriceFormat: FC<IPricedFormatProps> = ({
  priceUnformatted,
  TypographyVariant = "textPrice",
  discountType,
  strikethrough,
  isMerchandising = false
}) => {
  // const { t } = useTranslation();
  const giftCardData = useSelector(giftCardSelector);
  const freeString = "Gratis";

  function numberWithCommas(price) {
    const allPrices = {
      displayPrice: price
        ? parseFloat(price)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0.00",
      displayUnidad: price
        ? price
            .toString()
            .split(".")[0]
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0",
      displayDecimal: price ? parseFloat(price).toFixed(2).toString().split(".")[1] : "00",
    };

    return allPrices;
  }

  const price = numberWithCommas(priceUnformatted);
  let isFreeProduct = false;

  
  if (price && parseInt(price.displayUnidad.replace(/,/g,"")) <= 1 && price.displayDecimal === "00") {
    isFreeProduct = true;
  }

  const PromocionPrecio = () => {
    return (
      <>
        {!giftCardData && isFreeProduct && !isMerchandising? (
          <StyledTypography
            id={"orderTotal"}
            variant="bodyBaseline"
            fontWeight={600}
            className={`${"total-discount"} ${strikethrough ? "line-through" : ""}`}>
            {freeString}
          </StyledTypography>
        ) : (
          <StyledTypography
            id={"orderTotal"}
            variant={TypographyVariant}
            fontWeight={discountType && TypographyVariant === "textSecondaryPrice" ? 600 : 400}
            className={`${discountType ? "total-discount" : ""} ${strikethrough ? "line-through" : ""}`}>
            <sup style={TypographyVariant === "textSecondaryPrice" ? { fontSize: "10px" } : { fontSize: "16px" }}>
              $
            </sup>
            <span>{price.displayUnidad}</span>
            <sup style={TypographyVariant === "textSecondaryPrice" ? { fontSize: "10px" } : { fontSize: "16px" }}>
              {price.displayDecimal}
            </sup>
          </StyledTypography>
        )}
      </>
    );
  };

  return <ContainerStyledBox>{<PromocionPrecio />}</ContainerStyledBox>;
};

export default StyledPriceFormat;

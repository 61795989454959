import styled from "styled-components";
import { StyledGrid, StyledBox, StyledSwipeableDrawer } from "../../../hdm/elements";
import { palette } from "../../../hdm/themes/color-palette";
import { tabletBreakpoint } from "../../../hdm/functions/utils";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RoomIcon from "@material-ui/icons/Room";
import { Dialog } from "@material-ui/core";

export const ContainerCheckoutProfile = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

    .styled--createcheckoutporfile-left {
      width: calc((100% - 20px) / 3);
      
      ${theme.breakpoints.down("sm")} {
        width: calc(100%);
        margin-bottom: 10px;
      }
      
    }

    .card-crear-perfil-compra-der {
      width: calc((100% / 3) * 2);
      margin-left: 10px;
      ${theme.breakpoints.down("sm")} {
        width: calc(100%);
        margin-left: 0px;
      }
    }

    .styled--checkoutprofile-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      ${theme.breakpoints.down("sm")} {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

      }

    }

    .styled--checkoutprofilecard-container {

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: 202px;
      cursor: pointer;
      ${theme.breakpoints.up("md")} {
        margin-right: 10px;
      }
      ${theme.breakpoints.down("sm")} {
        justify-content: ;
        height: auto;
      }
    }

    .stylegrid--misperfilesdecompra-container {
      ${theme.breakpoints.up("md")} {
        padding: 20px;
      }
      ${theme.breakpoints.down("sm")} {
        padding-left: 5px;
      }
    }

    .gray200 {
      color: ${palette.gray["200"]};
    }
    
    .gray300 {
      color: ${palette.gray["300"]};
    }

    .card-direccion-entrega {
      width: calc((100% - 10px) / 2);
      margin-right: 10px;

      ${theme.breakpoints.down("sm")} {
        width: calc(100%);
      }
    }

    .card-metodo-pago {
      width: calc((100% - 10px) / 2);
      ${theme.breakpoints.down("sm")} {
        width: calc(100%);

        .paypal--change-payment-method {
          margin-left:auto;
        }
      }
    }

    .container-fixdown-drawer {
      minHeight: 50px;
      width: 100%;
      background: white;
    }

    .container-fixdown {
      display: flex;
      flex-direction: column;
      padding: 5px;
      align-items: center;
    }

    .container-fixdown-sticky {
      display: flex;
      flex-direction: column;
      padding: 10px 5px;
      align-items: center;
      box-shadow: 0px -4px 3px rgb(0 0 0 / 12%);
    }

    .checkout-profile-card {
      width: calc((100% - 20px) / 3);
      ${theme.breakpoints.down("sm")} {
        width: calc(100%);
      }

    }

    .sticky-down {
      position: absolute;
      bottom: 0px; 
      width: calc(100% - 20px);
      background: white;
    }

    .direcciones-overflow {
      flex: 1;
      overflow: auto;
    }

    .styled--emptycheckoutprofile-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 202px;
      padding: 10px;
      ${theme.breakpoints.up("md")} {
        &.padding-4 {
          padding: 20px;
        }
      }
    }

    .styled--emptyCheckoutProfileIcon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 130px;
      padding: 10px;
      border-radius: 50%;
      background-color: ${palette.gray["50"]}
    }

    .styled--createprofileicon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      padding: 10px;
      border-radius: 50%;
      background-color: ${palette.gray["50"]};
      
      ${theme.breakpoints.down("sm")} {
        width: 40px;
        height: 40px;
      }
    }

    .styled--savetimeonpurchase-text {
      color: ${palette.gray["300"]};
      text-align: -webkit-center;
    }

    .styled--AssignmentInSharp-svg {
      width: 80px;
      height: 80px;
      color: #ffffff;
    }

    .styled--boltsharpicon-container {
      height: 20px;
    }

    .styled--leftcard-center {
      text-align: -webkit-center;
    }

    .styled--chevronright-icon {
      height: 24px;
      color:${palette.primary.main}
    }

    .styled--createprofilewording-container {
      padding-left: 10px;
      flex-grow: 1;
    }

    .styled--checkoutprofileleft-container {
      display: flex;
      flex-direction: row;
      ${theme.breakpoints.down("sm")} {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
      }

    }

    .styled--accountlinks-container {
      display: block;
      ${theme.breakpoints.down("sm")} {
        display: none;
      }
    }
    
    .styled--profileCreate-container {
      ${theme.breakpoints.up("md")} {
        padding: 20px
      }
    }

    .styled--alias-container {
      margin-bottom: 10px;
      ${theme.breakpoints.down("sm")} {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .styled--direccionespagos-container {
      display: flex;
      flex-direction: row;
      ${theme.breakpoints.down("sm")} {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

      }

    }

    .styled--savecancelactions-container {
      padding: 10px;
      ${theme.breakpoints.down("sm")} {
        margin: 0px 10px;
      }
    }

    .styled--cancelCreate-link {
        padding: 10px 0;
    }

    &.styled--createDirectionDrawer {
      height: 100%;
      display: flex;
      flex-direction: column;

      .styled--createDirectionDrawer-content {
        flex: 1 1 0%;
        overflow: auto;
      }
    }

  `}
`;

export const ContainerStylediv = styled.div`
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1;
    overflow: auto;
  }
`;

export const StyledSwipeableDrawersDireccion = styled((props: any) => <StyledSwipeableDrawer {...props} />)`
  ${({ theme }) => `

  .MuiDrawer-paper{
    //position: relative;
    width: 90% !important;
    padding: 0!important;
    overflow-x: hidden;
    max-width: 360px!important;
    min-width: 288px!important;
  }
  .MuiDrawer-root{
    width: 90%;
  }

`}
`;

export const StyledSwipeableDrawersAgregarTarjeta = styled((props: any) => <StyledSwipeableDrawer {...props} />)`
  ${({ theme }) => `

  .MuiDrawer-paper{
    //position: relative;
    width: 90% !important;
    padding: 0!important;
    overflow-x: hidden;
    max-width: 360px!important;
    min-width: 288px!important;
  }
  .MuiDrawer-root{
    width: 90%;
  }

  .full-height {
    height: 100%;
  }

  .margin-0 {
    margin: 0px;
  }

  .position-padding-10{
    position: absolute;
    right: 0px;
    top: 0px;
    padding: ${theme.spacing(2)}px;
  }

  .color-cursor{
    color: ${theme.palette.gray["100"]};
    cursor: pointer;
  }

  .divider-color{
    backgroundColor: ${theme.palette.gray["50"]};
    margin-bottom: ${theme.spacing(4)}px;
  }

  .left-padding{
    padding-left: ${theme.spacing(2)}px;
  }

  .top-left-padding{
    padding-top: ${theme.spacing(4)}px;
    padding-left: ${theme.spacing(2)}px;
  }

  .right-align-text{
    text-align: right;
    padding-right: ${theme.spacing(2)}px;
  }

  .icon-plus{
    width: ${theme.spacing(2)}px;
    height: 10px;
    vertical-align: middle;
  }

  .left-margin{
    margin-left: ${theme.spacing(1)}px;
  }

  .checkoutprofile-edit-buttons-container{
    padding: 0px ${theme.spacing(2)}px;
  }

  .checkoutprofile-confirm-edit{
    & button{
      margin: 0;
    }
  }

  .checkoutprofile-cancel-edit{
    & button{
      margin: 0;
    }
  }

  .container-fixdown {
    color: red;
    & button{
      margin: 0;
    }
  }

  // // .save-card-button{
  // //   margin: 0;
  // // }

  .gray300 {
      color: ${palette.gray["300"]};
    }
  }
`}
`;

export const StyledRadioCardContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    width: 100%;
    display:flex;
    height: 40px;
    align-items: center;

    &.container{
      outline: 1px solid ${theme.palette.secondary.main};
      border-radius: 1px;
    }

    &:hover {
      outline: 1px solid ${theme.palette.primary.main};
      cursor: pointer;
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
    }
    &.successIconRadio{
      justify-content: flex-end;
    }
  `}
`;

export const StyledRadioCardSuccessContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    width: 100%;
    display:flex;
    height: 40px;
    align-items: center;

    &.container{
      outline: 1px solid ${theme.palette.secondary.main};
      border-radius: 1px;
    }

    &:hover {
      cursor: pointer;
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
    }
    &.successIconRadio{
      justify-content: flex-end;
    }
  `}
`;

export const StyledSuccessRoomIcon = styled(({ ...props }) => <RoomIcon {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.success.main} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
  `}
`;

export const StyledUncheckedRadioIcon = styled(({ ...props }) => <RadioButtonUncheckedIcon {...props} />)`
  ${({ theme }) => `
    color: ${palette.gray["100"]} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
`}
`;

export const StyledSuccessIconRadio = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  background-color: ${theme.palette.success.main};
  width: ${theme.spacing(5)}px;
  padding-top: ${theme.spacing(1)}px;
  height: 40px;
  display: inline-block;
  color: ${theme.palette.text.expandedMenu};
  text-align: center;
`}
`;

export const StyledDialogEliminarDir = styled((props) => <Dialog {...props} />)`
  ${({ theme }) => `

  &.dialog-eliminar-direccion {

    .MuiPaper-root.MuiDialog-paper {
      max-width: 400px;
    }

  }
`}
`;

import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";
import { StyledTypography } from "../../../hdm";

interface ISpecialTitleHDMProps {
  titleText: string;
  component?: string;
  marginBottom?: number;
}

const SpecialTitleContainer = styled((props: any) => <Box {...props} />)`
  ${({ theme, marginBottom }) => `
        margin-bottom: ${theme.spacing(marginBottom)}px;

        & .text-margin{
            padding-bottom: ${theme.spacing(2)}px;
        }

        & .primary-color-divider{
            width: fit-content;
            border-bottom: solid 2px ${theme.palette.primary.main}; 
        }

        & .gray-color-divider{
            border-bottom: solid 1px ${theme.palette.gray["50"]}; 
            flex-grow: 1;
            margin-bottom: ${theme.spacing(1)}px;
        }

    `}
`;

const SpecialTitleHDM: FC<ISpecialTitleHDMProps> = ({
  titleText,
  component = "h1",
  marginBottom = 0,
}) => {
  return (
    <>
      <SpecialTitleContainer marginBottom={marginBottom}>
        <StyledTypography
          component={component}
          variant="headingSubhead"
          className="text-margin primary-color-divider"
        >
          {titleText}
        </StyledTypography>
        <Box className="gray-color-divider" />
      </SpecialTitleContainer>
    </>
  );
};

export default SpecialTitleHDM;

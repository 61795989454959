import axios from "axios";
import { getExternalId } from "../pinterest/pinterest-conversion-utils";
import { getCookieValue } from "../cookieHandler";

const getTtclid = ( ) =>{
    const ttclid = sessionStorage.getItem("ttclid");

    return (ttclid!=undefined && ttclid!=null) ? ttclid  : "";
}

const getPageDetails = () =>{
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const referrer = `${url.protocol}//${url.host}/`;
    return [referrer, currentURL];
}

const extractSearchTerm = (url) =>{
    const urlObj = new URL(url);
    const segments = urlObj.pathname.split('/');
    const searchTerm = segments.filter(segment => segment != '').pop();
    return searchTerm;
}

export const triggerConversionTikTokPageView = async (storeId, mfbt, userAgent) => {
    const ttp = getCookieValue("_ttp");
    const externalId = getExternalId();
    const ttclid = getTtclid();
    const [  referrer, currentURL] = getPageDetails();
    const payload = {
        eventName: "Pageview",
        mfbt: mfbt,
        externalId:externalId,
        ttclid: (ttclid != null && ttclid !=undefined)?ttclid:"",
        ttp:(ttp != null && ttp !=undefined)?ttp:"",
        userAgent:userAgent,
        eventSource:currentURL,
        referrer: referrer,

    }
    
    console.log(`TEST Tiktok valores de SKUs \n\n ${JSON.stringify(payload)}`); 
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/convertions/tiktok/events?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("Tiktok PV OK ");
        }
        console.log("Tiktok PV Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error TT PV: " + error);
    });
}


export const triggerConversionTikTokSearch = async (storeId) => {
    const ttp = getCookieValue("_ttp");
    const mfbt = getCookieValue("mfbt");
    const externalId = getExternalId();
    const ttclid = getTtclid();
    const userAgent = window.navigator.userAgent;
    const [  referrer, currentURL] = getPageDetails();
    const searchTerm = extractSearchTerm(currentURL);
    const payload = {
        eventName: "Search",
        mfbt: mfbt,
        externalId:externalId,
        ttclid: (ttclid != null && ttclid !=undefined)?ttclid:"",
        ttp:(ttp != null && ttp !=undefined)?ttp:"",
        userAgent:userAgent,
        eventSource:currentURL,
        referrer: referrer,
        searchTerm: searchTerm,
    }
    
    console.log(`TEST Tiktok Search \n\n ${JSON.stringify(payload)}`); 
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/convertions/tiktok/events?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("Tiktok Search OK ");
        }
        console.log("Tiktok Search Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error TT Search: " + error);
    });
}


export const triggerConversionTikTokViewContent = async (storeId, mfbt, userAgent, category, value, productId) => {
    const ttp = getCookieValue("_ttp");
    const externalId = getExternalId();
    const ttclid = getTtclid();
    const [  referrer, currentURL] = getPageDetails();
    const payload = {
        eventName: "ViewContent",
        mfbt: mfbt,
        externalId:externalId,
        ttclid: (ttclid != null && ttclid !=undefined)?ttclid:"",
        ttp: (ttp !=undefined && ttp != null) ? ttp : "",
        userAgent:userAgent,
        eventSource:currentURL,
        referrer: referrer,
        category: category,
        value: value,
        productId: productId,
    }
    
    console.log(`TEST Tiktok valores de SKUs \n\n ${JSON.stringify(payload)}`); 
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/convertions/tiktok/events?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("Tiktok PV OK ");
        }
        console.log("Tiktok PV Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error TT PV: " + error);
    });
}

export const triggerConversionTikTokAddToCart = async (storeId, mfbt, userAgent, externalId, products) => {
    
    const ttp = getCookieValue("_ttp");
    const ttclid = getTtclid();
    const [  referrer, currentURL] = getPageDetails();
    const payload = {
        eventName: "AddToCart",
        mfbt: mfbt,
        externalId:externalId,
        ttclid: (ttclid != null && ttclid !=undefined)?ttclid:"",
        ttp:(ttp != null && ttp !=undefined)?ttp:"",
        userAgent:userAgent,
        eventSource:currentURL,
        referrer: referrer,
        productsPayload: products,
    }
    
    console.log(`TEST Tiktok valores de AddToCart \n\n ${JSON.stringify(payload)}`); 
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/convertions/tiktok/events?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("Tiktok PV OK ");
        }
        console.log("Tiktok PV Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error TT PV: " + error);
    });
}

export const triggerConversionTikTokOrderCheckoutEvents = async (storeId, orderId, eventName) => {
    const ttp = getCookieValue("_ttp");
    const mfbt = getCookieValue("mfbt");
    const externalId = getExternalId();
    const ttclid = getTtclid();
    const userAgent = window.navigator.userAgent;
    const [referrer, currentURL] = getPageDetails();
    const payload = {
        eventName: eventName,
        mfbt: mfbt,
        externalId: externalId,
        ttclid: (ttclid != null && ttclid != undefined) ? ttclid : "",
        ttp: (ttp != null && ttp != undefined) ? ttp : "",
        userAgent: userAgent,
        eventSource: currentURL,
        referrer: referrer,
        orderId: orderId,
    }

    console.log(`TEST Tiktok valores de Order Checkout events \n\n ${JSON.stringify(payload)}`);
    await axios
        .post(`/wcs/resources/store/${storeId}/hdm/convertions/tiktok/events?responseFormat=json`, payload)
        .then((res) => {
            if (res && res?.data) {
                console.log("Tiktok PV OK ");
            }
            console.log("Tiktok PV Response value:  " + res);
        })
        .catch((error) => {
            console.log("Error TT PV: " + error);
        });
}

export const triggerConversionTikTokSuscribeEvent = async (storeId, email) => {
    const ttp = getCookieValue("_ttp");
    const externalId = getExternalId();
    const mfbt = getCookieValue("mfbt");
    const ttclid = getTtclid();
    const userAgent = window.navigator.userAgent;
    const [referrer, currentURL] = getPageDetails();
    const payload = {
        eventName: "Subscribe",
        mfbt: mfbt,
        externalId: externalId,
        ttclid: (ttclid != null && ttclid != undefined) ? ttclid : "",
        ttp: (ttp != null && ttp != undefined) ? ttp : "",
        userAgent: userAgent,
        eventSource: currentURL,
        referrer: referrer,
        emailId: email,
    }

    console.log(`TEST Tiktok valores de SKUs \n\n ${JSON.stringify(payload)}`);
    await axios
        .post(`/wcs/resources/store/${storeId}/hdm/convertions/tiktok/events?responseFormat=json`, payload)
        .then((res) => {
            if (res && res?.data) {
                console.log("Tiktok PV OK ");
            }
            console.log("Tiktok PV Response value:  " + res);
        })
        .catch((error) => {
            console.log("Error TT PV: " + error);
        });
}
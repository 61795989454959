/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Custom libraries
import { OTHER_COLONY, REG_EX, SELECTCOLONY } from "../constants/common";

const addressUtil = {
  optionalAddressFields: [
    "addressLine3Txt",
    "phone1",
    "firstName",
    "lastName",
    "email1",
    "country",
    "attributes",
    "field3",
    "field1",
  ],
  addressFormFields: [
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipCode",
    "nickName",
    "addressType",
    "addressLine3",
    "phone1",
    "firstName",
    "lastName",
    "email1",
    "country",
    "field3",
    "field1",
    "addressLine3Txt",
  ],

  validateAddressForm: (formData: any, edit: boolean, isUpdateAddress?: boolean, userLoggedIn?: boolean) => {
    const editVal = edit ? edit : false;

    for (const key in formData) {
      if (!isUpdateAddress && (!formData["nickName"] || formData["nickName"].trim().length === 0)) {
        return false;
      }
      
      if (isUpdateAddress && edit && key === "nickName") {
        continue;
      }
      if (
        formData["addressLine1"] === undefined ||
        formData["addressLine1"] === "" ||
        formData["addressLine1"].trim().length === 0
      ) {
        return false;
      }
      if (
        formData["addressLine2"] === undefined ||
        formData["addressLine2"] === "" ||
        formData["addressLine2"].trim().length === 0
      ) {
        return false;
      }
      if (
        formData["addressLine3"] === undefined ||
        formData["addressLine3"] === "" ||
        formData["addressLine3"].trim().length === 0 ||
        formData["addressLine3"] === "0"
      ) {
        return false;
      }
      if (
        formData["city"] === undefined ||
        formData["city"] === "" ||
        formData["city"].trim().length === 0 ||
        formData["city"] === "0"
      ) {
        return false;
      }
      if (formData["addressLine3"] === OTHER_COLONY && formData["addressLine3Txt"] === "") {
        return false;
      }

      if (
        formData["addressLine3"].includes(SELECTCOLONY) &&
        (formData["addressLine3Txt"] === "" || formData["addressLine3Txt"] === undefined)
      ) {
        return false;
      }

      if (key === "addressLine3") {
        if (
          (formData["addressLine3"] === "" ||
            formData["addressLine3"] === "init" ||
            formData["addressLine3"] === OTHER_COLONY) &&
          formData["addressLine3Txt"] === undefined
        ) {
          return false;
        }
        continue;
      }

      if (!addressUtil.optionalAddressFields.includes(key)) {
        if (
          formData[key] !== undefined &&
          formData[key] !== "" &&
          (formData[key].trim() === "" || formData[key].trim() === "init")
        ) {
          return false;
        }
      }
    }

    /*if (!addressUtil.validatePhoneNumber(formData.phone1)) {
      return false;
    }
    if (!addressUtil.validateEmail(formData.email1)) {
      return false;
    }

    if (!editVal && !addressUtil.validateNickName(formData.nickName)) {
      return false;
    }*/
    return true;
  },

  validatePhoneNumber: (phoneNumber: string) => {
    const PHONE = REG_EX.PHONE;
    return phoneNumber === undefined || phoneNumber.trim() === "" || PHONE.test(phoneNumber.trim());
  },

  validateEmail: (email: string) => {
    const EMAIL = REG_EX.EMAIL;
    return email === undefined || email.trim() === "" || EMAIL.test(email);
  },
  validateVerificationToken: (verificationToken: string) => {
    const VERIFICATION_TOKEN = REG_EX.VERIFICATION_TOKEN;
    return (
      verificationToken === undefined || verificationToken.trim() === "" || !VERIFICATION_TOKEN.test(verificationToken)
    );
  },

  validateNickName: (nickName: string) => {
    const NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR = REG_EX.NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR;
    return nickName === undefined || nickName.trim() === "" || NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR.test(nickName);
  },

  removeLeadingTrailingWhiteSpace: (formData: any): any => {
    const result: any = {};
    // remove leading and trailing white space from all form input fields.
    if (formData !== undefined && formData !== null) {
      Object.keys(formData).forEach((key) => {
        const value = formData[key]?.trim();
        result[key] = value;
      });
    }
    return result;
  },

  removeIgnorableAddressFormFields: (formData: any): any => {
    const result: any = { ...formData };
    for (const key in result) {
      if (!addressUtil.addressFormFields.includes(key)) {
        delete result[key];
      }
    }
    return result;
  },

  getRegisteredInitialAddress: (address): any => {
    const keys = [
      "addressId",
      "addressLine",
      "addressType",
      "city",
      "country",
      "email1",
      "firstName",
      "lastName",
      "nickName",
      "phone1",
      "primary",
      "state",
      "zipCode",
      "addressLine2",
      "addressLine3",
      "field1",
      "field3",
    ].filter((k) => address[k] != null); // != is intentional (instead of !== since != [or ==] will equate null and undefined)
    const rc = Object.assign({}, ...keys.map((k) => ({ [k]: address[k] })));
    return rc;
  },
};

export default addressUtil;

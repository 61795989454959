import React, { useReducer, useCallback, useState, useMemo, Fragment, useEffect } from "react";
import Axios, { Canceler } from "axios";

import { useDispatch, useSelector } from "react-redux";

import { KitAvailabilityDataInterface } from "../../hdm/components/pdp-disponibilidad/KitAvailability";

// CAPI
import { getCookieValue, setCookieCAPI } from "../../components/custom-components/conversion-api/cookieHandler";
import { triggerAddToCartDL } from "../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { useSite } from "../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { useTranslation } from "react-i18next";
import {
  hdmColorsRdc,
  hdmDrawerColorDataRdc,
  hdmStoresDataRdc,
  installationPartNumber,
  lineaGamaColorsRdc,
  onlineStoreRdc,
} from "../../redux/selectors/hdmData";
import { currentStoreSelector } from "../../redux/selectors/current-store";
import {
  productDataActions,
  productDataInitData,
  productDataReducer,
} from "../../interfaces/menu/productDataInterface";
import {
  shopperActionsDataInitData,
  shopperActionsReducer,
  ShopperActionsList,
} from "../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsDataInterface";
import getDisplayName from "react-display-name";
import * as orderActions from "../../redux/actions/order";
import { StyledButton, StyledDialog, StyledDialogContent } from "../elements";
import * as customInv from "../../_foundation/utils/inventoryAvailability";
import { CLOSE_BACKDROP_LOADER_ACTION, OPEN_BACKDROP_LOADER_ACTION } from "../../redux/actions/backdrop-loader";
import { useEventCallback } from "@material-ui/core";
import { CLOSE_CART_DRAWER_ACTION, OPEN_CART_DRAWER_ACTION } from "../../redux/actions/drawer";
import { loginStatusSelector } from "../../redux/selectors/user";
import { proCustomerLevelSelector } from "../../redux/selectors/organization";
import { LoaderActions } from "../../components/custom-components/skeleton-loader/actions/sl-actions";
import { triggerConversionsPinterestAddToCart } from "../../components/custom-components/conversion-api/pinterest/pinterest-conversion-utils";

interface AddKitToKartProps {
  partNumber: string;
  productQuantity: number;
  kit: any;
  variant: string;
  wording: string;
}

let isKitAvailabilityInfoByStoreAlreadyCalled = false;
//kit Availability data
const kitAvailabilityInitData: KitAvailabilityDataInterface = {
  kitAvailabilityInfoByStore: [],
  getCompKitInventoryForAnotherStore: undefined,
  kitMarketAvailability: 0,
  kitCurrentStoreAvailability: 0,
  nextAvailableDate: "",
};

enum kitAvailabilityInfoByStoreActions {
  SET_KIT_AVAILABILITY_INFO_BY_STORE,
  SET_KIT_MARKET_AVAILABILITY,
  SET_KIT_CURRENT_STORE_AVAILABILITY,
  SET_COMP_KIT_INVENTORY_FOR_ANOTHER_STORE_FUNCTION,
}

function kitAvailabilityReducer(state: KitAvailabilityDataInterface, action) {
  switch (action.type) {
    case kitAvailabilityInfoByStoreActions.SET_COMP_KIT_INVENTORY_FOR_ANOTHER_STORE_FUNCTION:
      return { ...state, getCompKitInventoryForAnotherStore: action.payload };
    case kitAvailabilityInfoByStoreActions.SET_KIT_AVAILABILITY_INFO_BY_STORE:
      return { ...state, kitAvailabilityInfoByStore: action.payload };
    case kitAvailabilityInfoByStoreActions.SET_KIT_CURRENT_STORE_AVAILABILITY:
      return { ...state, kitCurrentStoreAvailability: action.payload };
    case kitAvailabilityInfoByStoreActions.SET_KIT_MARKET_AVAILABILITY:
      return { ...state, kitMarketAvailability: action.payload };
    default:
      throw new Error();
  }
}

const AddKitToKart = ({ partNumber, productQuantity, kit, variant, wording }: AddKitToKartProps) => {
  const { mySite } = useSite();
  const storeId: string = mySite ? mySite.storeID : "";
  const contract = useSelector(currentContractIdSelector);
  const [addItemActionTriggered, setAddItemActionTriggered] = useState<boolean>(false);
  const dispatch = useDispatch();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const { t } = useTranslation();
  const installationPartNumberData = useSelector(installationPartNumber);
  const [marketInventory, setMarketInventory] = useState<any>([]);
  const storeSelector = useSelector(currentStoreSelector);
  const widgetName = getDisplayName("ProductDetailsWidget");

  const currentMarketId = storeSelector && storeSelector?.currentStore?.marketId;
  const hdmStoresData = useSelector(hdmStoresDataRdc);
  const storeConfData = useSelector(onlineStoreRdc);
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);

  // const [kit, setKit] = useState<any>(null);
  const [kitAvailabilityData, kitAvailabilityDispatcher] = useReducer(kitAvailabilityReducer, kitAvailabilityInitData);
  const [productData, productDataDispatcher] = useReducer(productDataReducer, productDataInitData);
  const [shopperActionsData, shopperActionsDispatcher] = useReducer(shopperActionsReducer, shopperActionsDataInitData);
  const [kitPartsNumber, setKitPartsNumber] = useState<any>([]);
  const [kitQuantityProducts, setKitQuantityProducts] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const payloadBase: any = useMemo(
    () => ({
      storeId: storeId,
      cancelToken: new CancelToken((c) => cancels.push(c)),
    }),
    [cancels, storeId, CancelToken]
  );

  const processKitAvailabilityInfoByStore = (stores) => {
    type ComponentInventory = {
      [key: string]: Array<number>;
    };
    const componentsInventory: ComponentInventory = {};

    stores.map((store) => {
      if (Object.hasOwn(componentsInventory, store.physicalStoreName)) {
        //add elements to array
        componentsInventory[store.physicalStoreName].push(store.availableQuantity);
      } else {
        //create array
        componentsInventory[store.physicalStoreName] = [store.availableQuantity];
      }
    });

    const nonRepeatedStores: any = [];

    Object.keys(componentsInventory).forEach((key) => {
      nonRepeatedStores.push(stores.find((store) => store.physicalStoreName == key));
    });

    //reinitialize data
    kitAvailabilityDispatcher({
      type: kitAvailabilityInfoByStoreActions.SET_KIT_AVAILABILITY_INFO_BY_STORE,
      payload: [],
    });

    const availabilityByStoreArray: any = [];
    nonRepeatedStores.map((store) => {
      let storedata = hdmStoresData.filter((hdmStore) => hdmStore.stLocId === store.physicalStoreName)[0];

      storedata = JSON.parse(JSON.stringify(storedata));

      const inventories = componentsInventory[store.physicalStoreName];
      let availableQuantity = 0;
      if (inventories.length === kitPartsNumber.length) {
        availableQuantity = Math.min(...inventories);
      }
      storedata.availableQuantity = availableQuantity;
      availabilityByStoreArray.push(storedata);
    });
    kitAvailabilityDispatcher({
      type: kitAvailabilityInfoByStoreActions.SET_KIT_AVAILABILITY_INFO_BY_STORE,
      payload: availabilityByStoreArray,
    });
    // dont let another call happen
    isKitAvailabilityInfoByStoreAlreadyCalled = true;
  };

  useEffect(() => {
    // add logic only if needed on market inventory change
    const tiendaId = storeSelector.currentStore.stLocId;
    const currentMarket = marketInventory?.filter((market) => market?.physicalStoreName === tiendaId);

    //product data logic
    let marketQuantity = 0;
    let disponibilidad;

    const disponibilidadMarket = marketInventory;

    processKitAvailabilityInfoByStore(marketInventory);

    if (marketInventory && marketInventory.length !== 0) {
      disponibilidad = marketInventory.filter((market) => market.physicalStoreName === tiendaId)[0];

      marketInventory.map(
        (disponibilidad) => (marketQuantity = marketQuantity + parseInt(disponibilidad.availableQuantity))
      );
    }

    const disponibilidadEnTienda = disponibilidad?.availableQuantity ? parseInt(disponibilidad.availableQuantity) : 0;

    const nextAvailableInventoryDate = disponibilidad?.x_NextAvailableInventoryDate
      ? disponibilidad?.x_NextAvailableInventoryDate
      : "";

    productDataDispatcher({
      type: productDataActions.LOAD_DATA,
      payload: {
        disponibilidadMarket: disponibilidadMarket,
        marketQuantity: marketQuantity,
        disponibilidadEnTienda: disponibilidadEnTienda,
        nextAvailableInventoryDate: nextAvailableInventoryDate,
      },
    });
  }, [marketInventory]);
  const addToCart = useCallback(
    (componentsQuantity) => {
      console.log("handling addToCart ");
      if (!isAvailable) {
        setOpenModal(true);
        return;
      }
      dispatch(OPEN_CART_DRAWER_ACTION({source: "kitCart"}));
      dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.ADD_PRODUCT_TO_CART, idx: kitPartsNumber }));
      const hasInstallation = installationPartNumberData !== "";

      const eventId = setCookieCAPI("mfbt");
      const fbc = getCookieValue("_fbc");
      const fbp = getCookieValue("_fbp");
      const userAgent = window.navigator.userAgent;
      const eventSource = window.location.href;
      const marketId = getCookieValue(`WC_MarketId_${storeId}`);
      const pstore = getCookieValue(`WC_DefaultStore_${storeId}`);
      const payloadBase: any = {
        storeId: storeId,
        widget: widgetName,
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };

      if (productQuantity > 0 && kitPartsNumber.length > 0) {

        const quantitykits: number[] = hasInstallation ? [parseInt(componentsQuantity)] : []
        kitPartsNumber.map(pn => {
          quantitykits.push(kitQuantityProducts[pn] * parseInt(componentsQuantity))
        })

        const param = {
          partnumber: hasInstallation ? [installationPartNumberData, ...kitPartsNumber] : kitPartsNumber,
          quantity: quantitykits,
          contractId: contract,
          colorCode: "",
          mfbt: eventId,
          fbc: fbc,
          fbp: fbp,
          pstore: pstore,
          userAgent: userAgent,
          marketId: marketId,
          ...payloadBase,
        };
        dispatch(orderActions.ADD_ITEM_ACTION(param));
        setAddItemActionTriggered(true);
        const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
        triggerAddToCartDL(
          mySite.storeID,
          mySite?.isB2B && loginStatus && proCustomerLevel
            ? storeSelector?.currentStore?.stLocId + "_" + proCustomerLevel
            : storeSelector?.currentStore?.stLocId,
          contract,
          partNumber,
          productQuantity,
          itemAffiliation,
          storeSelector?.currentStore?.marketId,
          mySite?.isB2B
        );

        triggerConversionsPinterestAddToCart(
          mySite.storeID,
          mySite?.isB2B && loginStatus && proCustomerLevel ? storeSelector?.currentStore?.stLocId + "_" + proCustomerLevel : storeSelector?.currentStore?.stLocId,
          contract,
          partNumber,
          productQuantity,
          storeSelector?.currentStore?.marketId,
          mySite?.isB2B
        );

        // scroll down to the last added items
        const cartList = document.getElementsByClassName("product-list-container")[0];
        if (cartList) {
          setTimeout(() => {
            cartList.scrollTop = cartList?.scrollHeight;
          }, 300);
        }

        // Close cart drawer after ten seconds
        // setTimeout(() => {
        //   dispatch(
        //     CLOSE_CART_DRAWER_ACTION(() => {
        //       /* */
        //     })
        //   );
        // }, 10000);
      }
    },
    [
      contract,
      productQuantity,
      partNumber,
      dispatch,
      payloadBase,
      kitPartsNumber,
      installationPartNumberData,
      isAvailable,
    ]
  );

  useEffect(() => {
    if (kit && hdmStoresData && storeSelector) {
      getCompKitInventory();
    }
  }, [kit, hdmStoresData, storeSelector]);

  const getKitComponentsInventoryInCurrentStore = (inventory, pIds: Array<string>) => {
    // Here we have an array wit the inventory for all the components
    // I need to compare if all of them doesn't have stock, show an error message
    console.log("inventory ", inventory);
    const numberOfComponents = pIds.length;

    const componentsArrayInventoryInPhysicalStore: Array<number> = [];
    const componentsArrayInventoryInMarket: Array<number> = [];

    //be sure that both components are present on inventory response
    const currentStoreInventory = inventory.filter((data) => {
      const isPartnumber = pIds.lastIndexOf(data?.productId) >= 0;
      const isCurrentStore = data?.physicalStoreName === storeSelector?.currentStore?.stLocId;
      return isPartnumber && isCurrentStore;
    });

    // check components inventory
    currentStoreInventory.forEach((element) => {
      if (element["availableQuantity"]) {
        componentsArrayInventoryInPhysicalStore.push(parseInt(element["availableQuantity"]));
      }
      if (element["x_AvailableQuantityInMarket"]) {
        componentsArrayInventoryInMarket.push(parseInt(element["x_AvailableQuantityInMarket"]));
      }
    });

    //number of currentStoreInventory results must be equals to numbers of components in current kit
    if (
      currentStoreInventory.length === numberOfComponents &&
      numberOfComponents === componentsArrayInventoryInPhysicalStore.length
    ) {
      //check which kit component has the least available inventory
      const minCurrentStore = Math.min(...componentsArrayInventoryInPhysicalStore);
      //check which kit component has the least available market
      const minMarket = Math.min(...componentsArrayInventoryInMarket);
      if (minMarket != null && minMarket > 0) {
        // if at least 1 sku have inventory, we can add to cart
        setIsAvailable(true);
      }
      shopperActionsDispatcher({
        type: ShopperActionsList.LOAD_DATA,
        payload: {
          currentStoreAvailable: minCurrentStore,
          marketAvailable: minMarket,
        },
      });
      kitAvailabilityDispatcher({
        type: kitAvailabilityInfoByStoreActions.SET_KIT_CURRENT_STORE_AVAILABILITY,
        payload: minCurrentStore,
      });
      kitAvailabilityDispatcher({
        type: kitAvailabilityInfoByStoreActions.SET_KIT_MARKET_AVAILABILITY,
        payload: minMarket,
      });
      //shopperActionsData.currentStoreAvailable = Math.min(...componentsArrayInventoryInMarket);
    } else {
      //if any of the products is not available, just set to 0 the current kit inventory
      kitAvailabilityDispatcher({
        type: kitAvailabilityInfoByStoreActions.SET_KIT_CURRENT_STORE_AVAILABILITY,
        payload: 0,
      });
    }
  };
  const getCompKitInventory = () => {
    const allTiendasIds = hdmStoresData.filter((mktId) => mktId.marketId === currentMarketId);
    const physicalStore = hdmStoresData.filter((store) => store.stLocId === storeSelector?.currentStore?.stLocId);
    let physicalStoreUniqueId;
    if (physicalStore.length > 0) {
      physicalStoreUniqueId = physicalStore[0].uniqueID;
    }
    const AllUniqueFetchIds: any = [];
    //this line extract every items of the kit (dont remove)
    let pIds = [];
    pIds = kit.components.map((component) => component.uniqueID);
    ////

    //check inventory on current market
    allTiendasIds.map((tienda) => {
      AllUniqueFetchIds.push(tienda.uniqueID);
    });

    const parameters: any = {
      ...payloadBase,
      productIds: pIds.toString(),
    };

    customInv
      .getAvailabilityByStores({
        ...parameters,
        onlineStoreId: mySite.storeID,
        productavailable: true,
        fullfillment_type: "Store",
        type: "ItemBean",
        search: 2,
        physicalStoreId: [physicalStoreUniqueId],
      })
      .then((res) => {
        //check inventory for current physical store
        getKitComponentsInventoryInCurrentStore(res, pIds);
        //check inventory for market
      })
      .catch((e) => {
        console.error("Something went wrong!:", e);
      });

    const quantityProducts = new Object()
    const partNumbers = kit.components.map((component: any) => {
      quantityProducts[`${component.partNumber}`] = parseInt(component.quantity)
      return component.partNumber;
    });

    setKitPartsNumber(partNumbers);
    setKitQuantityProducts(quantityProducts)
  };
  const getCompKitInventoryForAnotherStore = useEventCallback(() => {
    console.log("getCompKitInventoryForAnotherStore");
    const allTiendasIds = hdmStoresData.filter((mktId) => mktId.marketId === currentMarketId);

    const AllUniqueFetchIds: any = [];
    //this line extract every items of the kit (dont remove)
    let pIds = [];
    pIds = kit.components.map((component) => component.uniqueID);
    ////

    //check inventory on current market
    allTiendasIds.map((tienda) => {
      AllUniqueFetchIds.push(tienda.uniqueID);
    });

    const parameters: any = {
      ...payloadBase,
      productIds: pIds.toString(),
    };

    !isKitAvailabilityInfoByStoreAlreadyCalled && dispatch(OPEN_BACKDROP_LOADER_ACTION({}));

    !isKitAvailabilityInfoByStoreAlreadyCalled &&
      customInv
        .getAvailabilityByStores({
          ...parameters,
          onlineStoreId: mySite.storeID,
          productavailable: true,
          fullfillment_type: "Store",
          type: "ItemBean",
          search: 2,
          physicalStoreId: AllUniqueFetchIds, //["12505", "12521"] <- array of all stores in the market
        })
        .then((res) => {
          //extract
          setMarketInventory(res);
        })
        .catch((e) => {
          console.error("something went wrong!:", e);
        })
        .finally(() => {
          dispatch(CLOSE_BACKDROP_LOADER_ACTION({}));
        });

    const quantityProducts = new Object()
    const partNumbers = kit.components.map((component: any) => {
      quantityProducts[`${component.partNumber}`] = parseInt(component.quantity)
      return component.partNumber;
    });

    setKitPartsNumber(partNumbers);
    setKitQuantityProducts(quantityProducts)
  }, [kit]);
  useEffect(() => {
    kitAvailabilityDispatcher({
      type: kitAvailabilityInfoByStoreActions.SET_COMP_KIT_INVENTORY_FOR_ANOTHER_STORE_FUNCTION,
      payload: getCompKitInventoryForAnotherStore,
    });
    return () => cancels.forEach((cancel) => cancel());
  }, []);
  console.log("isAvailable : ", partNumber, " ", isAvailable);
  const handleCloseModal = () => setOpenModal((value) => !value);
  return (
    <Fragment>
      <StyledButton
        className="add-to-cart"
        variant={variant}
        disableRipple={true}
        fullWidth
        onClick={() => addToCart(productQuantity)}
        disabled={productQuantity === null || productQuantity <= 0 ? true : false}>
        {wording}
      </StyledButton>
      <StyledDialog open={openModal} onClick={handleCloseModal} onClose={handleCloseModal}>
        <StyledDialogContent>{t("productDetail.Availability.labels.comboNoAvailable")}</StyledDialogContent>
        <StyledButton variant="text" color="secondary">
          {t("ChangePasswordSection.OkLabel")}
        </StyledButton>
      </StyledDialog>
    </Fragment>
  );
};

export default AddKitToKart;

import axios from "axios";
import { useEffect, useState } from "react";
import { numberWithCommas } from "./getPromoicons";
import { CHROME, EDGE, FIREFOX, MSIE, SAFARI } from "../../constants/common";
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export const AllowScroll = () => {
  const html = document.documentElement;
  html.style.position = "";
  html.style.overflow = "";
  document.body.style.position = "";
  document.body.style.overflow = "";
};

export const DisableScroll = () => {
  setTimeout(() => {
    const html = document.documentElement;
    html.style.position = "relative";
    html.style.overflow = "hidden";
    document.body.style.position = "relative";
    document.body.style.overflow = "hidden";
  }, 200);
};

export const AllowScrollWithTop = () => {
  const html = document.documentElement;
  html.style.position = "";
  html.style.overflow = "";
  html.style.margin = "";
  html.style.height = "";
  document.body.style.position = "";
  document.body.style.overflow = "";
  document.body.style.margin = "";
  document.body.style.height = "";
};

export const DisableScrollWithTop = () => {
  setTimeout(() => {
    const html = document.documentElement;
    html.style.position = "relative";
    html.style.overflow = "hidden";
    html.style.margin = "0";
    html.style.height = "100%";
    document.body.style.position = "relative";
    document.body.style.overflow = "hidden";
    document.body.style.margin = "0";
    document.body.style.height = "100%";
  }, 200);
};

export const getAllEspecificacionesTec = (productDetailTabsChildren: any) => {
  let attributes = [];
  if (productDetailTabsChildren && productDetailTabsChildren?.length !== 0) {
    attributes = productDetailTabsChildren
      .filter((attr) => attr?.displayable === "true" && attr?.usage === "Descriptive")
      .map((thisAttr) => {
        if (thisAttr?.values) {
          return {
            key: thisAttr?.id,
            atributo: thisAttr?.name,
            valor: thisAttr?.values[0]?.value,
          };
        }
      });
  }

  if (attributes?.length !== 0) {
    attributes.sort((a: any, b: any) => a.atributo.localeCompare(b.atributo));
  }

  return attributes;
};

export const getAllEspecificacionesTecForKits = (attributes: any) => {
  const propiedadesExcluidas = [
    "li_UPC",
    "li_FULFILLMENT_TYPE",
    "li_PRO_MARKET_BUYABLE_EXCLUDE",
    "li_MARKET_BUYABLE_EXCLUDE",
    "li_PRO_STORE_BUYABLE_EXCLUDE",
    "li_STORE_BUYABLE_EXCLUDE",
    "li_ONLINE_ONLY_SELLING_STORE",
    "li_PRO_STORE_ONLY_AVAILABLE",
    "li_STORE_ONLY_AVAILABLE",
  ];
  let especificacionesTec: any = null;
  let allEspecTec: any = [];
  if (attributes && attributes.length !== 0) {
    especificacionesTec = attributes.filter((productDetail) => productDetail.displayable === "true");
    if (especificacionesTec && especificacionesTec.length !== 0) {
      if (especificacionesTec && especificacionesTec.length !== 0) {
        especificacionesTec.map((especificacion) => {
          const espcTec = {
            key: especificacion.identifier,
            atributo: especificacion?.name,
            valor: especificacion.values.length > 0 ? especificacion.values[0].value : "",
          };

          if (propiedadesExcluidas.includes(espcTec.key)) return;

          allEspecTec.push(espcTec);
        });

        allEspecTec = allEspecTec.filter((espectec) => !espectec.atributo.includes(propiedadesExcluidas));

        allEspecTec = allEspecTec.filter((espectec) => espectec.atributo.trim() !== "" && espectec.valor.trim() !== "");
      }
    }
  }
  return allEspecTec;
};

let videos: any = [];
export const checkVideos = (attachments: any) => {
  if (attachments) {
    videos = attachments.filter((vid) => vid.usage === "MEDIA" && vid.attachmentAssetPath.includes("youtube") && vid?.name?.length > 1);
    // videos.push({
    //   attachmentAssetID: "3074457345617695049",
    //   attachmentAssetPath: "https://www.youtube.com/watch?v=cMS3x9eCBYg",
    //   image: "https://www.youtube.com/watch?v=cMS3x9eCBYg",
    // });
    // videos.push({
    //   attachmentAssetID: "3074457345617695050",
    //   attachmentAssetPath: "https://www.youtube.com/watch?v=tM-a89kHk-A",
    //   image: "https://www.youtube.com/watch?v=tM-a89kHk-A",
    // });
    // videos.push(product.attachments.filter(vid => vid.usage === "MEDIA")[0])
  }
  return videos;
};

export const tabletBreakpoint = () => {
  const value: number = 1025;
  return value;
};
export const mobileBreakpoint = () => {
  const value: number = 960;
  return value;
};
export const desktopBreakpoint = () => {
  const value: number = 1440;
  return value;
};

export const getMeasurementUnit = (value: string) => {
  switch (value) {
    case "C62":
      return "";
      break;
    case "MTK":
      return "m²";
      break;
    case "MTR":
      return "m";
      break;
    case "KGM":
      return "kg";
      break;
    case "LTR":
      return "l";
      break;
    case "":
      return "";
      break;
  }
};

/* Format price function */
export const formatProductPrice = (priceToFormat) => {
  const dollarUSLocale = Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" });
  const formattedValue = dollarUSLocale.format(priceToFormat);
  return formattedValue;
};

export const getEmailExist = async (data) => {
  const { email, storeID } = data;
  let emailExist = false;
  await axios
    .post(`/wcs/resources/store/${storeID}/cart/@self/guest_checkout?responseFormat=json`, {
      emailId: email,
      nickname: email,
    })
    .then((response) => {
      console.log("response", response);
      if (response.status === 200) {
        if (response?.data?.emailAvailablity) {
          emailExist = response.data.emailAvailablity;
        }
      }
    })
    .catch((e) => {
      console.log(e);
    });

  return emailExist;
};

export const getOrdenStatus = () => {
  const ordenStatus = {
    M: "En proceso",
    R: "En surtido",
    T: "En tránsito",
    S: "Surtido",
    ER: "En revisión",
    "¿?": "Pendiente de pago",
    D: "Entregado",
    X: "Devuelta",
    PX: "Devuelta",
  };
};

export const isCrediOrDebitExpired = (cardExpiryDate) => {
  const [mm, yy] = cardExpiryDate.split("/");
  const dateObj = new Date(+yy + 2000, mm - 1, 15); // months are 0 based and don't take the 1st due to timezones
  console.log(dateObj);
  return dateObj.getTime() < new Date().getTime();
};

export const paintColorsPalette = () => {
  return [
    {
      color: "#FFFFFF",
      name: "#N/D",
    },
    {
      color: "#FFFFFF",
      name: "Pasteles",
    },
    {
      color: "#5C5C5C",
      name: "Grises",
    },
    {
      color: "#744E3C",
      name: "Cafés",
    },
    {
      color: "#D01D1F",
      name: "Rojos",
    },
    {
      color: "#E7873C",
      name: "Naranjas",
    },
    {
      color: "#F8D65C",
      name: "Amarillos",
    },
    {
      color: "#6CA74D",
      name: "Verdes",
    },
    {
      color: "#19E3E3",
      name: "Aquas",
    },
    {
      color: "#417AD6",
      name: "Azules",
    },
    {
      color: "#846BC6",
      name: "Morados",
    },
  ];
};

export const getPaintLines = () => {
  return ["Premium Plus", "Premium", "Professional Advantage", "ULTRA"];
};

export const getPinturasJson = (dbColorsHDM) => {
  const marcas: any = [];
  const pinturasHDM: any = dbColorsHDM.BD;

  const AllPalettesGama = paintColorsPalette();

  // const allLineas = ["Premium Plus", "Premium", "Professional Advantage", "ULTRA"];

  // let marcaPremiumPlus: any = {}
  // const premiumPlus = pinturasHDM.filter((pintura) => pintura.marca === "BEHR" && pintura.linea === "Premium Plus");

  // allLineas.map((linea) => {
  // const marcaLinea = pinturasHDM.filter((pintura) => pintura.marca === "BEHR" && pintura.linea === linea);

  // let marcaPremiumPlus: any = {}
  // const premiumPlus = pinturasHDM.filter(pintura => pintura.marca === 'BEHR' && pintura.linea === 'Premium Plus')

  // const allPaintLines = getPaintLines()

  const allBranchColors: any = [];
  const allPaintLines: any = [];
  const allBaseColors: any = [];

  pinturasHDM.map((pintura) => {
    if (!allBranchColors.includes(pintura?.marca)) {
      allBranchColors.push(pintura?.marca);
    }
    if (!allPaintLines.includes(pintura?.linea)) {
      allPaintLines.push(pintura?.linea);
    }
    if (!allBaseColors.includes(pintura?.base)) {
      allBaseColors.push(pintura?.base);
    }
  });

  const allLineaGamasColors: any = [];
  const allLineColors: any = [];
  allBranchColors.map((branch) => {
    allPaintLines.map((paintLinea) => {
      const marcaLinea = pinturasHDM.filter((pintura) => pintura.marca === branch && pintura.linea === paintLinea);
      let allColores: any = [];

      if(marcaLinea?.length === 0) return

      AllPalettesGama.map((paletteGama) => {
        let colores: any = [];
        marcaLinea.map((branchLine) => {
          let gamaColores = branchLine.hue.filter((hue) => hue.gama === paletteGama.name)[0];
          if (!gamaColores) return;
          gamaColores = JSON.parse(JSON.stringify(gamaColores));
          colores = colores.concat(
            gamaColores.colores.map((color) => {
              color.base = branchLine?.base;
              color.gama = gamaColores?.gama;
              return color;
            })
          );
          allColores = allColores.concat(gamaColores.colores);
        });

        const gama = {
          marca: branch,
          linea: paintLinea,
          gama: paletteGama.name,
          colores: colores,
        };
        allLineaGamasColors.push(gama);
      });

      allLineColors.push({
        linea: paintLinea,
        colores: allColores,
      });
    });
  });

  return {
    allLineaGamasColors: allLineaGamasColors,
    hdmColors: {
      allBranchColors: allBranchColors,
      allPaintLines: allPaintLines,
      allBaseColors: allBaseColors,
      allLineColors: allLineColors,
    },
  };
};

export const checkIfHasColorBase = (hdmColors, product) => {
  const { manufacturer, attributes } = product;
  let baseColor: any = null;
  let branchColor: any = null;
  let lineColor: any = null;

  if (manufacturer && manufacturer !== "") {
    branchColor = hdmColors?.allBranchColors.find((branch) => manufacturer.includes(branch));
    if (!branchColor) return false;

    if (attributes && attributes?.length !== 0) {
      baseColor = attributes.find((attr) => attr.identifier.includes("BASE_") && attr.usage === "Defining");

      if (baseColor) {
        baseColor = baseColor?.values[0]?.value;
      }
    }

    // baseColor = "Neutra";

    if (!baseColor) return false;

    if (!hdmColors?.allBaseColors.find((base) => base === baseColor)) return false;

    lineColor = hdmColors.allPaintLines.find((line) => manufacturer.toLowerCase().includes(line.toLowerCase()));

    if (!lineColor) return false;
  } else {
    return false;
  }

  return { branchColor, lineColor, baseColor };
};

export const validateNaturalPersonRFC = (rfc: string) => {
  const expresionRFC = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/; // natural person rfc
  return expresionRFC.test(rfc);
};

export const validateLegalPersonRFC = (rfc: string) => {
  const expresionRFC = /^[A-Za-zÑñ&]{3}\d{6}[0-9A-Za-zÑñ]{3}$/; //legal person rfc
  return expresionRFC.test(rfc);
};

export const validateCURP = (curp: string) => {
  const expresionCURP = /^[A-Za-z]{4}\d{6}[HM][A-Za-z]{5}[A-Za-z0-9]{2}$/;
  return expresionCURP.test(curp);
};

export const validateFirstNameLastName = (value) => {
  // const patt = new RegExp(/^[A-Za-z-ñÑáéíóúÁÉÍÓÚ´\s]+$/g);
  const patt = new RegExp(/^[a-zA-Z0-9-ñÑáéíóúÁÉÍÓÚ´\s]+$/g);
  return patt.test(value);
};

export const validateEmailFormat = (value) => {
  // const patt = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const patt = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return patt.test(value.trim());
};

export const validateIsNumber = (value) => {
  const num = Number(value.trim());
  if (Number.isInteger(num)) {
    return true;
  }
  return false;
};

export const validateSameChars = (value) => {
  const patt = /^(.)\1+$/;
  return patt.test(value.trim());
};

export const gtmPDPLoaded = (sku: string, category1: string, category2: string, category3: string) => {
  window["dataLayer"].push({
    event: "page_load",
    ecommerce: {
      sku: sku,
      item_category: category1,
      item_category2: category2,
      item_category3: category3,
    },
  });

  const page_PDPload = new CustomEvent("page_load", {
    detail: {
      sku: sku,
      item_category: category1,
      item_category2: category2,
      item_category3: category3,
    },
    bubbles: true,
    cancelable: true,
    composed: false,
  });

  return dispatchEvent(page_PDPload);
};

export const formatFilterWidgetLabelforPromos= ( promoLabel: string ) => {
  switch (promoLabel) {
    case 'Gran Remate':
      return 'Remate';

    default:
      return promoLabel;
      
  }
}

export const SEARCH_CODE_PAINT_REGEX = /^[a-zA-Z0-9\s_,-]+$/;

// Recieves product.attributes as parameter
export const getProductModel = (productAttributes:any) => {
  let productModel:string = ""
      for (const obj of productAttributes) {
        if (obj.identifier === 'MODELO') {
          productModel = obj.values[0].value;
          break; 
      }}
  return productModel;
}

export const validatePricePROvsDIY = (pro, diy) => {

  const pricePro = numberWithCommas(pro)
  const priceDiy = numberWithCommas(diy)

  console.log('pricePro', pricePro)
  console.log('priceDiy', priceDiy)

  if(pro["x_measurements.quantityMeasure"].toLowerCase() === "c62"){
    // console.log(pricePro.listPrice, parseFloat(pricePro.listPrice))
    console.log(pricePro.offerPrice, parseFloat(pricePro.offerPrice))
    console.log(priceDiy.offerPrice, parseFloat(priceDiy.offerPrice))

    if(parseFloat(pricePro.offerPrice) <= parseFloat(priceDiy.offerPrice)){
      console.log('preciopro')
      return pro.price
    }else{
      console.log('preciodiy')
      return diy.price
    }
  }


}

export const handleCheckPromoNLP = async (productPRO, url) => {

  const productDIY: any = await handleGetProductDIY(productPRO, url)

  console.log("productPRO ", productPRO);
  console.log("contentsFFF ", productDIY);

  if(productDIY){
    return validatePricePROvsDIY(productPRO, productDIY)
    // console.log("price", price)
  }

}

const handleGetProductDIY = async (productPRO, url) => {

  try {
    const productDIY = await axios.get<any>(url)
    .then((response) => response.data)
    .then((response) => {
      const { contents } = response;
      if (contents && contents?.length > 0) {
        return contents.find(prod => prod.partNumber === productPRO?.partNumber && prod.type === "item")
      }
    });
    if(productDIY){
      productDIY.price = [
        {
          "description": "L",
          "currency": "MXN",
          "value": "759",
          "usage": "Display"
        },
        {
          "description": "I",
          "currency": "MXN",
          "field1": {
            "LP": "759"
          },
          "value": "530.10",
          "usage": "Offer"
        }
      ]
      // productDIY.price = [
      //   {
      //     "description": "I",
      //     "currency": "MXN",
      //     "field1": {},
      //     "value": "55.0",
      //     "usage": "Offer"
      //   },
      //   {
      //     "description": "L",
      //     "currency": "MXN",
      //     "value": "52.0",
      //     "usage": "Display"
      //   }
      // ]

      return productDIY
    }
  } catch (error) {
    console.log("error", error)
  }

  return null
}

export const getBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("Chrome") !== -1) {
    return CHROME;
  } else if (userAgent.indexOf("Safari") !== -1) {
    return SAFARI;
  } else if (userAgent.indexOf("Firefox") !== -1) {
    return FIREFOX;
  } else if (userAgent.indexOf("Edge") !== -1) {
    return EDGE;
  } else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
    return MSIE;
  } else {
    return "Unknown or Unsupported Browser";
  }
}


export function formatDate(inputDateString) {
  const inputDate = parseISO(inputDateString);
  const formattedDate = format(inputDate, "dd LLL yyyy", { locale: es}); //E.g. 10 dic 2023
  return formattedDate;
}

export async function organizationAddress (url) {
  let data;
  await axios.get(url)
    .then((response) => {
      data = response.data
    })
    .catch((error) => {
      console.log('errorOrg', '--', error);
    });
  return data;
}


export const calculateDiyProPrice = (product, proPrice) => {

  const DIYpriceData = product.price

  const DIYdisplayPrice = DIYpriceData.find(price => price.usage === "Display")
  const DIYofferPrice = DIYpriceData.find(price => price.usage === "Offer")
  

  const price = parseFloat(DIYofferPrice.value)

  if(parseFloat(DIYofferPrice.value) > proPrice){

    DIYofferPrice.value = proPrice.toString()
    
    return [
      DIYdisplayPrice,
      DIYofferPrice
    ]
    
  }else {
    
    return DIYpriceData
    
  }


}

export function formatTelephoneInputB2B(input: string) {
  const numericValue = input.replace(/\D/g, '');

  const formattedValue = numericValue.replace(/(\d{3})(\d{3})/g, '$1 $2 ');

  return formattedValue.trim();
}

export function logEvent(name) {
  if (!name) {
    return;
  }
  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(name);
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: "logEvent",
      name: name,
    };
    window.webkit.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    console.log("No native APIs found.");
  }
}

export function logEventWithValues(name, value) {
  if (!name || !value) {
    return;
  }
  const json = JSON.stringify(value);
  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(name, json);
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: "logEvent",
      name: name,
      value: json,
    };
    window.webkit.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    console.log("No native APIs found.");
  }
}
import { Divider } from "@material-ui/core";
import { useEffect } from "react";
//Standard libraries
import { useTranslation } from "react-i18next";
import { StyledGrid, StyledTypography } from "../../../../elements";
import FormattedPriceDisplay from "../../../../widgets/formatted-price-display";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../../../../redux/selectors/user";

const Paypal = ({ ...props }) => {
  const { t } = useTranslation();
  const orderDataTYP = props.orderDataTYP;
  const orderData = props.orderData;
  const storeConfData = props.storeConfData;
  const userLoggedIn = useSelector(loginStatusSelector);

  const paymentInstruction = orderData?.paymentInstruction;
  let installments = "";
  let authorizationCode = "";
  let transDate = "";
  let installmentsDisplay = "";
  const guestMSI = orderDataTYP && orderDataTYP?.[0]?.xitem_field2 && orderDataTYP?.[0]?.xitem_field2.split("|")[0];

  if (paymentInstruction.length > 0) {
    const protocolData = paymentInstruction[0].protocolData;
    if (protocolData.length > 0) {
      // installments = userLoggedIn ? protocolData.filter((data) => data.name === "installments")[0]?.value : guestMSI;
      installments = protocolData.filter((data) => data.name === "installments")[0]?.value;
      authorizationCode = protocolData.filter((data) => data.name === "authorizationCode")[0]?.value;
      transDate = orderData?.paymentInstruction?.[0]?.protocolData.filter(elem => elem.name === "transactionDate")[0]?.value;
      transDate = transDate
        ? new Date(transDate).toLocaleString("es-GB", {
            timeZone: "UTC",
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
            hourCycle: "h24",
          }).replace(',', '') + " hrs."
        : "";
      /*paymentAmount = protocolData.filter((data) => data.name === "paymentAmount")[0]?.value;
      name = protocolData.filter((data) => data.name === "name")[0]?.value;
      description = protocolData.filter((data) => data.name === "description")[0]?.value;*/
      if(installments === "1"){
        installmentsDisplay = t("OrderConfirmation.PaymentMethod.Paypal.SingleExhibition");
      }else{
        installmentsDisplay = installments + " " + t("OrderConfirmation.PaymentMethod.Paypal.installmentPay");
      }
    }
  }
  return (
    <>
      <StyledGrid container>
        <StyledGrid xs={12}>
          <StyledTypography variant="headingFeatured" className={"orderConfirmation--styledTypography-upperCase"}>
            {t("OrderConfirmation.PaymentMethod.Paypal.Confirmation.Name")}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item xs={12} className={"top-margin-2"}>
          <StyledTypography
            variant="bodyBaseline"
            className={"orderConfirmation--StyledTypography-darkGray"}
            component={"span"}>
            {t("OrderConfirmation.PaymentMethod.Paypal.Confirmation.installments")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" " + installmentsDisplay}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item xs={12} className={"top-margin-2"}>
          <StyledTypography
            variant="bodyBaseline"
            className={"orderConfirmation--StyledTypography-darkGray"}
            component={"span"}>
            {t("OrderConfirmation.PaymentMethod.Paypal.Confirmation.authNumber")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" " + authorizationCode}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item xs={12} className={"top-margin-2"}>
          <StyledTypography
            variant="bodyBaseline"
            className={"orderConfirmation--StyledTypography-darkGray"}
            component={"span"}>
            {t("OrderConfirmation.PaymentMethod.Paypal.Confirmation.transDate")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" " + transDate}
          </StyledTypography>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export { Paypal };

import { Divider } from "@material-ui/core";
import { useEffect } from "react";
//Standard libraries
import { useTranslation } from "react-i18next";
import { StyledGrid, StyledTypography } from "../../../../elements";
import FormattedPriceDisplay from "../../../../widgets/formatted-price-display";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { useSite } from "../../../../../_foundation/hooks/useSite";

const Cod = ({ ...props }) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const orderDataTYP = props.orderDataTYP;
  const orderData = props.orderData;
  const storeConfData = props.storeConfData;
  const isCreditAllowed = isB2B && !!orderData?.paymentInstruction?.[0]?.protocolData?.find(
    (data) => data?.name == "isCreditAllowed" && data?.value == "true"
  );

  return (
    <>
      <StyledGrid container>
        <StyledGrid xs={12}>
          <StyledTypography variant="headingFeatured" className={"orderConfirmation--styledTypography-upperCase"}>
            {isCreditAllowed ? t("OrderConfirmation.PaymentMethod.CreditAr.Title") : t("OrderConfirmation.PaymentMethod.Cod.Name")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" component="span">
            {isCreditAllowed ? t("OrderConfirmation.PaymentMethod.CreditAr.Description") : t("OrderConfirmation.PaymentMethod.Cod.DetailConfirmation")}
          </StyledTypography>
          {
            !isCreditAllowed && (
              <StyledTypography variant="bodyBaseline" weight="semibold" component="span">
                {storeConfData?.PayInStoreExpiryHrs + " " + t("OrderConfirmation.hrs")}
              </StyledTypography>
            )
          }
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export { Cod };

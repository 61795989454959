import { Divider } from "@material-ui/core"
import { StyledBox, StyledTypography } from "../../../hdm/elements"
import { palette } from "../../../hdm/themes"

interface Props {
    title: string
    alinear?: string
}
const TitleCard = (props) => {

    const { title, alinear } = props
    return(
        <>
        <StyledTypography variant="textSegment" align={alinear ? alinear : "center"} style={{color: palette.gray["500"]}}>
            {title}
        </StyledTypography>
        
        <Divider className="vertical-margin-2"/>
        </>
    )
}

export default TitleCard
import axios from "axios";
import { getCookieValue, setCookieCAPI } from "../cookieHandler";
import { triggerConversionTikTokAddToCart } from "../tiktok/tiktok-conversion-utils";

export const createExternalIdValue = () => {

  try{
    const currentDate = new Date().getTime();
    const value = localStorage.getItem("external_id");

    const randNum = Math.floor(Math.random() * 50000) + 1;
    const unixTime = Math.floor(Date.now() / 1000);
    const external_id = randNum.toString() + unixTime.toString();
    if(!value){
      const newRecord = {id: external_id, currentDate: currentDate};
      localStorage.setItem("external_id", JSON.stringify(newRecord));
      
    }else{

      // Si hay un registro almacenado, verifica la diferencia de tiempo
      const parsed = JSON.parse(value);
      const registerDate = parsed.fechaActual;
      const differenceTime = currentDate - registerDate;
      const minutes = differenceTime / (1000 * 60); // Convertir de milisegundos a minutos
      if (minutes >= 30) {
          // Si han pasado más de 30 minutos, guarda un nuevo registro con el nuevo valor y la fecha actual
          const newRecord = { valor: external_id, fechaActual: currentDate };
          localStorage.setItem('registro', JSON.stringify(newRecord));
      }

    }
  }catch(e){
    console.log("error generating external_id value");
  }
  
}

export const triggerConversionPinterestPageVisit = async (storeId, mfbt, epik, userAgent, eventSource,
    sku, name, marketId, price, brand, category) => {

    const externalId = getExternalId();

    const payload = {
      mfbt: mfbt,
      epik:epik,
      userAgent:userAgent,
      eventSource:eventSource,
      sku: sku,
      name: name,
      marketId: marketId,
      price: price,
      brand: brand, 
      category: category,
      externalId:externalId
    }
    await axios
        .post(`/wcs/resources/store/${storeId}/hdm/convertions/pagevisit?responseFormat=json`,payload)
        .then((res) => {
            if (res && res?.data) {
                console.log("Page visit is working as expected ");
            }
            console.log("Pinterest PV Response value:  " + res);
        })
        .catch((error) => {
            console.log("Error pageview: " + error);
        });
}


export const triggerConversionsPinterestViewCategory = async (category,isB2B , isPLP) =>{

    const mfbt = getCookieValue("_mfbt");
    const epik = getCookieValue("_epik");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const storeId = isB2B != true? "10351" : "11401";
    let cat =  "";
    if(!isPLP){
        cat = category.category[1];
        console.log("TEST ctgs:  "+cat);
    }else{
        cat = category.map(cat => cat.label).join(" > ");
        console.log("TEST ctgs:  "+cat);
    }
    
    const marketId = getCookieValue(`WC_MarketId_${storeId}`);
    const externalId = getExternalId();
    await axios
    .get(
        `/wcs/resources/store/${storeId}/hdm/convertions/viewcategory?responseFormat=json&mfbt=${mfbt}&epik=${epik}&userAgent=${userAgent}&eventSource=${eventSource}&marketId=${marketId}&category=${cat}&externalId=${externalId}`
    )
    .then((res) => {
        if (res && res?.data) {
            console.log("view category is working as expected ");
        }
        console.log("Pinterest VC Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error pageview: " + error);
    });
}

export const triggerConversionsPinterestAddToCart = async (
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
    itemQty,
    currentMarketId,
    isB2B
  ) => {
    
      retrieveProductsDetails(
        storeId,
        physicalStoreId,
        contractId,
        partNumber,
        itemQty
      ) 
        .then(async (itmsList) => {
          const products: any = [];
          let priceVal = 0;
          itmsList.map(function (element, index) {
            if (element?.price) {
              priceVal = priceVal + parseFloat(element?.price);
            }
    
            const productObj = {
              item_name: element?.item_name,
              item_id: element?.item_id,
              price: element?.price,
              item_brand: validateAttribute(element?.item_brand),
              item_category: validateAttribute(element?.item_category),
              item_category2: validateAttribute(element?.item_category2),
              item_category3: validateAttribute(element?.item_category3),
              quantity: element?.quantity,
              discount: element?.discount,
            };
            products.push(productObj);
          });
    
          

          const mfbt = getCookieValue("mfbt");
          const epik = getCookieValue("_epik");
          const userAgent = window.navigator.userAgent;
          const eventSource = window.location.href;
          const storeId = isB2B != true? "10351" : "11401";
          const marketId = getCookieValue(`WC_MarketId_${storeId}`);
          const externalId = getExternalId();
          const payload = {
            mfbt: mfbt,
            epik:epik,
            userAgent:userAgent,
            eventSource:eventSource,
            externalId:externalId,
            products: products,
          }
          
            console.log(`TEST Pinterest valores de SKUs \n\n ${JSON.stringify(payload)}`);
            await axios
            .post(`/wcs/resources/store/${storeId}/hdm/convertions/addToCart?responseFormat=json`,payload )
            .then((res) => {
                if (res && res?.data) {
                    console.log("view category is working as expected ");
                }
                console.log("Pinterest VC Response value:  " + res);
            })
            .catch((error) => {
                console.log("Error pageview: " + error);
            });
          

          
            try{
              triggerConversionTikTokAddToCart(storeId, mfbt, userAgent, externalId, products );
            }catch(e){
              console.log(`Error TT AtC`);
            }
          
        })
        .catch((error) => {
          console.log(error);
        });
    
  };


const retrieveProductsDetails = async (
    storeId,
    physicalStoreId,
    contractId,
    partNumber,
    itemQty
  ) => {
    const items: any = [];
    let itemName: string = "";
    let itemId: string = "";
    let itemPrice: string = "";
    let itemBrand: string = "";
    let itemQuantity: number = 0;
    let itemCategory: string = "";
    let itemCategory1: string = "";
    let itemCategory2: string = "";
    let catGrpIds: string = "";
    const catGrpIdDetails = new Map();
    let catalogGroupIDs: any;
    let partNumbers: string = "";
    const partNumberCatGrpIds = new Map();
    const productDetails = new Map();
  
    if (Array.isArray(partNumber)) {
      let count: number = 0;
      for (const partNum of partNumber) {
        if (partNum) {
          if (partNumbers === "") {
            partNumbers = "partNumber=" + partNum;
          } else {
            partNumbers = partNumbers + "&partNumber=" + partNum;
          }
          const prodDetails = new Map();
          prodDetails.set("quantity", Number(itemQty[count]));
          productDetails.set(partNum, prodDetails);
        }
        count = count + 1;
      }
    } else {
      partNumbers = "partNumber=" + partNumber;
      const prodDetails = new Map();
      prodDetails.set("quantity", Number(itemQty));
      productDetails.set(partNumber, prodDetails);
    }
  
    try {
      await axios.get<any>(
        `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents) {
          for (const dataResponse of response.data.contents) {
            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }
            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            for (const price of dataResponse.price) {
              if (price.usage === "Offer" && price.value !== "") {
                prodDetails.set("price", price.value);
              }
            }
  
            const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
            const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
            let nlpDiscount: any;
            if (
              offerPrice?.value &&
              displayPrice?.value &&
              parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
            ) {
              nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
            }
            prodDetails.set("discount", nlpDiscount);
            
            if (dataResponse && dataResponse.parentCatalogGroupID) {
              if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
                catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
                if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                  catalogGroupIDs = catalogGroupIDs[0].split("/");
                } else {
                  catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
                }
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
              }
              if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
                const catGrpIdsList: string[] = [];
                for (const catalogGroupID of catalogGroupIDs) {
                  if (catalogGroupID) {
                    if (catGrpIds === "") {
                      catGrpIds = "id=" + catalogGroupID;
                    } else {
                      catGrpIds = catGrpIds + "&id=" + catalogGroupID;
                    }
                    catGrpIdsList.push(catalogGroupID);
                  }
                }
                partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
              }
            }
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      });
      if (catGrpIds) {
        await axios.get<any>(
          `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
        ).then((response) => {
          if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
            for (const catalogGroupDetails of response.data.contents) {
              if (catalogGroupDetails) {
                catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
              }
            }
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  
    if (Array.isArray(partNumber)) {
      for (const partNum of partNumber) {
        if (partNum) {
          itemName = "";
          itemId = "";
          itemPrice = "";
          itemBrand = "";
          itemQuantity = 0;
          itemCategory = "";
          itemCategory1 = "";
          itemCategory2 = "";
  
          let prodDetails = new Map();
          if (productDetails.get(partNum) != null) {
            prodDetails = productDetails.get(partNum);
            itemName = prodDetails.get("name");
            itemId = partNum;
            itemPrice = prodDetails.get("price");
            itemBrand = prodDetails.get("brand");
            itemQuantity = prodDetails.get("quantity");
          }
          const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
          let count: number = 0;
          if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
            for (const catalogGroupID of prdCatgrpIds) {
              if (catalogGroupID) {
                const catgryName: string =
                  catGrpIdDetails.get(catalogGroupID) === null || catGrpIdDetails.get(catalogGroupID) === undefined
                    ? "NA"
                    : catGrpIdDetails.get(catalogGroupID);
                if (count === 0) {
                  itemCategory = catgryName;
                } else if (count === 1) {
                  itemCategory1 = catgryName;
                } else if (count === 2) {
                  itemCategory2 = catgryName;
                }
                count = count + 1;
              }
            }
          }
          console.log("itemName", itemName);
          console.log("itemId", itemId);
          console.log("itemPrice", itemPrice);
          console.log("itemBrand", itemBrand);
          console.log("itemCategory", itemCategory);
          console.log("itemCategory1", itemCategory1);
          console.log("itemCategory2", itemCategory2);
          console.log("itemQuantity", itemQuantity);
          const item = {
            item_name: itemName === null || itemName === undefined ? "NA" : itemName,
            item_id: itemId,
            price: itemPrice === null || itemPrice === undefined ? "NA" : formatNumberWithTwoDecimals(itemPrice),
            item_brand: itemBrand === null || itemBrand === undefined ? "NA" : itemBrand,
            item_category: validateAttribute(itemCategory),
            item_category2: validateAttribute(itemCategory1),
            item_category3: validateAttribute(itemCategory2),
            quantity: !isNaN(itemQuantity) ? itemQuantity : 1,
            discount:
              prodDetails.get("discount") === null || prodDetails.get("discount") === undefined
                ? 0
                : formatNumberWithTwoDecimals(prodDetails.get("discount")),
            index: 0,
            item_list_id: "NA",
            item_list_name: "NA",
          };
          items.push(item);
        }
      }
    } else {
      let prodDetails = new Map();
      if (productDetails.get(partNumber) != null) {
        prodDetails = productDetails.get(partNumber);
        itemName = prodDetails.get("name");
        itemId = partNumber;
        itemPrice = prodDetails.get("price");
        itemBrand = prodDetails.get("brand") === null || prodDetails.get("brand") === undefined ? "NA" : prodDetails.get("brand") ;
        itemQuantity = prodDetails.get("quantity");
      }
      const prdCatgrpIds = partNumberCatGrpIds.get(partNumber);
      let count: number = 0;
      if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
        for (const catalogGroupID of prdCatgrpIds) {
          if (catalogGroupID) {
            const catgryName: string =
              catGrpIdDetails.get(catalogGroupID) === null || catGrpIdDetails.get(catalogGroupID) === undefined
                ? "NA"
                : catGrpIdDetails.get(catalogGroupID);
            if (count === 0) {
              itemCategory = catgryName;
            } else if (count === 1) {
              itemCategory1 = catgryName;
            } else if (count === 2) {
              itemCategory2 = catgryName;
            }
            count = count + 1;
          }
        }
      }
      const item = {
        item_name: itemName === null || itemName === undefined ? "NA" : validateAttribute(itemName),
        item_id: itemId,
        price: itemPrice === null || itemPrice === undefined ? "NA" : formatNumberWithTwoDecimals(itemPrice),
        item_brand: itemBrand === null || itemBrand === undefined ? "NA" : validateAttribute(itemBrand),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        quantity: itemQuantity,
        discount:
          prodDetails.get("discount") === null || prodDetails.get("discount") === undefined
            ? 0
            : formatNumberWithTwoDecimals(prodDetails.get("discount")),
      };
      items.push(item);
    }
    return items;
  };

function formatNumberWithTwoDecimals(value) {
    return parseFloat(Number(value).toFixed(2));
}

const validateAttribute = (attribute) => {
    if (attribute && attribute != "") {
        return attribute.replace(/["':,]/g,'');
    } else {
        return "NA";
    }
};

export const triggerPinterestCheckout = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  currentMarketId,
  isB2B,
  emailId
) => {
  console.log(`TEST before all the details about purchase`);
  const [orderTotal, totalShippingCharge, itmsList, totalTax] = await retrieveProductsDetailsAddPaymentPurchaseEvent(
    storeId,
    physicalStoreId,
    contractId,
    orderId,
    true,
    currentMarketId,
    isB2B
  );

    const mfbt = getCookieValue("_mfbt");
    const epik = getCookieValue("_epik");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const store = isB2B != true? "10351" : "11401";
    const marketId = getCookieValue(`WC_MarketId_${storeId}`);
    const externalId = getExternalId();
    
    const payload = {
      mfbt: mfbt,
      epik:epik,
      userAgent:userAgent,
      eventSource:eventSource,
      orderId: orderId,
      orderTotal: orderTotal,
      products: itmsList,
      email: emailId,
      externalId: externalId,
    }
    
    console.log(`TEST Pinterest valores de request checkoutP \n\n ${JSON.stringify(payload)}`);
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/convertions/checkoutP?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("checkout is working as expected ");
        }
        console.log("Pinterest check Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error pageview: " + error);
    });
};

export const retrieveProductsDetailsAddPaymentPurchaseEvent = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId,
  isPurchaseEvent,
  currentMarketId,
  isB2B
) => {
  const products: any = [];
  const partNumberList: string[] = [];
  const catGrpList: string[] = [];
  let partNumbers: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();
  let orderTotal: any = "";
  let totalShippingCharge: any = "";
  let totalTax: any = "";


  try {
    await axios.get<any>(`/wcs/resources/store/${storeId}/order/${orderId}`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        orderTotal = formatNumberWithTwoDecimals(response.data.grandTotal);
        if (isPurchaseEvent) {
          totalShippingCharge = formatNumberWithTwoDecimals(
            parseFloat(response.data.totalShippingCharge) + parseFloat(response.data.totalShippingTax)
          );
          totalTax = formatNumberWithTwoDecimals(
            parseFloat(response.data.totalSalesTax) + parseFloat(response.data.totalShippingTax)
          );
        }
        let oiIndex: number = 0;
        for (const orderItem of response.data.orderItem) {
          oiIndex = oiIndex + 1;

          if (orderItem && orderItem.partNumber) {
            partNumberList.push(orderItem.partNumber);
            let unitPrice: any;
            if (orderItem.xitem_taxRate) {
              unitPrice =
                parseFloat(orderItem.unitPrice) +
                parseFloat(orderItem.unitPrice) * (parseFloat(orderItem.xitem_taxRate) / 100);
            } else {
              unitPrice = parseFloat(orderItem.unitPrice);
            }

            const prodDetails = new Map();

           
            prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice));
            prodDetails.set("quantity", Number(orderItem.quantity));

            prodDetails.set("index", oiIndex);
            prodDetails.set("item_list_id", "NA");
            prodDetails.set("item_list_name", "NA");
            prodDetails.set("item_variant", "NA");

            productDetails.set(orderItem.partNumber, prodDetails);
          }
        }
      }
      if (partNumberList && partNumberList.length >= 1) {
        for (const partNum of partNumberList) {
          if (partNum) {
            if (partNumbers === "") {
              partNumbers = "partNumber=" + partNum;
            } else {
              partNumbers = partNumbers + "&partNumber=" + partNum;
            }
          }
        }
      }
    });

    await axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          
          
          if (dataResponse && dataResponse.parentCatalogGroupID) {
            let catalogGroupIDs: any;
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }

            const catGrpIdsList: string[] = [];
            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }

            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  catGrpList.push(catalogGroupID);
                  catGrpIdsList.push(catalogGroupID);
                }
              }
            }
            partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      }
    });
    if (catGrpList) {
      if (catGrpList && catGrpList.length >= 1) {
        for (const catalogGroupID of catGrpList) {
          if (catalogGroupID) {
            if (catGrpIds === "") {
              catGrpIds = "id=" + catalogGroupID;
            } else {
              catGrpIds = catGrpIds + "&id=" + catalogGroupID;
            }
          }
        }
      }
      await axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }

    for (const partNum of partNumberList) {
      const prdDtls = productDetails.get(partNum);
      const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
      let itemCategory: string = "";
      let itemCategory1: string = "";
      let itemCategory2: string = "";
      let count: number = 0;
      for (const catalogGroupId of prdCatgrpIds) {
        if (catalogGroupId) {
          const catgry: string =
            catGrpIdDetails.get(catalogGroupId) === null || catGrpIdDetails.get(catalogGroupId) === undefined
              ? "NA"
              : catGrpIdDetails.get(catalogGroupId);
          if (count === 0) {
            itemCategory = catgry;
          } else if (count === 1) {
            itemCategory1 = catgry;
          } else if (count === 2) {
            itemCategory2 = catgry;
          }
          count = count + 1;
        }
      }
      const price = prdDtls.get("price") ;
        
      
      const productObj = {
        item_name: prdDtls.get("name") === null || prdDtls.get("name") === undefined ? "NA" : validateAttribute(prdDtls.get("name")),
        item_id: partNum,
        price: prdDtls.get("price") === null || prdDtls.get("price") === undefined ? "NA" 
          : price ,
        item_brand: prdDtls.get("brand") === null || prdDtls.get("brand") === undefined ? "NA" : validateAttribute(prdDtls.get("brand")),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        quantity:
          prdDtls.get("quantity") === null || prdDtls.get("quantity") === undefined ? "NA"
            : prdDtls.get("quantity"),
        
      };
      products.push(productObj);
    }
  } catch (error) {
    console.log("error", error);
  }
  return [orderTotal, totalShippingCharge, products, totalTax];
};


export const getExternalId = () =>{
  const value = localStorage.getItem("external_id");
  if(value){
    const parsed = JSON.parse(value);
    return parsed.id;
  }
  return "";
}


export const triggerReOrderConvertionsEvent = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId
) => {
  const [orderTotal, itmsList] = await retrieveOrderProductsDetails(
    storeId,
    physicalStoreId,
    contractId,
    orderId
  );
    
    const products: any = [];
    let priceVal = 0;
    itmsList.map(function (element, index) {
      if (element?.price) {
        priceVal = priceVal + parseFloat(element?.price);
      }
      const productObj = {
        item_name: element?.item_name,
        item_id: element?.item_id,
        price: element?.price,
        item_brand: validateAttribute(element?.item_brand),
        item_category: validateAttribute(element?.item_category),
        item_category2: validateAttribute(element?.item_category2),
        item_category3: validateAttribute(element?.item_category3),
        quantity: element?.quantity,
        discount: 0,
      };
      products.push(productObj);
    });

    

    const mfbt = setCookieCAPI("mfbt");
    const epik = getCookieValue("_epik");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const marketId = getCookieValue(`WC_MarketId_${storeId}`);
    const externalId = getExternalId();
    const payload = {
      mfbt: mfbt,
      epik:epik,
      userAgent:userAgent,
      eventSource:eventSource,
      externalId:externalId,
      products: products,
    }
    
    console.log(`TEST Pinterest valores de SKUs \n\n ${JSON.stringify(payload)}`);
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/convertions/addToCart?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("view category is working as expected ");
        }
        console.log("Pinterest VC Response value:  " + res);
    })
    .catch((error) => {
        console.log("Error pageview: " + error);
    });
  

  
    try{
      triggerConversionTikTokAddToCart(storeId, mfbt, userAgent, externalId, products );
    }catch(e){
      console.log(`Error TT AtC`);
    }
    
};


export const retrieveOrderProductsDetails = async (
  storeId,
  physicalStoreId,
  contractId,
  orderId
) => {
  const products: any = [];
  const partNumberList: string[] = [];
  const catGrpList: string[] = [];
  let partNumbers: string = "";
  let catGrpIds: string = "";
  const catGrpIdDetails = new Map();
  const partNumberCatGrpIds = new Map();
  const productDetails = new Map();
  let orderTotal: any = "";
  try {
    await axios.get<any>(`/wcs/resources/store/${storeId}/order/${orderId}`).then((response) => {
      if (response && response.status === 200 && response?.data?.orderItem) {
        orderTotal = formatNumberWithTwoDecimals(response.data.grandTotal);
        let oiIndex: number = 0;
        for (const orderItem of response.data.orderItem) {
          oiIndex = oiIndex + 1;
          const hasNxMPromo = false;

          if (orderItem && orderItem.partNumber) {
            partNumberList.push(orderItem.partNumber);
            let unitPrice: any;
            if (orderItem.xitem_taxRate) {
              if (hasNxMPromo) {
                unitPrice =
                  parseFloat(orderItem.unitPrice) +
                  parseFloat(orderItem.unitPrice) * (parseFloat(orderItem.xitem_taxRate) / 100);
              } else {
                unitPrice =
                  parseFloat(orderItem.orderItemPrice) +
                  parseFloat(orderItem.orderItemPrice) * (parseFloat(orderItem.xitem_taxRate) / 100) +
                  (parseFloat(orderItem.totalAdjustment.value) +
                    parseFloat(orderItem.totalAdjustment.value) * (parseFloat(orderItem.xitem_taxRate) / 100));
              }
            } else {
              if (hasNxMPromo) {
                unitPrice = parseFloat(orderItem.unitPrice);
              } else {
                unitPrice = parseFloat(orderItem.orderItemPrice) + parseFloat(orderItem.totalAdjustment.value);
              }
            }

            const prodDetails = new Map();
            if (hasNxMPromo) {
              prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice));
            } else {
              prodDetails.set("price", formatNumberWithTwoDecimals(unitPrice / Number(orderItem.quantity)));
            }

            prodDetails.set("quantity", Number(orderItem.quantity));

            prodDetails.set("index", oiIndex);
            productDetails.set(orderItem.partNumber, prodDetails);
          }
        }
      }
      if (partNumberList && partNumberList.length >= 1) {
        for (const partNum of partNumberList) {
          if (partNum) {
            if (partNumbers === "") {
              partNumbers = "partNumber=" + partNum;
            } else {
              partNumbers = partNumbers + "&partNumber=" + partNum;
            }
          }
        }
      }
    });

    await axios.get<any>(
      `/search/resources/api/v2/products?storeId=${storeId}&physicalStoreId=${physicalStoreId}&contractId=${contractId}&langId=-5&${partNumbers}`
    ).then((response) => {
      if (response && response.status === 200 && response?.data?.contents) {
        for (const dataResponse of response.data.contents) {
          const offerPrice = dataResponse.price.filter((precio) => precio.usage === "Offer")[0];
          const displayPrice = dataResponse.price.filter((precio) => precio.usage === "Display")[0];
          let nlpDiscount: any;
          if (
            offerPrice?.value &&
            displayPrice?.value &&
            parseFloat(displayPrice.value) > parseFloat(offerPrice.value)
          ) {
            nlpDiscount = parseFloat(displayPrice.value) - parseFloat(offerPrice.value);
          }

          if (dataResponse && dataResponse.parentCatalogGroupID) {
            let catalogGroupIDs: any;
            if (Array.isArray(dataResponse.parentCatalogGroupID) && dataResponse.parentCatalogGroupID[0]) {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.filter((item) => item.split("/").length === 4);
              if (catalogGroupIDs && catalogGroupIDs.length > 0) {
                catalogGroupIDs = catalogGroupIDs[0].split("/");
              } else {
                catalogGroupIDs = dataResponse.parentCatalogGroupID[0].split("/");
              }
            } else {
              catalogGroupIDs = dataResponse.parentCatalogGroupID.split("/");
            }

            const catGrpIdsList: string[] = [];
            let prodDetails = new Map();
            if (productDetails.get(dataResponse.partNumber) != null) {
              prodDetails = productDetails.get(dataResponse.partNumber);
            }

            prodDetails.set("name", dataResponse.name);
            prodDetails.set("brand", dataResponse.manufacturer);
            if (catalogGroupIDs && catalogGroupIDs.length >= 1) {
              for (const catalogGroupID of catalogGroupIDs) {
                if (catalogGroupID) {
                  catGrpList.push(catalogGroupID);
                  catGrpIdsList.push(catalogGroupID);
                }
              }
            }
            partNumberCatGrpIds.set(dataResponse.partNumber, catGrpIdsList);
            productDetails.set(dataResponse.partNumber, prodDetails);
          }
        }
      }
    });
    if (catGrpList) {
      if (catGrpList && catGrpList.length >= 1) {
        for (const catalogGroupID of catGrpList) {
          if (catalogGroupID) {
            if (catGrpIds === "") {
              catGrpIds = "id=" + catalogGroupID;
            } else {
              catGrpIds = catGrpIds + "&id=" + catalogGroupID;
            }
          }
        }
      }
      await axios.get<any>(
        `/search/resources/api/v2/categories?storeId=${storeId}&contractId=${contractId}&langId=-5&${catGrpIds}`
      ).then((response) => {
        if (response && response.status === 200 && response?.data?.contents && response.data.contents.length > 0) {
          for (const catalogGroupDetails of response.data.contents) {
            if (catalogGroupDetails) {
              catGrpIdDetails.set(catalogGroupDetails.uniqueID, catalogGroupDetails.name);
            }
          }
        }
      });
    }

    for (const partNum of partNumberList) {
      const prdDtls = productDetails.get(partNum);
      const prdCatgrpIds = partNumberCatGrpIds.get(partNum);
      let itemCategory: string = "";
      let itemCategory1: string = "";
      let itemCategory2: string = "";
      let count: number = 0;
      if (prdCatgrpIds && prdCatgrpIds.length >= 1) {
        for (const catalogGroupId of prdCatgrpIds) {
          if (catalogGroupId) {
            const catgry: string =
              catGrpIdDetails.get(catalogGroupId) === null || catGrpIdDetails.get(catalogGroupId) === undefined
                ? "NA"
                : catGrpIdDetails.get(catalogGroupId);
            if (count === 0) {
              itemCategory = catgry;
            } else if (count === 1) {
              itemCategory1 = catgry;
            } else if (count === 2) {
              itemCategory2 = catgry;
            }
            count = count + 1;
          }
        }
      }
      const productObj = {
        item_name: prdDtls.get("name") === null || prdDtls.get("name") === undefined ? "NA" : prdDtls.get("name"),
        item_id: partNum,
        price: prdDtls.get("price") === null || prdDtls.get("price") === undefined ? "NA" : prdDtls.get("price"),
        item_brand: prdDtls.get("brand") === null || prdDtls.get("brand") === undefined ? "NA" : prdDtls.get("brand"),
        item_category: validateAttribute(itemCategory),
        item_category2: validateAttribute(itemCategory1),
        item_category3: validateAttribute(itemCategory2),
        quantity:
          prdDtls.get("quantity") === null || prdDtls.get("quantity") === undefined ? "NA" : prdDtls.get("quantity"),
        index: prdDtls.get("index") === null || prdDtls.get("index") === undefined ? 0 : prdDtls.get("index"),
      };
      products.push(productObj);
    }
  } catch (error) {
    console.log("error", error);
  }
  return [orderTotal, products];
};
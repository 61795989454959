import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Navigation, Pagination, Manipulation } from "swiper";
import SwiperClass from "swiper";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/zoom/zoom.min.css";
import { StyledChip, StyledTypography, StyledBox, StyledCardMediaHipoteca } from "../../../hdm";

// Icons
import TouchAppIcon from "@material-ui/icons/TouchApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ReactComponent as Swipe } from "../../../assets/homedepot/images/swipe.svg";
import VisualSupport from "../styled-visual-support/VisualSupport";
import { CustomDiv } from "./CarouselHDMDesktopStyles";
import useScript from "./useScript";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { SLPDPProductImageCardIsTablet } from "../skeleton-loader/components/elements/PDP";


interface ICarouselHDMProps {
  fullImage: string;
  carouselImages?: string[] | string;
  showChips?: boolean;
  onClick?: () => void;
  isVisualSupportEnabled: string;
  ArApiKey: string;
  SKU: string;
  promociones: any,
  urlRibbons: any,
  isArEnabled: boolean;
}

const StyledSwiper = styled((props: any) => <Swiper {...props} />)`
  ${({ theme }) => `

    .swiper-wrapper{
        // padding-bottom: ${theme.spacing(6)}px;
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
        position: relative;
        padding-top: ${theme.spacing(1)}px;
        background-color: #fff;
    }

    .swiper-pagination-bullet-active{
        background-color: ${theme.palette.primary.main} !important;
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 5px;
    }

    `}
`;

const CarouselChip = styled((props: any) => <StyledChip {...props} />)`
  ${({ theme }) => `

    &.chip-hidden{
        display: none;
    }

    & p{
      font-weight: 400 !important;
    }

    &.carrousel-chip-total-images{
        z-index: 2;
        position: absolute;
        right: ${theme.spacing(4)}px;
        background-color: ${theme.palette.gray["200"]}60;
        color: ${theme.palette.primary.contrastText};

        & .chip-close-icon{
            display: flex;
        }
    }

    &.carrousel-chip-touchtozoom{
        z-index: 2;
        position: absolute;
        background-color: ${theme.palette.gray["200"]}60;
        color: ${theme.palette.primary.contrastText};
        bottom: ${theme.spacing(7)}px;
        transform: translate(55%, -60%);

        & svg{
            font-size: 25px;
        }

    }

    &.carrousel-chip-touchtozoom-ar{
        z-index: 2;
        position: absolute;
        background-color: ${theme.palette.gray["200"]}60;
        color: ${theme.palette.primary.contrastText};
        bottom: ${theme.spacing(7)}px;
        transform: translate(55%, -200%);

        & svg{
            font-size: 25px;
        }
    }

    `}
`;

const CarouselHDM: FC<ICarouselHDMProps> = ({
  onClick,
  fullImage,
  showChips = true,
  carouselImages = [],
  isVisualSupportEnabled,
  ArApiKey,
  SKU,
  promociones,
  urlRibbons,
  isArEnabled,
}) => {
  /* States */
  const [isZoomed, setIsZoomed] = useState(false);
  const [bottomChipVisible, setBottomChipVisible] = useState(true);
  const [activeIndex, setIsActiveIndex] = useState(1);
  const [attachedImages, setattachedImages] = useState<string[] | string>([]);

  /* Zoom current state */
  const handleSetZoom = () => {
    setIsZoomed(!isZoomed ? true : false);
  };

  // const carouselImageList = [
  //     productFullImage,
  //     "https://swiperjs.com/demos/images/nature-2.jpg",
  //     "https://swiperjs.com/demos/images/nature-3.jpg",
  //     "https://swiperjs.com/demos/images/nature-4.jpg",
  //     "https://swiperjs.com/demos/images/nature-51.jpg"
  // ]

  const removeErrorImage = (errorImage: any): any => {
    // swiperRef?.current?.removeSlide(slideIndex - 1)
    errorImage?.remove();
    swiperRef?.current?.updateSlides();
    delete result[errorImage];
  };

  /*--- Swipper zoom configurations ---*/
  const swiperRef = useRef<SwiperClass>();
  const handleZoom = () => {
    if (!isZoomed) {
      // swiperRef?.current?.zoom.enable();
      swiperRef?.current?.zoom.in();
    } else if (isZoomed) {
      swiperRef?.current?.zoom.out();
    }
  };

  /* chip number tracking data */
  const onSlideChangeHandler = () => {
    const index = swiperRef?.current?.activeIndex ? swiperRef?.current?.activeIndex : 0;
    setIsActiveIndex(index + 1);
    swiperRef?.current?.zoom.out();
  };

  /* Used to hide bottom chip when image is zoomed and user is panning */
  const zoomedRef = useRef<any>(null);
  useEffect(() => {
    const handleStart = (e) => {
      setBottomChipVisible(true);
    };
    const handleEnd = (e) => {
      setBottomChipVisible(false);
    };
    const zoomIsActive = isZoomed;

    if (zoomIsActive) {
      window.addEventListener("touchstart", handleStart);
      window.addEventListener("touchend", handleEnd);
    }
    if (!zoomIsActive) {
      window.removeEventListener("touchstart", handleStart);
      window.removeEventListener("touchend", handleEnd);
    }

    return () => {
      window.removeEventListener("touchstart", handleStart);
      window.removeEventListener("touchend", handleEnd);
    };
  }, [bottomChipVisible, isZoomed]);

  /*-- Carousel images functions --*/
  const storeConfData = useSelector(onlineStoreRdc);
  const baseUrlImagesHDM = storeConfData?.IMAGE_SERVER_HOSTNAME;

  /* Carousel images */
  const productFullImage = baseUrlImagesHDM + "productos/" + SKU + "/" + SKU + "-d.jpg";

  // attached images sorted by name
  const zoomImages: string[] = [];
  const associatedImages: string[] = [];
  const terminationDimages: string[] = [];

  // set attached images to the state
  useEffect(() => {
    if (carouselImages.length > 0) {
      setattachedImages(carouselImages);
    }
  }, [carouselImages]);

  // Sort images depending on the nomeclature
  const sortImagesByNom = () => {
    for (const image of attachedImages) {
      const splittedImage = image.split("-")[1].split(".")[0].slice(0, 1);
      if (splittedImage === "z") {
        zoomImages.push(baseUrlImagesHDM + image);
      }
      if (splittedImage === "a") {
        associatedImages.push(baseUrlImagesHDM + image);
      }
      if (splittedImage === "p") {
        terminationDimages.push(baseUrlImagesHDM + image);
      }
    }
  };

  sortImagesByNom();

  // sort images alphabeticaly
  const zoomedProductFullImage = zoomImages.sort().shift();
  associatedImages.sort();

  const newtotalImages = swiperRef?.current?.slides.length;

  // Merged images into an object to be used on carousel
  const result = {};
  associatedImages.forEach((element: string, index) => {
    result[element] = zoomImages[index];
  });

  // Call cora script if product has ar enabled
  useScript(isArEnabled ? "https://cora.accenture.com/widget/cora-ar-web/cora-bundle.min.js" : "", "");

  if (!showChips && !zoomedProductFullImage && !zoomImages.length) return <SLPDPProductImageCardIsTablet/>;

  // return <SLPDPProductImageCardIsTablet/>;

  return (
    <>
      {showChips && zoomedProductFullImage && zoomImages.length > 0 && (
        <>
          <CarouselChip
            size="medium"
            className="carrousel-chip-total-images"
            label={
              <StyledTypography variant="bodyBaseline" weigth="regular">
                {isZoomed ? (
                  <HighlightOffIcon
                    className="chip-close-icon"
                    fontSize="small"
                    onClick={() => swiperRef?.current?.zoom.out()}
                  />
                ) : (
                  `${activeIndex}/${newtotalImages ?? '1'}`
                )}
              </StyledTypography>
            }
          />
          <CarouselChip
            size="medium"
            className={
              isZoomed && bottomChipVisible
                ? "chip-hidden"
                : isVisualSupportEnabled === "1" || isVisualSupportEnabled === "3"
                ? "carrousel-chip-touchtozoom-ar"
                : "carrousel-chip-touchtozoom"
            }
            label={
              <StyledBox sx={{ display: "flex", alignItems: "center" }}>
                {isZoomed ? (
                  <>
                    <Swipe />
                    <StyledTypography variant="bodyBaseline">{"Arrastra para ver más"}</StyledTypography>
                  </>
                ) : zoomImages.length > 0 ? (
                  <>
                    <TouchAppIcon />
                    <StyledTypography variant="bodyBaseline" weight="bold">{"Toca para zoom"}</StyledTypography>
                  </>
                ) : null}
              </StyledBox>
            }
          />
        </>
      )}

      {fullImage && (
        <>
          <StyledSwiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            passiveListeners={false}
            // navigation={true}
            pagination={{ clickable: true }}
            zoom={{ maxRatio: 2 }}
            modules={[Zoom, Navigation, Pagination, Manipulation]}
            // className="mySwiper"
            onZoomChange={handleSetZoom}
            onClick={handleZoom}
            onSlideChange={onSlideChangeHandler}>
            <div className="test-boxi" ref={zoomedRef}>
              <SwiperSlide>
                <div className="swiper-zoom-container">
                  {zoomedProductFullImage ? (
                    <img
                      src={!isZoomed ? productFullImage : zoomedProductFullImage}
                      alt={productFullImage}
                      className="lazyload"
                    />
                  ) : (
                    <img src={productFullImage} alt={productFullImage} className="lazyload" />
                  )}
                  {/* <img src={!isZoomed ? productFullImage : zoomedProductFullImage } alt={productFullImage} /> */}
                </div>
                {promociones && promociones.includes("PROMOICON_HV") ? (
                  <StyledCardMediaHipoteca
                    image={urlRibbons["PROMOICON_HV"]}
                    title={"hipoteca verde"}
                    className={"promo_plp-ribbon-hv-pdp"}
                    component="div"
                  />
                ) : null}
              </SwiperSlide>
            </div>

            <div className="test-boxi" ref={zoomedRef}>
              {Object.entries(result).map((image: any, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-zoom-container">
                    {/* <img src={image[0]} alt={image[0]} onError={()=> removeErrorImage(index) } /> */}
                    {/* <img src={image[1]} alt={image[0]} onError={()=> removeErrorImage(swiperRef?.current?.slides.length) } /> */}
                    <img
                      src={!isZoomed ? image[0] : image[1]}
                      alt={image[0]}
                      style={{ display: "block" }}
                      className="lazyload"
                      // onError={()=> removeErrorImage(swiperRef?.current?.slides.length, image[0] ) }
                      // onError={(e:any)=> {e.currentTarget.parentNode?.parentNode.remove(); swiperRef?.current?.updateSlides()} }
                      onError={(e: any) => removeErrorImage(e?.currentTarget?.parentNode?.parentNode?.remove())}
                    />
                  </div>
                  {promociones && promociones.includes("PROMOICON_HV") ? (
                    <StyledCardMediaHipoteca
                      image={urlRibbons["PROMOICON_HV"]}
                      title={"hipoteca verde"}
                      className={"promo_plp-ribbon-hv-pdp"}
                      component="div"
                    />
                  ) : null}
                </SwiperSlide>
              ))}
            </div>
          </StyledSwiper>
          {/* {isVisualSupportEnabled === "1" && ( */}
          {/* <VisualSupport isMobileView ArApiKey={ArApiKey} isArEnabled={isArEnabled}/> */}
          {/* )}  */}
        </>
      )}
      {(isVisualSupportEnabled === "1" || isVisualSupportEnabled === "3") && (
        <>
          <VisualSupport isMobileView ArApiKey={ArApiKey} isArEnabled={isArEnabled} SKU={SKU} />
          <CustomDiv
            id="coverlayer"
            hidecreativedrivelogo=""
            language="es"
            currency="MXN"
            accentcolor="#F96302"
            apitoken="6231e4310d2cdd0061c063cf"
            className="cover-layer-pdp"></CustomDiv>
        </>
      )}
    </>
  );
};

export default CarouselHDM;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { Divider } from "@material-ui/core";
import { palette } from "../../themes/color-palette";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import CheckIcon from "@material-ui/icons/Check";

import styled from "styled-components";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

import {
  StyledSelect,
  StyledMenuItem,
  StyledFormControl,
  StyledInputLabel,
  StyledGrid,
  StyledSwipeableDrawer,
} from "../../elements";
import { AllowScroll, DisableScroll } from "../../functions/utils";
import { useSite } from "../../../_foundation/hooks/useSite";
import { pdpSeoRdc, hdmStoresDataRdc } from "../../../redux/selectors/hdmData";
import { SET_CURRENT_STORE_ACTION } from "../../../redux/actions/current-store";
import jsonStateCityList from "../../../components/custom-components/styled-store-selector/json-stores-info/StoreLocatorStateCitiesList.json";

import {
  ContainerStyledBox,
  ContainerStyledTypography,
  ContainerStyledButton,
  ContainerStyledRadioCardContainer,
  ContainerStyledUncheckedRadioIcon,
  ContainerStyledSuccessRoomIcon,
  ContainerStyledSuccessIconRadio,
  ContainerStylediv,
  StyledSwipeableDrawersDisponibilidad,
} from "./containers-disponibilidad";

import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import BuscarEstadoCiudad from "./BuscarEstadoCiudad";
import { useTranslation } from "react-i18next";
import KitAvailabilityByState from "./KitAvailabilityByState";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { SLPDPSearchOtherStores } from "../../../components/custom-components/skeleton-loader/components/elements/PDP";

const StyledKeyboardArrowDown = styled(({ ...props }) => <KeyboardArrowDown {...props} />)`
  ${({ theme }) => `
  font-size: 24px;
`}
`;

const ContainerStyledGrid = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
  
  &.storeLocator--styledGrid-arrowSelect {
    display: flex;
    width: 340px;
    justify-content: flex-end;
    vertical-align: middle;
  }
`}
`;

const RowIconSpan = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  width: ${theme.spacing(5)}px;
  padding-top: 7px;
  height: 40px;
  display: inline-block;
  text-align: center;
  margin-right: ${theme.spacing(1)}px;
  z-index: -1;
}


`}
`;

interface Props {
  productData: any;
  getCompKitInventoryForAnotherStore?: any;
  kitAvailabilityInfoByStore:Array<any>;
}

const KitAvailabilityByStore = (props: Props) => {
  const {
    productData,
    getCompKitInventoryForAnotherStore,
    kitAvailabilityInfoByStore
  } = props;

  console.log("kitAvailabilityInfoByStore", kitAvailabilityInfoByStore)
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tiendas, setTiendas] = useState(kitAvailabilityInfoByStore);
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const seoRdc = useSelector(pdpSeoRdc);
  const hdmStoresData = useSelector(hdmStoresDataRdc);
  const currentStore = useSelector(currentStoreSelector);
  const navigate = useNavigate();
  const cityList = jsonStateCityList.formScheduleObject;
  const currentStoreId = currentStore?.currentStore?.stLocId;

  const handleSetTiendas = (data: any) => {
    setTiendas(data);
  };


  const handleOpenBuscarOtraTienda = () => {
    //handle market inventory in use-kit component
    setIsOpen(true);
    
  };

  useEffect(()=>{
    setTiendas(kitAvailabilityInfoByStore);
  },[kitAvailabilityInfoByStore])
  
  

  const handleCloseBuscarOtraTienda = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // if(!window.location.pathname.includes("/p/")){
    //   if (isOpen) {
    //     DisableScroll();
    //   } else {
    //     AllowScroll();
    //   }
    // }else {
    //   // console.log("window.location.pathname")
    // }
    if(isOpen){
      getCompKitInventoryForAnotherStore && getCompKitInventoryForAnotherStore()
      setTiendas(kitAvailabilityInfoByStore);
    }
  }, [isOpen]);

  const handleCambiarTienda = (data) => {
    Axios.get(
      `/wcs/resources/store/${mySite.storeID}/storelocator/latitude/${data.latitude}/longitude/${data.longitude}?maxItems=1&radius=1&langId=-5`
    )
      .then((response) => response.data)
      .then((res) => {
        if (parseInt(res.recordSetCount) > 0) {
          const findStores = cityList.filter((states: any) =>
            states.stores.some((city) => city.stLocId === res.PhysicalStore[0].storeName)
          )[0];

          const payload = {
            findStore: {
              ...data,
              stateId: findStores.stateId,
              uniqueID: res.PhysicalStore[0].uniqueID,
              isExternal: true,
            },
          };
          if (res?.PhysicalStore) {
            dispatch(SET_CURRENT_STORE_ACTION(payload));
          }
        } else {
          dispatch(SET_CURRENT_STORE_ACTION(data));
        }

        if (seoRdc && seoRdc !== "") {
          navigate(seoRdc);
        }
      });
  };

  return (
    <>
      <ContainerStyledBox width={215}>
        <ContainerStyledButton
          id="availabilityStoresLink"
          startIcon={<SearchIcon style={{ fontSize: "20.59px" }} />}
          style={{ color: "#3E7697", padding: 0 }}
          variant="text"
          disableRipple={true}
          onClick={handleOpenBuscarOtraTienda}>
          Buscar en otra tienda
        </ContainerStyledButton>
      </ContainerStyledBox>

      <StyledSwipeableDrawersDisponibilidad
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchor="right"
        disableEnforceFocus>
        <ContainerStylediv style={{ height: "100%", margin: "0px" }}>
          <ContainerStylediv className="wrapper">
            <ContainerStylediv>
              <ContainerStyledBox py={"10px"} style={{}}>
                <ContainerStyledTypography variant={"textSegment"} align="center">
                  Buscar disponibilidad
                </ContainerStyledTypography>

                <ContainerStyledBox style={{ position: "absolute", right: "0px", top: "0px", padding: "10px" }}>
                  <HighlightOffIcon
                    style={{ color: palette.gray["100"], cursor: "pointer" }}
                    onClick={handleCloseBuscarOtraTienda}
                  />
                </ContainerStyledBox>
              </ContainerStyledBox>
              <Divider style={{ backgroundColor: palette.gray["50"] }} />

              <ContainerStyledBox>
                <ContainerStyledTypography variant="bodyBaseline" style={{ padding: "10px" }}>
                  A continuación te mostraremos la disponibilidad en las tiendas de la ciudad que seleccionaste
                </ContainerStyledTypography>
              </ContainerStyledBox>

              <Divider style={{ color: palette.gray["50"] }} />
            </ContainerStylediv>

            {tiendas?.length === 0 ?
            <>
            {[1,2,3,4,5].map( ski => (<SLPDPSearchOtherStores />))}
            </>
            :
            <ContainerStylediv className="content">
              <ContainerStylediv style={{}}>
                {tiendas &&
                  tiendas.sort((a, b) => (a.storeName.trim().toLowerCase() > b.storeName.trim().toLowerCase()) ? 1 : -1).map((tienda) => {
                    const cantidad = parseInt(tienda.availableQuantity);
                    let cantidadWording = t("productDetail.Availability.labels.availableQuantity", {
                      n: `${cantidad ? cantidad : 0}`,
                    });
                    // let wording = "para compra en línea";
                    // let iconClass = "check-enabled";
                    if (cantidad > 199) {
                      cantidadWording = `+199 disponibles`;
                    }

                    if (currentStoreId === tienda.stLocId) {
                      return (
                        <ContainerStyledBox m={2} key={tienda.stLocId}>
                          <ContainerStyledBox mt={2}>
                            <ContainerStyledTypography
                              variant="bodyBaseline"
                              weight="bold"
                              style={{ color: palette.highlight.main }}
                              component="span">
                              {cantidadWording}&nbsp;
                            </ContainerStyledTypography>
                            <ContainerStyledTypography variant="bodyBaseline" component="span">
                              en&nbsp;
                            </ContainerStyledTypography>
                            <ContainerStyledTypography variant="bodyBaseline" weight="bold" component="span">
                              {tienda.storeName} #{tienda.stLocId}
                            </ContainerStyledTypography>
                          </ContainerStyledBox>

                          <ContainerStyledBox mt={1}>
                            <ContainerStyledTypography variant="bodySubText" style={{ color: palette.gray["300"] }}>
                              {tienda.address1}, {tienda.address2}. {tienda.city}, {tienda.stLocName} C.P.{" "}
                              {tienda.zipcode}
                            </ContainerStyledTypography>
                          </ContainerStyledBox>

                          <ContainerStyledBox mt={2}>
                            <ContainerStyledRadioCardContainer className={"container storeChecked"}>
                              <ContainerStyledRadioCardContainer item xs={8}>
                                <ContainerStyledSuccessRoomIcon fontSize={"small"} />
                                <ContainerStyledTypography
                                  variant="bodySubText"
                                  className={"storeSelector--styledTypography-primary"}>
                                  Tienda selecionada
                                </ContainerStyledTypography>
                              </ContainerStyledRadioCardContainer>
                              <ContainerStyledRadioCardContainer item xs={4} className={"successIconRadio"}>
                                <ContainerStyledSuccessIconRadio>
                                  <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
                                </ContainerStyledSuccessIconRadio>
                              </ContainerStyledRadioCardContainer>
                            </ContainerStyledRadioCardContainer>
                          </ContainerStyledBox>
                        </ContainerStyledBox>
                      );
                    }

                    return (
                      <ContainerStyledBox m={2} key={tienda.stLocId}>
                        <ContainerStyledBox mt={2}>
                          <ContainerStyledTypography
                            variant="bodyBaseline"
                            weight="bold"
                            style={{ color: palette.highlight.main }}
                            component="span">
                            {cantidadWording}&nbsp;
                          </ContainerStyledTypography>
                          <ContainerStyledTypography variant="bodyBaseline" component="span">
                            en&nbsp;
                          </ContainerStyledTypography>
                          <ContainerStyledTypography variant="bodyBaseline" weight="bold" component="span">
                            {tienda.storeName} #{tienda.stLocId}
                          </ContainerStyledTypography>
                        </ContainerStyledBox>

                        <ContainerStyledBox mt={1}>
                          <ContainerStyledTypography variant="bodySubText" style={{ color: palette.gray["300"] }}>
                            {tienda.address1}, {tienda.address2}. {tienda.city}, {tienda.stLocName} C.P.{" "}
                            {tienda.zipcode}
                          </ContainerStyledTypography>
                        </ContainerStyledBox>

                        <ContainerStyledBox mt={2}>
                          <ContainerStyledRadioCardContainer
                            className={"container"}
                            onClick={() => handleCambiarTienda(tienda)}>
                            <ContainerStyledUncheckedRadioIcon fontSize={"small"} />
                            <ContainerStyledTypography variant="bodySubText" style={{ color: palette.secondary.main }}>
                              Selecciona una tienda
                            </ContainerStyledTypography>
                          </ContainerStyledRadioCardContainer>
                        </ContainerStyledBox>
                      </ContainerStyledBox>
                    );
                  })}
              </ContainerStylediv>
            </ContainerStylediv>
            }

            {tiendas?.length === 0 ?
            null
            :
            <ContainerStylediv>
              <KitAvailabilityByState handleSetTiendas={handleSetTiendas} productData={productData} />
            </ContainerStylediv>
            }
          </ContainerStylediv>
        </ContainerStylediv>
      </StyledSwipeableDrawersDisponibilidad>
      {/* <StyledSwipeableDrawerHDM isOpen={isOpen} setOpenDrawer={setIsOpen} variant={"disponibilidad"}>
        <ContainerStyledBox style={{height: '100%', margin: 0}}>
          <ContainerStyledBox className="wrapper-buscar">
          <ContainerStyledBox mb={2}>
            <ContainerStyledTypography variant="bodyBaseline">
              A continuación te mostraremos la disponibilidad en las tiendas de la ciudad que seleccionaste
            </ContainerStyledTypography>
          </ContainerStyledBox>

          <Divider style={{ color: palette.gray["50"] }} />

          <ContainerStyledBox className="content-tiendas">

            {tiendas && tiendas.map(tienda => {

              return (

              <ContainerStyledBox m={2} key={tienda.stLocId}>

                <ContainerStyledBox display="flex" mt={2}>
                  <ContainerStyledTypography
                    variant="bodyBaseline"
                    weight="bold"
                    style={{ color: palette.highlight.main }}
                    component="span">
                    +1 disponibles&nbsp;
                  </ContainerStyledTypography>
                  <ContainerStyledTypography variant="bodyBaseline" component="span">
                    en&nbsp;
                  </ContainerStyledTypography>
                  <ContainerStyledTypography variant="bodyBaseline" weight="bold" component="span">
                    {tienda.storeName} #{tienda.stLocId}
                  </ContainerStyledTypography>
                </ContainerStyledBox>

                <ContainerStyledBox mt={1}>
                  <ContainerStyledTypography variant="bodySubText" style={{ color: palette.gray["300"] }}>
                    {tienda.address1}, {tienda.address2}. {tienda.city}, {tienda.stLocName} C.P. {tienda.zipcode}
                  </ContainerStyledTypography>
                </ContainerStyledBox>

                <ContainerStyledBox>
                  <ContainerStyledRadioCardContainer className={"container"}>
                    <ContainerStyledUncheckedRadioIcon fontSize={"small"} />
                    <ContainerStyledTypography variant="bodySubText" style={{ color: palette.secondary.main }}>
                     Selecciona una tienda
                    </ContainerStyledTypography>
                  </ContainerStyledRadioCardContainer>
                </ContainerStyledBox>

                <ContainerStyledBox mt={2}>
                  <ContainerStyledRadioCardContainer className={"container storeChecked"}>
                    <ContainerStyledRadioCardContainer item xs={8}>
                      <ContainerStyledSuccessRoomIcon fontSize={"small"} />
                      <ContainerStyledTypography variant="bodySubText" className={"storeSelector--styledTypography-primary"}>
                        Selecciona una tienda
                      </ContainerStyledTypography>
                    </ContainerStyledRadioCardContainer>
                    <ContainerStyledRadioCardContainer item xs={4} className={"successIconRadio"}>
                      <ContainerStyledSuccessIconRadio>
                        <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
                      </ContainerStyledSuccessIconRadio>
                    </ContainerStyledRadioCardContainer>
                  </ContainerStyledRadioCardContainer>
                </ContainerStyledBox>
              </ContainerStyledBox>
              )
            })}
          </ContainerStyledBox>
        <BuscarEstadoCiudad handleSetTiendas={handleSetTiendas} />
        </ContainerStyledBox>
        </ContainerStyledBox>
      </StyledSwipeableDrawerHDM> */}
    </>
  );
};

export default KitAvailabilityByStore;

import Axios, { Canceler } from "axios";
import { useState, useMemo, useEffect } from "react";
import getDisplayName from "react-display-name";
import { useDispatch, useSelector } from "react-redux";

import { useSite } from "../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import { useTranslation } from "react-i18next";

import { StyledButton } from "../elements/button";
import { hdmColorsRdc, hdmDrawerColorDataRdc, lineaGamaColorsRdc, onlineStoreRdc } from "../../redux/selectors/hdmData";
import { checkIfHasColorBase } from "./utils";
import { loginStatusSelector } from "../../redux/selectors/user";
import { proCustomerLevelSelector } from "../../redux/selectors/organization";
import { currentStoreSelector } from "../../redux/selectors/current-store";
import { triggerAddToCartDL } from "../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { triggerConversionsPinterestAddToCart } from "../../components/custom-components/conversion-api/pinterest/pinterest-conversion-utils";
import { OPEN_CART_DRAWER_ACTION } from "../../redux/actions/drawer";
import { OPEN_BACKDROP_LOADER_ACTION } from "../../redux/actions/backdrop-loader";
import { LoaderActions } from "../../components/custom-components/skeleton-loader/actions/sl-actions";

interface Props {
  partNumberGift: string;
  partNumber: any;
  quantity: string;
  selectedArticulo: any;
  handleCloseProductos: () => void;
  giftQty: string;
}

const AgregarArticulo = (props: Props) => {
  const { partNumberGift, quantity, selectedArticulo, partNumber, handleCloseProductos, giftQty } = props;
  const { mySite } = useSite();
  const storeId: string = mySite ? mySite.storeID : "";
  const contract = useSelector(currentContractIdSelector);
  const [addItemActionTriggered, setAddItemActionTriggered] = useState<boolean>(false);
  const dispatch = useDispatch();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const { t } = useTranslation();
  const colorCode: any = null;
  let colorCodeGift: any = null;
  const widgetName = getDisplayName("ProductDetailsWidget");
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const currentStore = useSelector(currentStoreSelector);
  const storeConfData = useSelector(onlineStoreRdc);


  const payloadBase: any = {
    storeId: storeId,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  if (hdmLineaGamaColor && hdmLineaGamaColor?.length !== 0 && hdmColors && hdmColors?.length !== 0) {
    const hasProductBaseColor: any = checkIfHasColorBase(hdmColors, {
      manufacturer: selectedArticulo?.manufacturer,
      attributes: selectedArticulo?.attributes,
    });

    if (hasProductBaseColor) {
      const { branchColor, lineColor, baseColor } = hasProductBaseColor;
      const pinturasHDM = hdmLineaGamaColor;
      const colorBase = pinturasHDM.filter(
        (database) => database?.marca === branchColor && database?.linea === lineColor && database.gama === "#N/D"
      )[0];
      const baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === baseColor)[0];

      colorCodeGift = baseGamaColorDefault?.valor;
    }
  }

  const handleAgregarArticulo = () => {
    if (!quantity || quantity === "") {
      return;
    }
    handleCloseProductos();
    const param = {
      partnumber: partNumber,
      quantity: [quantity],
      contractId: contract,
      colorCode: hdmDrawerColorData?.colorCode ? hdmDrawerColorData?.colorCode : colorCode,
      ...payloadBase,
    };

    const paramRegalo = {
      partnumber: [partNumberGift],
      quantity: [giftQty],
      contractId: contract,
      colorCode: colorCodeGift,
      ...payloadBase,
    };
    setAddItemActionTriggered(true);

    dispatch(OPEN_CART_DRAWER_ACTION({source: "pdpProduct"}));
    dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.ADD_PRODUCT_TO_CART, idx: partNumber }));
    dispatch(orderActions.ADD_ITEM_ACTION(param));
    dispatch(orderActions.ADD_ITEM_ACTION(paramRegalo));
    
    const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
    try{

      triggerAddToCartDL(
        mySite.storeID,
        mySite?.isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
        contract,
        [partNumber,partNumberGift],
        giftQty,
        itemAffiliation,
        currentStore?.currentStore?.marketId,
        mySite?.isB2B
      );

      
      triggerConversionsPinterestAddToCart(
        mySite.storeID,
        mySite?.isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
        contract,
        [partNumber,partNumberGift],
        quantity,
        currentStore?.currentStore?.marketId,
        mySite?.isB2B
      );
    }catch(e){
      console.log("Error DL add_to_cart");
    }
    // setTimeout(() => {
    //   handleCloseProductos();
    // }, 1000);
  };

  let disabled = false;

  if (!selectedArticulo || quantity === "") {
    disabled = true;
  }

  return (
    // <StyledButton variant="outlined" disableRipple={true} fullWidth onClick={handleAgregarArticulo}>
    //   {t("productBox.Agregar")}
    // </StyledButton>
    <StyledButton variant="outlined" disabled={disabled} onClick={selectedArticulo ? handleAgregarArticulo : null}>
      {t("productDetail.AddPromotionToCart")}
    </StyledButton>
  );
};

export default AgregarArticulo;

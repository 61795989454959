//Standard libraries
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CART } from "../../../constants/routes";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { StyledLinkButton } from "../../custom-components/styled-store-selector/styled-link-button/StyledLinkButton";
import { useTranslation } from "react-i18next";
import StyledDialogStore from "../../custom-components/styled-store-selector/styled-dialog-store/StyledDialogStore";
import Divider from "@material-ui/core/Divider";
import { DeliveryInfoCard } from "../delivery-info-card";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { StyledEmarsysTitle } from "../../../hdm/commerce-widgets/hdm-emarsys-product-recommendation/Container-emarsys-product";
import { useSite } from "../../../_foundation/hooks/useSite";
import { EMPTY_STRING } from "../../../constants/common";

/**
 * Address card display component
 * displays the details of a single address
 * @param props
 */
const BopisAddressCard: React.FC<any> = (props: any) => {
  const currentStore = useSelector(currentStoreSelector);
  const storeDetails = currentStore.currentStore;
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const readOnlyShipping = props.readOnlyShipping ? props.readOnlyShipping : false;
  const variant = props.variant ? props.variant : "checkout";
  const stepActive = props.stepActive ? props.stepActive : 0;
  const deliveryMethod = props.deliveryMethod;
  const deliveryDates = props.deliveryDates;
  const isInstallation = props.isInstallation === true ? true : false;
  const isDrawer = props?.isDrawer;
  const handleGoToCart = () => {
    navigate(CART);
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };
  const contentComponent = (
    <>
      <StyledBox display="flex" className={"top-margin-2"}>
        {readOnlyShipping ? (
          <StyledGrid StyledGrid>
            <LocationOnIcon
              className={`${variant === "typ" ? "orderConfirmation--icon-darkGray" : "shipping--styledIcon-locationIcon"} ${
                isB2B ? "no-margin" : EMPTY_STRING
              }`}
            />
          </StyledGrid>
        ) : null}
        <StyledGrid className={isB2B && variant === "typ" ? "left-margin-1" : EMPTY_STRING}>
          {storeDetails.storeName ? (
            <StyledTypography
              variant={isB2B && variant === "typ" ? "headingFeatured" : "bodyBaseline"}
              weight="semibold">
              {storeDetails.storeName} {" #" + storeDetails.stLocId}
            </StyledTypography>
          ) : null}

          {storeDetails.address1 && (
            <StyledTypography
              variant="bodyBaseline"
              component={variant === "typ" ? "span" : ""}
              className={"top-margin-1"}>
              {storeDetails.address1 + " "}
            </StyledTypography>
          )}

          {storeDetails.address2 && (
            <StyledTypography variant="bodyBaseline" component={variant === "typ" ? "span" : ""}>
              {storeDetails.address2 + " "}
            </StyledTypography>
          )}

          {storeDetails.city ? (
            <StyledTypography variant="bodyBaseline" component={variant === "typ" ? "span" : ""}>
              {storeDetails.city + ", "} {storeDetails.stLocName} {" C.P. " + storeDetails.zipcode}
            </StyledTypography>
          ) : null}
        </StyledGrid>
      </StyledBox>
    </>
  );
  return (
    <>
      {(variant === "checkout" && !isInstallation) && (
        <StyledDialogStore
          title={t("Shipping.BopisDialog.Tittle")}
          children={t("Shipping.BopisDialog.Content")}
          accept={t("Shipping.BopisDialog.Accept")}
          cancel={t("Shipping.BopisDialog.Cancel")}
          open={openDialog}
          setOpen={setOpenDialog}
          onConfirm={handleGoToCart}
          onClose={handleOpenDialog}
          alignConfirmButtons={"vertical"}
          variantConfirmButton={"outlined"}
          onStoreSelect={handleOpenDialog}
        />
      )}
      <StyledGrid container>
        {variant === "checkout" && (
          <StyledGrid item xs={12}>
            {readOnlyShipping ? (
              <StyledEmarsysTitle>
                <StyledBox mb={1} className={"top-margin-1"}>
                  <StyledTypography variant="bodyBaseline" component="span" className="title-border-checkout">
                    {isInstallation ? t("Shipping.Labels.InstallationTitle") : t("Shipping.Labels.BopisAddressLater")}
                  </StyledTypography>
                  <Divider className="top-margin-1"/>
                </StyledBox>
              </StyledEmarsysTitle>
            ) : (
              <StyledTypography variant="bodyBaseline" weight="semibold">
                {t("Shipping.Labels.BopisAddress")}
              </StyledTypography>
            )}
          </StyledGrid>
        )}
        {variant === "orderResume" && (
          <StyledGrid item xs={12}>
            {readOnlyShipping ? (
              <StyledEmarsysTitle>
                <StyledBox mb={1} className={"top-margin-1"}>
                  <StyledTypography variant="bodyBaseline" component="span" className="title-border-checkout">
                    {t("Shipping.Labels.BopisAddressLater")}
                  </StyledTypography>
                  <Divider className="top-margin-1"/>
                </StyledBox>
              </StyledEmarsysTitle>
            ) : (
              <StyledTypography variant="bodyBaseline" weight="semibold">
                {t("Shipping.Labels.BopisAddress")}
              </StyledTypography>
            )}
          </StyledGrid>
        )}
        {!readOnlyShipping && (
          <StyledGrid item xs={12} className={"top-margin-1"}>
            <StyledTypography variant="bodySubText" className={"shipping--typography-shippingGray"}>
              {t("Shipping.Labels.BopisConfirm")}
            </StyledTypography>
          </StyledGrid>
        )}

        <StyledGrid xs={12} className={"bopisAddressCard--styledGrid-flex bottom-margin-2"}>
          <StyledGrid>{contentComponent}</StyledGrid>
          {variant === "checkout" && stepActive === 1 && (
            <StyledGrid item xs className={"shipping--styledGrid-editSelectedAddress"}>
              <StyledLinkButton variant="bodyBaseline" onClick={() => handleOpenDialog()}>
                {t("Shipping.Labels.BopisChange")}
              </StyledLinkButton>
            </StyledGrid>
          )}
        </StyledGrid>
        <DeliveryInfoCard
          readOnlyShipping={readOnlyShipping}
          type={"bopis"}
          variant={variant}
          deliveryMethod={deliveryMethod}
          deliveryDates={deliveryDates}
          stepActive={stepActive}
          isDrawer={isDrawer}
        />
      </StyledGrid>
      {props.divider && (
        <StyledGrid xs={12}>
          <Divider />
        </StyledGrid>
      )}
    </>
  );
};
export { BopisAddressCard };

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { useSite } from "../../../_foundation/hooks/useSite";
import getDisplayName from "react-display-name";
import styled from "styled-components";
import { loadScript } from "@paypal/paypal-js";

import { payMethodsSelector } from "../../../redux/selectors/order";
import { paymentSequenceRdc, hdmStoresDataRdc } from "../../../redux/selectors/hdmData";

import * as orderActions from "../../../redux/actions/order";
import { AllowScroll, DisableScroll } from "../../../hdm/functions/utils";

import {
  StyledBox,
  StyledButton,
  StyledCardEmpty,
  StyledLink,
  StyledMenuTypography,
  StyledTypography,
  StyledGrid,
  StyledPaper,
} from "../../../hdm/elements";
import TitleCard from "../../custom-components/styled-title-card/TitleCard";
import {
  ContainerCheckoutProfile,
  ContainerStylediv,
  StyledRadioCardContainer,
  StyledRadioCardSuccessContainer,
  StyledSuccessIconRadio,
  StyledSwipeableDrawersAgregarTarjeta,
  StyledUncheckedRadioIcon,
} from "./container-checkout-profile";
import { palette } from "../../../hdm/themes";
import StoreIcon from "@material-ui/icons/Store";
import oxxoLogo from "../../../assets/homedepot/images/oxxo-logo.svg";
import CheckIcon from "@material-ui/icons/Check";
import { CollectionsBookmarkOutlined, CompareArrowsOutlined, SubscriptionsOutlined } from "@material-ui/icons";
import { AccountCircleSharpIcon } from "../../../hdm/components/svg-icons";
import { SvgIcon, Divider, Button, ClickAwayListener, Grow, MenuItem, MenuList, Popper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Direcciones from "./Direcciones";
import DatosFacturaForm from "./DatosFacturaForm";
import AgregarNuevaTarjeta from "./AgregarNuevaTarjeta";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import carnet from "../../../assets/homedepot/images/Carnet-01.png";

import { CONSTANTS } from "../../../constants/order-item-table";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { cartSelector } from "../../../redux/selectors/order";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { StyledLinkButton } from "../../custom-components/styled-store-selector/styled-link-button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StyledDialogStore from "../../custom-components/styled-store-selector/styled-dialog-store/StyledDialogStore";
import { PAYMENT } from "../../../constants/order";
import { cardInfo } from "./ProfileCreate";
import { checkoutProfileSelector } from "../../../redux/selectors/checkout-profile";
import SavedCardImage from "./SavedCardImage";
import { loaders } from "../../custom-components/skeleton-loader/components";
import withSkeletonLoader from "../../custom-components/skeleton-loader/hoc/withSkeletonLoader";

const metodosPago = [
  {
    label: "Pago con tarjeta de crédito / débito",
    metodo: "VISA",
    icon: false,
    iconMetodo: "",
  },
  {
    label: "Transferencia electrónica SPEI",
    metodo: "OpenPay",
    icon: true,
    iconMetodo: (
      <img
        src="https://cdn.homedepot.com.mx/layout/checkout/PBT-Black.svg"
        alt="logo home depot"
        width={30}
        height={15}
        style={{ marginRight: 5 }}
        className="lazyload"
      />
    ),
  },
  {
    label: "Pago con PayPal",
    metodo: "PayPal",
    icon: true,
    iconMetodo: (
      <img
        src="https://cdn.homedepot.com.mx/layout/checkout/Paypal.png"
        alt="logo home depot"
        width={30}
        height={15}
        style={{ marginRight: 5 }}
        className="lazyload"
      />
    ),
  },
  {
    label: "Pago en efectivo",
    metodo: "Oxxo",
    icon: true,
    iconMetodo: (
      <img
        src={oxxoLogo}
        alt="logo home depot"
        width={30}
        height={15}
        style={{ marginRight: 5 }}
        className="lazyload"
      />
    ),
  },
  {
    label: "Pago en nuestras tiendas",
    metodo: "PayInStore",
    icon: true,
    iconMetodo: <StoreIcon style={{ color: palette.gray["300"], marginRight: 5 }} />,
  },
];

interface Props {
  updateDataProfile: any;
  misDirecciones: any;
  handleSelectedMetodoPago: (metodo: any) => void;
  storeConfData: any;
  setRefHeights?: any;
  refHeights?: any;
  isMobile?: boolean;
  setSaveCard?: any;
  linkedAccount: boolean;
  setLinkedAccount: (metodo: any) => void;
  setListCardsLength: (num: number) => void;
}

export const PaypalContainerPaymentMethod = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
  .paypal--button-container{
    background: ${theme.palette.highlight.main} !important;
    color: ${theme.palette.background.paper} !important;
    min-width: 110px;
    border-color: ${theme.palette.highlight.main};
    transition: all .3s ease 0;
    font-weight: 600;
    border-radius: 0;
    font-size: 16px;
    height: 45px;
    line-height: 2.7;
    display: block;
    text-align: center;
    text-decoration: none;
    position: relative;
    cursor: pointer;
  }

  .paypal--button-container-BA{
    background: ${theme.palette.background.paper} !important;
    color: ${theme.palette.primary.main} !important;
    min-width: 110px;
    border: 2px solid ${theme.palette.primary.main} !important;
    transition: all .3s ease 0;
    font-weight: 600;
    border-radius: 3px;
    font-size: 16px;
    height: 42px;
    line-height: 2.7;
    display: block;
    text-align: center;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .paypal-buttons {
      height:42px !important;
    }
  }
  .paypal--button-container-BA:hover{
    background: ${theme.palette.primary.main} !important;
    color: ${theme.palette.background.paper} !important;
    min-width: 110px;
    border: 2px solid ${theme.palette.primary.main} !important;
    cursor: pointer;
  }
  .paypal-buttons {
    position: absolute !important;
    opacity: 0;
    z-index: 1000;
    top: 0px;
    left: 0;
    display: inline-block;
    width: 100%;
    min-height: 25px;
    min-width: 150px;
    max-width: 750px;
    font-size: 0;
  }
  
  .paypal--styledGrid-linkedAccountContainer{
    color: #000000;
    display: flex;
    align-items: center;
  }

  .paypal--styledGrid-linkedAccountContainer{
    color: #000000;
    display: flex;
    align-items: center;
  }

  .paypal--styledTypography-linkedAccount {
    margin-right: ${theme.spacing(1)}px;
  }

  .paypal--styledBox-optionsLink{
    ${theme.breakpoints.down("sm")} {
      margin-left:auto;
    }
  }

  .paypal--styledLinkButton-optionsLink {
    text-decoration: none;
    margin-right: ${theme.spacing(1)}px;
  }
  
  .paypal--icon-slimChevron{
    height: 10px;
    width: 10px;
  }

  .paypal--icon-circleContact{
    height: 17px;
    width: 17px;
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.gray["200"]};
  }

  .paypal--popper-container{
    margin-top: ${theme.spacing(1)}px;
    z-index: 10;
    .MuiMenuItem-root{
      min-height: 30px;
    }
  }
  
  .paypal--menuList-container{
    border-top: 3px solid  ${theme.palette.primary.main};
    padding-top: ${theme.spacing(0)}px;
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(0)}px;
    li{
      padding-top: ${theme.spacing(1)}px;
      padding-left: ${theme.spacing(0)}px;
      padding-right: ${theme.spacing(0)}px;
      padding-bottom: ${theme.spacing(1)}px;
    }
  }
    
  `}
`;

const PaypalCheckoutProfile = (props) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const userLoggedIn = useSelector(loginStatusSelector);
  const storeConfData = props.storeConfData;
  const { linkedAccount, setLinkedAccount} = props;
  const cart = useSelector(cartSelector);

  const [payerEmail, setPayerEmail] = useState("");
  const [menuList, setMenuList] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [optionMenuSelected, setOptionMenuSelected] = useState("unlinkAccount");
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const prevOpen = React.useRef(menuList);
  const date = new Date();
  const current_date = date.getFullYear().toString().substr(-2) + "" + (date.getMonth() + 1) + "" + date.getDate();
  const current_time = date.getHours() + "" + date.getMinutes() + "" + date.getSeconds();
  const uuid = useMemo(() => {
    return "cbs9de39fe-c24d-11eb-8529-" + current_date + current_time;
  }, []);

  const createFnclsPpScript = async () => {
    const paypalfnclsPp = document.getElementById(`fnclsPp`) as HTMLInputElement | null;
    paypalfnclsPp?.remove();
    const script = document.createElement("script");
    script.type = "application/json";
    script.id = "fnclsPp";
    script.setAttribute("fncls", storeConfData?.paypal_fnparams);
    script.innerHTML = `
    {
      "f":"${uuid}",
      "s":"${storeConfData?.paypal_app_reference_id}"
    }`;
    document.head.append(script);

    const paypalFb = document.getElementById(`paypalFb`) as HTMLInputElement | null;
    paypalFb?.remove();
    const scriptB = document.createElement("script");
    scriptB.id = "paypalFb";
    scriptB.type = "application/javascript";
    scriptB.src = "https://c.paypal.com/da/r/fb.js";
    document.head.append(scriptB);
  };

  const loadRegisteredUserPp = () => {
    /*const payloadSearchPA = {
      orderId: cart.orderId,
    };*/
    Axios.get(`/wcs/resources/store/${mySite.storeID}/hdm/paypal/myaccount/searchBillingAgreement`)
      // Axios.post(`/wcs/resources/store/${mySite.storeID}/hdm/paypal/checkout/searchBillingAgreement`, payloadSearchPA)
      .then((res) => {
        const isLinked = res.data.isBAActive;
        setLinkedAccount(isLinked);
        if (isLinked) {
          setPayerEmail(res.data.payer_email);
        } else {
          setPayerEmail("");
        }

        const payLoadPayPalRegistered = {
          "client-id": storeConfData?.paypal_client_id,
          currency: "MXN",
          components: "buttons",
          vault: "true",
          intent: "tokenize",
          commit: false,
        };
        loadScript(payLoadPayPalRegistered)
          .then((paypal) => {
            // start to use the PayPal JS SDK script
            createPayPalBillingAgreement(paypal);
          })
          .catch((err) => {
            console.error("failed to load the PayPal JS SDK script", err);
          });
      })
      .catch((error) => {
        console.log("paypal error searchBillingAgreement ", error);
      });
  };

  const createPayPalBillingAgreement = (paypal) => {
    const flag = linkedAccount;
    const FUNDING_SOURCES = [paypal.FUNDING.PAYPAL];
    FUNDING_SOURCES.forEach(function (fundingSource) {
      const button = paypal.Buttons({
        fundingSource: fundingSource,
        intent: "tokenize",
        createBillingAgreement: function (data, actions) {
          return new Promise((resolve, reject) => {
            const payload = {
              flag: flag,
              uuid: uuid,
            };
            Axios.post(
              `/wcs/resources/store/${mySite.storeID}/hdm/paypal/myaccount/createBillingAgreement`,
              payload
            )
              .then((res) => {
                if (res.data.responseCode === "201") {
                  resolve(res.data.billingTokenId);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        },
        onApprove: function (data, actions) {
          const payload = {
            flag: flag,
            billingTokenId: data.billingToken,
            uuid: uuid,
          };
          Axios.post(
            `/wcs/resources/store/${mySite.storeID}/hdm/paypal/myaccount/executeBillingAgreement`,
            payload
          )
            .then((res) => {
              if (res.data.responseCode == "201") {
                const isLinked = res.data.isBAActive;
                setLinkedAccount(isLinked);
                if (isLinked) {
                  setPayerEmail(res.data.payer_email);
                } else {
                  setPayerEmail("");
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });

      if (button.isEligible()) {
        button.render(document.getElementById("paypal-button-container-BA"));
      }
    });
  };

  const openPaypalCardAdmin = () => {
    const payLoadPayPalRegistered = {
      "client-id": storeConfData?.paypal_client_id,
      currency: "MXN",
      components: "buttons",
      vault: "true",
      intent: "tokenize",
      commit: false,
    };
    loadScript(payLoadPayPalRegistered)
      .then((paypal) => {
        // start to use the PayPal JS SDK script
        createPayPalBillingAgreementCardAdmin(paypal);
      })
      .catch((err) => {
        console.error("failed to load the PayPal JS SDK script", err);
      });
  };

  const createPayPalBillingAgreementCardAdmin = (paypal) => {
    const flag = linkedAccount;
    const FUNDING_SOURCES = [paypal.FUNDING.PAYPAL];
    FUNDING_SOURCES.forEach(function (fundingSource) {
      const button = paypal.Buttons({
        fundingSource: fundingSource,
        intent: "tokenize",
        createBillingAgreement: function (data, actions) {
          return new Promise((resolve, reject) => {
            const payload = {
              flag: flag,
              uuid: uuid,
            };
            Axios.post(
              `/wcs/resources/store/${mySite.storeID}/hdm/paypal/myaccount/createBillingAgreement`,
              payload
            )
              .then((res) => {
                if (res.data.responseCode === "201") {
                  resolve(res.data.billingTokenId);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        },
        onCancel: function () {
          setMenuList(false);
        },
        onApprove: function (data, actions) {
          const payload = {
            flag: flag,
            billingTokenId: data.billingToken,
            uuid: uuid,
          };
          Axios.post(
            `/wcs/resources/store/${mySite.storeID}/hdm/paypal/myaccount/executeBillingAgreement`,
            payload
          )
            .then((res) => {
              if (res.data.responseCode == "201") {
                setMenuList(false);
                const isLinked = res.data.isBAActive;
                const disableMSI: any = res.data.msiMap == null ? true : false;
                setLinkedAccount(isLinked);
                if (isLinked) {
                  setPayerEmail(res.data.payer_email);
                } else {
                  setPayerEmail("");
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        },
      });

      if (button.isEligible()) {
        button.render(document.getElementById("paypal-button-container-BA-CardAdmin"));
      }
    });
  };

  const loadScriptsPp = async () => {
    if (userLoggedIn) {
      await createFnclsPpScript();
      loadRegisteredUserPp();
    }
  };

  const handleToggle = () => {
    setMenuList((prevOpen) => !prevOpen);
    openPaypalCardAdmin();
  };

  const handleMenuList = (event) => {
    if (menuList) {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }
    }
    setMenuList(menuList);
  };

  const handleOpenDialog = (typeDailog) => {
    setOptionMenuSelected(typeDailog);
    setMenuList(false);
    if (typeDailog != "cardAdmin") {
      setOpenDialog(!openDialog);
    }
  };

  const handleUnlinkAccount = () => {
    setOpenDialog(false);
    Axios.delete(`/wcs/resources/store/${mySite.storeID}/hdm/paypal/billingAgreement/deleteBillingAgreement`)
      .then((res) => {
        if (res.data.success) {
          setLinkedAccount(false);
          loadRegisteredUserPp();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadScriptsPp();
  }, [storeConfData]);

  const dialogContent = (
    <>
      <StyledTypography variant={"bodyBaseline"} component="span">
        {t("OrderConfirmation.PaymentMethod.Paypal.dialog.detail.confirm")}
      </StyledTypography>
      <StyledTypography variant={"bodyBaseline"} weight="bold" component="span">
        {t("OrderConfirmation.PaymentMethod.Paypal.dialog.detail.unlink")}
      </StyledTypography>
      <StyledTypography variant={"bodyBaseline"} component="span">
        {t("OrderConfirmation.PaymentMethod.Paypal.dialog.detail.account")}
      </StyledTypography>
    </>
  );

  const dialogContentChangeAccount = (
    <>
      <StyledTypography variant={"bodyBaseline"} component="span">
        {t("OrderConfirmation.PaymentMethod.Paypal.dialogChangeAccount.body.confirm")}
      </StyledTypography>
      <StyledTypography variant={"bodyBaseline"} weight="bold" component="span">
        {t("OrderConfirmation.PaymentMethod.Paypal.dialogChangeAccount.body.change")}
      </StyledTypography>
      <StyledTypography variant={"bodyBaseline"} component="span">
        {t("OrderConfirmation.PaymentMethod.Paypal.dialogChangeAccount.body.account")}
      </StyledTypography>
    </>
  );

  return (
    <PaypalContainerPaymentMethod>
      <StyledDialogStore
        title={
          optionMenuSelected == "unlinkAccount"
            ? t("OrderConfirmation.PaymentMethod.Paypal.dialog.title")
            : t("OrderConfirmation.PaymentMethod.Paypal.dialogChangeAccount.title")
        }
        children={optionMenuSelected == "unlinkAccount" ? dialogContent : dialogContentChangeAccount}
        accept={t("OrderConfirmation.PaymentMethod.Paypal.dialog.confirm")}
        cancel={t("OrderConfirmation.PaymentMethod.Paypal.dialog.cancel")}
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={handleUnlinkAccount}
        onClose={handleOpenDialog}
        alignConfirmButtons={"vertical"}
        variantConfirmButton={"outlined"}
        onStoreSelect={handleOpenDialog}
        isHomologatedPopUp
      />
      <StyledBox pt={2} display={"flex"} alignItems={"center"}>
        <img src={storeConfData?.IMAGE_SERVER_HOSTNAME + CONSTANTS.icons.paypal} height={18} className="lazyload" />
        <StyledTypography variant="bodyBaseline" component="span" className={"left-margin-1"}>
          {t("CheckoutProfile.PayMethodName")}
        </StyledTypography>
      </StyledBox>
      {userLoggedIn && !linkedAccount && (
        <StyledBox pt={4}>
          <StyledTypography variant="bodySubtext" className="gray300">
            {t("CheckoutProfile.NoLinkedAccount")}
          </StyledTypography>
          <StyledGrid item xs={12}>
            <div className="button_footer_line paypal" id="WC_ShipmentDisplay_div_32_1">
              <div id="paypal-button-container-BA" className="paypal--button-container-BA">
                <span className="pagarbutton">{t("CheckoutProfile.textButtonLinkAccount")}</span>
              </div>
            </div>
          </StyledGrid>
        </StyledBox>
      )}
      {userLoggedIn && linkedAccount && (
        <>
          <StyledBox pt={4} display="flex" alignItems="center">
            <StyledTypography
              className={"paypal--styledTypography-linkedAccount"}
              variant="bodyBaseline"
              fontWeight={600}>
              {t("OrderConfirmation.PaymentMethod.Paypal.LinkedAccount")}
            </StyledTypography>
            <StyledBox
              className="paypal--styledBox-optionsLink"
              ref={anchorRef}
              id="composition-button"
              aria-controls={menuList ? "composition-menu" : undefined}
              aria-expanded={menuList ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}>
              <StyledLinkButton
                className="paypal--styledLinkButton-optionsLink"
                variant="bodyBaseline"
                component="span">
                {t("OrderConfirmation.PaymentMethod.Paypal.MoreOptions")}
              </StyledLinkButton>
              <ExpandMoreIcon color="primary" className="paypal--icon-slimChevron" viewBox="6 4 12 12" />
            </StyledBox>
            <Popper
              className={"paypal--popper-container"}
              open={menuList}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-end"
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                  }}>
                  <StyledPaper>
                    <StyledGrid>
                      <ClickAwayListener onClickAway={handleMenuList}>
                        <MenuList
                          autoFocusItem={menuList}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          className={"paypal--menuList-container"}>
                          <MenuItem>
                            <StyledTypography variant="bodyBaseline">
                              <div className="button_footer_line paypal" id="WC_ShipmentDisplay_div_32_1">
                                <div
                                  id="paypal-button-container-BA-CardAdmin"
                                  className="paypal--button-container-BA-CardAmin">
                                  <span className="pagarbutton">
                                    {t("OrderConfirmation.PaymentMethod.Paypal.menuList.cardAdmin")}
                                  </span>
                                </div>
                              </div>
                            </StyledTypography>
                          </MenuItem>
                          {/*<MenuItem onClick={() => handleOpenDialog("cardAdmin")}>
                              <StyledTypography variant="bodyBaseline">
                                {t("OrderConfirmation.PaymentMethod.Paypal.menuList.cardAdmin")}
                              </StyledTypography>
                            </MenuItem>*/}
                          <MenuItem onClick={() => handleOpenDialog("changeAccount")}>
                            <StyledTypography variant="bodyBaseline">
                              {t("OrderConfirmation.PaymentMethod.Paypal.menuList.changeAccount")}
                            </StyledTypography>
                          </MenuItem>
                          <MenuItem onClick={() => handleOpenDialog("unlinkAccount")}>
                            <StyledTypography variant="bodyBaseline">
                              {t("OrderConfirmation.PaymentMethod.Paypal.menuList.unlinkAccount")}
                            </StyledTypography>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </StyledGrid>
                  </StyledPaper>
                </Grow>
              )}
            </Popper>
          </StyledBox>
          <StyledBox pt={2} display="flex" alignItems="center">
            <AccountCircleIcon className="paypal--icon-circleContact" fontSize={"small"} viewBox="0 -2 24 24" />
            <StyledTypography variant="bodyBaseline">{payerEmail}</StyledTypography>
          </StyledBox>
        </>
      )}
    </PaypalContainerPaymentMethod>
  );
};

const MetodosDePago = (props: Props) => {
  const {
    handleSelectedMetodoPago,
    misDirecciones,
    updateDataProfile,
    setRefHeights,
    refHeights,
    isMobile,
    setSaveCard,
    linkedAccount,
    setListCardsLength
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMetodoPago, setSelectedMetodoPago] = useState<any>(null);
  const [selectedMetodoPagoExt, setSelectedMetodoPagoExt] = useState<any>(null);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  const [allPayMethods, setAllPayMethods] = useState<any>([]);
  const paymentSequence = useSelector(paymentSequenceRdc);
  const hdmStoresData = useSelector(hdmStoresDataRdc);
  const PayMethodsRef = useRef<HTMLDivElement>(null);

  const fromState = useSelector(checkoutProfileSelector);

  const dispatch = useDispatch();
  const cancels: Canceler[] = [];
  const payMethods = useSelector(payMethodsSelector);
  // const [payMethods, setPayMethods] = useState<any>(null);

  const storeConfData = props.storeConfData;

  const widgetName = getDisplayName(MetodosDePago);
  const CancelToken = Axios.CancelToken;
  const [listCards, setListCards]: any = useState([]);
  const { mySite } = useSite();
  const { t } = useTranslation();

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const getCardsUser = async (drawer=false) => {
    const arrayCards: any = [];
    await Axios.get(`/wcs/resources/store/${mySite?.storeID}/hdm/cybersource/retrieveSavedCardDetails`)
      .then((res) => {
        const rawCardData: any = res?.data?.savedCardMap;

        for (let i = 1; i <= Object.keys(rawCardData).length; i++) {
          const element = rawCardData[`count_detail_${i}`];
          arrayCards.push(element);
        }
        let currentCard = null;
        let currentCardNumber = null;

        try{
          updateDataProfile.profile.protocolData.map((data) => {
            if (data.name === "payment_method") {
              currentCard = data.value;
            }
            if (data.name === "account_hidden") {
              currentCardNumber = data.value;
            }
          });
        }catch(e){
          console.error(e);
        }
        


        const newArrayCards = arrayCards.map((card) => {
          const isSelected = card.cardType === currentCard && card.fullCardNo === currentCardNumber;
          return { ...card, isSelected };
        });
        setListCards(newArrayCards);
        if(drawer) {
          onClickPayMethodsExt(newArrayCards[newArrayCards.length - 1], newArrayCards.length - 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    await Axios.get(
      `/wcs/resources/store/${mySite?.storeID}/cart/@self/payment_policy_list?responseFormat=json&langId=-1`
    )
      .then((res) => {
        if (res.status === 200 && res?.data && res?.data?.resultList && res?.data?.resultList !== 0) {
          if (
            res.data.resultList[0]?.paymentPolicyInfoUsableWithoutTA &&
            res.data.resultList[0]?.paymentPolicyInfoUsableWithoutTA.length !== 0
          ) {
            const allPaymentMethods = res.data.resultList[0]?.paymentPolicyInfoUsableWithoutTA;

            const paymentMethosOrder = [
              PAYMENT.paymentMethodName.amex,
              PAYMENT.paymentMethodName.mc,
              PAYMENT.paymentMethodName.visa,
              PAYMENT.paymentMethodName.giftCard,
              PAYMENT.paymentMethodName.openPay,
              PAYMENT.paymentMethodName.paypal,
              PAYMENT.paymentMethodName.oxxo,
              PAYMENT.paymentMethodName.cod,
              PAYMENT.paymentMethodName.master
            ];

            const selectedPayMethods: any = [];
            paymentMethosOrder.map((payMethod) => {
              const thisSelectedPayMethod = allPaymentMethods.find(
                (thisPayMethod) => thisPayMethod.policyName === payMethod
              );
              if (thisSelectedPayMethod) {
                thisSelectedPayMethod.xumet_policyId = thisSelectedPayMethod.policyId;
                thisSelectedPayMethod.paymentMethodName = thisSelectedPayMethod.policyName;
                selectedPayMethods.push(thisSelectedPayMethod);
              }
            });

            setAllPayMethods(selectedPayMethods);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (updateDataProfile) {
      let metodo: any = null;
      updateDataProfile.profile.protocolData.map((data) => {
        if (data.name === "payment_method") {
          metodo = data.value;
        }
      });
      const selected = allPayMethods.filter((pay) => pay.paymentMethodName === metodo)[0];
      setSelectedMetodoPago(selected);
    }
  }, [allPayMethods]);

  useEffect(() => {
    getCardsUser();
    dispatch(orderActions.GET_PAYMETHODS_ACTION({ ...payloadBase }));
  }, []);

  /* Get paymethod component height */
  useEffect(() => {
    setRefHeights((prevState) => {
      return {
        ...prevState,
        paymethodsHeight: PayMethodsRef?.current?.clientHeight,
      };
    });

    if (selectedMetodoPago?.paymentMethodName === "PayPal") {
      setRefHeights((prevState) => {
        return {
          ...prevState,
          paymethodsHeight: refHeights?.addressListHeight,
        };
      });
    }
    setListCardsLength(listCards ? listCards?.length : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCards, allPayMethods, selectedMetodoPago]);

  const handleSavedCard = () => {
    getCardsUser(true);
    dispatch(orderActions.GET_PAYMETHODS_ACTION({ ...payloadBase }));
  };

  useEffect(() => {
    if(!window.location.pathname.includes("/p/")){
      if (isOpen) {
        DisableScroll();
      } else {
        AllowScroll();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedMetodoPago) {
      handleSelectedMetodoPago(selectedMetodoPago);
      return;
    } else {
      handleSelectedMetodoPago(null);
    }

    if (selectedMetodoPagoExt) {
      handleSelectedMetodoPago(selectedMetodoPagoExt);
      return;
    } else {
      handleSelectedMetodoPago(null);
    }
  }, [selectedMetodoPago, selectedMetodoPagoExt, linkedAccount]);

  useEffect(() => {
    if (paymentSequence && paymentSequence !== "" && payMethods && payMethods.length !== 0) {
      orderPayMethos();
    } else if (payMethods && payMethods.length !== 0) {
      // setAllPayMethods(payMethods);
    }
  }, [paymentSequence, payMethods]);

  const orderPayMethos = () => {
    const paymentOrderList = paymentSequence.split(",");
    const allPaymentMethods: any = [];
    paymentOrderList.map((payOrderList) => {
      const metodo = payMethods.filter((payM) => payM.paymentMethodName === payOrderList)[0];
      if (metodo) {
        allPaymentMethods.push(metodo);
      }
      return true;
    });
    // setAllPayMethods(allPaymentMethods);
  };

  const handleOpenAgregarTarjeta = (data: any = {}) => {
    setIsOpen(true);
  };

  const handleCloseAgregarTarjeta = () => {
    setIsOpen(false);
  };

  const onClickPayMethods = (payMetod) => {
    const newArray: any = [...listCards];
    newArray.map((row, i) => {
      return (newArray[i] = { ...row, isSelected: false });
    });
    const cardInfoObject: cardInfo = {
      pay_payMethodId: payMetod.paymentMethodName,
      pay_cc_brand: payMetod.paymentMethodName,
      pay_payment_method: payMetod.paymentMethodName,
      pay_account_hidden: "-1",
      pay_expire_month: "-1",
      pay_expire_year: "-1",
    };

    setSaveCard(cardInfoObject);
    setListCards(newArray);
    setSelectedMetodoPago(payMetod);
    setSelectedMetodoPagoExt(payMetod);
    if (payMetod.paymentMethodName === "VISA") handleOpenAgregarTarjeta();
  };

  const onClickPayMethodsExt = (payMetod, index) => {
    const newArray: any = [...listCards];
    newArray.map((row, i) => {
      return (newArray[i] = { ...row, isSelected: false });
    });

    newArray[index] = { ...payMetod, isSelected: true };

    const [expMonth, expYear] = payMetod?.unFormattedExpDate.split("-");
    const currentCardNumber = payMetod?.fullCardNo.replaceAll("X", "1");
    const cardInfoObject: cardInfo = {
      pay_payMethodId: payMetod?.cardType,
      pay_cc_brand: payMetod?.cardType,
      pay_payment_method: payMetod?.cardType,
      pay_account_hidden: payMetod?.fullCardNo,
      pay_expire_month: expMonth,
      pay_expire_year: expYear,
    };

    setSaveCard(cardInfoObject);
    setListCards(newArray);
    setSelectedMetodoPagoExt(payMetod);
    setSelectedMetodoPago(null);
  };

  // return loaders.account.profilesCreate.paymentMethods;
  if (!allPayMethods) return loaders.account.profilesCreate.paymentMethods;

  if (selectedMetodoPago && selectedMetodoPago?.paymentMethodName === "PayPal") {
    const paypal = metodosPago.filter((metod) => metod.metodo === "PayPal")[0];

    return (
      <>
        <StyledBox mb={2} className="card-metodo-pago">
          <StyledCardEmpty
            className={"vertical-padding-2 horizontal-padding-2"}
            style={{
              // minHeight: !isMobile && refHeights?.addressListHeight > refHeights?.paymethodsHeight
              //           ? refHeights?.addressListHeight
              //           : ""
              minHeight: !isMobile ? refHeights?.addressListHeight : "",
            }}>
            <TitleCard title="Método de pago" alinear="left" />
            <StyledBox display="flex" alignItems="center">
              <StyledTypography variant="bodyBaseline" fontWeight={600}>
                {t("CheckoutProfile.TitleLabelSelectedMethod")}
              </StyledTypography>
              <StyledLink
                className="left-padding-3 paypal--change-payment-method"
                onClick={() => setSelectedMetodoPago("none")}>
                {t("OrderPaymentInfo.Labels.Change")}
              </StyledLink>
            </StyledBox>
            <PaypalCheckoutProfile {...props} />
          </StyledCardEmpty>
        </StyledBox>
      </>
    );
  }

  const MetodoPagoContainer = () => {
    return (
      <StyledBox mb={2} className="card-metodo-pago" ref={PayMethodsRef}>
        <StyledCardEmpty
          className={"vertical-padding-2 horizontal-padding-2"}
          style={{
            minHeight:
              refHeights?.addressListHeight > refHeights?.paymethodsHeight
                ? refHeights?.addressListHeight
                : refHeights?.paymethodsHeight,
          }}>
          <TitleCard title="Método de pago" alinear="left" />
          {listCards.length > 0 && (
            <>
              <StyledBox display="flex" alignItems="center" justifyContent="flex-start">
                <AddIcon color="primary" fontSize="small" onClick={() => handleOpenAgregarTarjeta("nuevo")} />
                <StyledLink onClick={() => handleOpenAgregarTarjeta("nuevo")}>Agregar nueva tarjeta</StyledLink>
              </StyledBox>
              {listCards.map((row, index) => {
                if (row?.isSelected) {
                  return (
                    <StyledBox mt={2} key={index}>
                      <StyledRadioCardSuccessContainer className={"container storeChecked"}>
                        <StyledRadioCardSuccessContainer item xs={8} style={{ paddingLeft: 10 }}>
                          <SavedCardImage cardType={row.cardType} />
                          <StyledTypography variant="bodyBaseline" fontWeight={600} color="secondary" component="span">
                            {`****${row?.cardNo}`}
                          </StyledTypography>
                          <StyledTypography
                            variant="bodyBaseline"
                            color="secondary"
                            component="span"
                            className="left-padding-3">
                            Vence
                          </StyledTypography>
                          <StyledTypography
                            variant="bodyBaseline"
                            fontWeight={600}
                            color="secondary"
                            component="span"
                            className="left-padding-1">
                            {`${row?.expiryDate}`}
                          </StyledTypography>
                        </StyledRadioCardSuccessContainer>
                        <StyledRadioCardSuccessContainer item xs={4} className={"successIconRadio"}>
                          <StyledSuccessIconRadio>
                            <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
                          </StyledSuccessIconRadio>
                        </StyledRadioCardSuccessContainer>
                      </StyledRadioCardSuccessContainer>
                    </StyledBox>
                  );
                } else {
                  return (
                    <StyledBox mt={2} key={index}>
                      <StyledRadioCardContainer className={"container"} onClick={() => onClickPayMethodsExt(row, index)}>
                        <StyledUncheckedRadioIcon fontSize={"small"} />
                        <SavedCardImage cardType={row.cardType.toLocaleLowerCase()} />
                        <StyledTypography variant="bodyBaseline" fontWeight={600} color="secondary" component="span">
                          {`****${row?.cardNo}`}
                        </StyledTypography>
                        <StyledTypography
                          variant="bodyBaseline"
                          fontWeight={600}
                          color="secondary"
                          component="span"
                          className="left-padding-3">
                          Vence
                        </StyledTypography>
                        <StyledTypography
                          variant="bodyBaseline"
                          fontWeight={600}
                          color="secondary"
                          component="span"
                          className="left-padding-1">
                          {`${row?.expiryDate}`}
                        </StyledTypography>
                      </StyledRadioCardContainer>
                    </StyledBox>
                  );
                }
              })}
            </>
          )}
          {allPayMethods.map((payMetod) => {
            const metodo = metodosPago.filter((metod) => metod.metodo === payMetod.paymentMethodName)[0];
            if (!metodo) return null;
            if (metodo.metodo === "VISA" && listCards.length > 0) return null;
  
            if (selectedMetodoPago) {
              if (selectedMetodoPago.paymentMethodName === payMetod.paymentMethodName) {
                return (
                  <StyledBox mt={2}>
                    <StyledRadioCardSuccessContainer
                      className={"container storeChecked"}
                      onClick={() => onClickPayMethods(payMetod)}>
                      <StyledRadioCardSuccessContainer item xs={8}>
                        <StyledBox ml={2}>{metodo.icon ? metodo.iconMetodo : null}</StyledBox>
                        <StyledTypography variant="bodySubText" style={{ paddingLeft: 10 }}>
                          {metodo.label}
                        </StyledTypography>
                      </StyledRadioCardSuccessContainer>
                      <StyledRadioCardSuccessContainer item xs={4} className={"successIconRadio"}>
                        <StyledSuccessIconRadio>
                          <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
                        </StyledSuccessIconRadio>
                      </StyledRadioCardSuccessContainer>
                    </StyledRadioCardSuccessContainer>
                  </StyledBox>
                );
              }
            }
            return (
              <StyledBox mt={2} key={metodo.metodo}>
                <StyledRadioCardContainer className={"container"} onClick={() => onClickPayMethods(payMetod)}>
                  <StyledUncheckedRadioIcon fontSize={"small"} />
                  {metodo.icon ? metodo.iconMetodo : null}
                  <StyledTypography variant="bodySubText" style={{ color: palette.secondary.main }}>
                    {metodo.label}
                  </StyledTypography>
                </StyledRadioCardContainer>
              </StyledBox>
            );
          })}
        </StyledCardEmpty>
        <AgregarNuevaTarjeta
          isOpen={isOpen}
          misDirecciones={misDirecciones}
          handleCloseAgregarTarjeta={handleCloseAgregarTarjeta}
          handleSavedCard={handleSavedCard}
        />
      </StyledBox>
    );
  }

  return withSkeletonLoader(<MetodoPagoContainer/>, (allPayMethods.length > 0), loaders.account.profilesCreate.paymentMethods) 

};

export default MetodosDePago;
